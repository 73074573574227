import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ShareService } from 'src/app/services/share.service';
@Component({
  selector: 'app-nz-input-edit',
  templateUrl: './nz-input-edit.component.html',
  styleUrls: ['./nz-input-edit.component.scss']
})
export class NzInputEditComponent extends FieldType<FieldTypeConfig> implements OnInit{
    get type() {
      return this.props.type || 'text';
    }
    constructor(
      private shareService: ShareService
    ) {
      super();
    }
    ngOnInit(): void {
    }

    onEdit() {
      this.shareService.emitdata({type: 'edit', value: true})
    }
}
