export function updateFieldInForm(form: any, fieldName: any, value: any, name: any) {
    if (form && form.length) {
      form.forEach((groupChildren: any) => {
        groupChildren.fields.forEach((data: any) => {
          if (data.field_name === fieldName) {
            data[name] = value;
          }
        });
      });
    }
}

export function updateFieldInForm1(form: any, fieldName: any, value: any) {
    if (form && form.length) {
      form.forEach((groupChildren: any) => {
        groupChildren.fields.forEach((data: any) => {
          if (data.field_name === fieldName) {
            data['columnValue'] = value;
          }
        });
      });
    }
}

export function getFieldValueAggrid(form: any, fieldName: any, fieldValue = ''): any {
    let result = '';
    if (form && form.length) {
      form.forEach((results: any) => {
        results.fields.forEach((data : any) => {
          if (data.field_name === fieldName) {
            result = data['columnValue'] || data[fieldValue];
          }
        });
      });
    }
    return result;
}

export function formatMoney(money: number): any {
    if (money) {
        return money.toLocaleString();
    }
}

export function formatNumber(number: number) {
  return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function CheckHideAction(path: string, action: string) {
  return false;
  // const getMenuItems = localStorage.getItem('menuItems');// phân quyền S-Service
  // if(typeof getMenuItems === 'string' && getMenuItems.length > 0) {
  //   const menuItems = localStorage.hasOwnProperty('menuItems') ? JSON.parse(getMenuItems) : null;
  //   if (menuItems && menuItems.length > 0) {
  //       let newArray: any = []
  //       menuItems.forEach((element: any) => {
  //           newArray.push(element);
  //           if (element.submenus && element.submenus.length > 0) {
  //               element.submenus.forEach((element1: any) => {
  //                   newArray.push(element1);
  //               });
  //           }
  //       });
  //       if (newArray.length > 0) {
  //           const menus = newArray.find((m: any) => m.path === path);
  //           if (menus && menus.actions.length > 0) {
  //               const arrAction = menus.actions.map((d: any) => d.actionCd);
  //               if (arrAction.indexOf(action) > -1) {
  //                   return  false;
  //               } else {
  //                   return true;
  //               }
  //           }else {
  //               return true;
  //           }
  //       }else {
  //           return true;
  //       }
  //   }
  // } else {
  //     return true;
  // }
  //
  // return true
}

