import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { map } from 'rxjs/operators';
import { FirebaseAuthService } from 'src/app/services/firebase-auth.service';
@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private firebaseAuthService: FirebaseAuthService,
    private http: HttpClient) { }
    content: any = null;
  async ngOnInit() {
    await this.authService.completeAuthentication();
    const token = this.authService.getAccessTokenValue();
    const returnUrl = localStorage.getItem('returnUrl');
    if (!this.firebaseAuthService.authenticated) {
      const customToken = await this.getCustomTokenKC(token);
      if (customToken) {
         this.firebaseAuthService.customLogin(customToken);
      }
    }
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
    }
  }

  getCustomTokenKC(token: string): any {
    const url = `https://asia-northeast1-sunshine-app-production.cloudfunctions.net/getCustomTokenKC`;
    return this.http.post(url, {
      data: {
        access_token: token
      }
    }).pipe(
      map((response: any) => response.result
    )
    ).toPromise();
  }
}
