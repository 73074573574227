<div class="field">
    <p-autoComplete 
    field="name"
    [dataKey]="'value'"
    [formControl]="formControl" 
    [formlyAttributes]="field" 
    [forceSelection]="true"
    [placeholder]="to.label ? to.label + ' ' + '( Nhấn enter để tìm kiếm )' : '( Nhấn enter để tìm kiếm )'"
    [completeOnFocus]="true"
    [dropdownAriaLabel]="'sadasd'"
    [suggestions]="to['results']"
    [showEmptyMessage]="false"
    [emptyMessage]="'Không tìm thấy dữ liệu search'"
    [style]="{'width':'100%'}" 
    [inputStyle]="{'width':'100%'}"
    (completeMethod)="to['searchObject'](to, $event, field)"
    (onSelect)="to['change'] && to['change'](field, $event)"
    [appendTo]="'body'"
    [ngClass]="{'ng-pristine ng-invalid ng-star-inserted ng-dirty ng-touched': ((formControl.touched || options.formState.submitted) && formControl.getError('required')) }"
    ></p-autoComplete>
    <span class="ng-invalid text-red-500" *ngIf="((formControl.touched || options.formState.submitted) && formControl.getError('required'))">Trường bắt buộc nhập</span>
</div>