
const host = {
  socketServer: 'https://chat.sunshineservice.vn:3001',
  authServer: 'https://idp-dev.unicloudgroup.com.vn/realms/realm_ssg_service',
  apiServer: 'https://dev.api.unicloudgroup.com.vn',
  apiCoreServer: 'https://dev.api.core.unicloudgroup.com.vn',
  apiShomeServer: 'https://dev.api.resident.unicloudgroup.com.vn', //server prod
  uploadServer: 'https://data.sunshinegroup.vn/api/v1/FileHandler',
  apiServiceConfig: 'https://dev-api-service-config.unicloudgroup.com.vn'
};

const authenSettings = {
  authority: host.authServer,
  client_id: 'web_ssv_service',
  redirect_uri: 'https://dev-web-service.unicloudgroup.com.vn/auth-callback',
  post_logout_redirect_uri: 'https://dev-web-service.unicloudgroup.com.vn',
  response_type: 'id_token token',
  scope: 'openid profile api_sre api_core_bigtec api_home_service api_uni_config',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://dev-web-service.unicloudgroup.com.vn/silent-refresh.html'
};

export const environment = {
  production: false,
  apiBase: host.apiServer,
  apiCoreBase: host.apiCoreServer,
  apishomeBase: host.apiShomeServer,
  apiServiceConfig: host.apiServiceConfig,
  authBase: host.authServer + '/connect/token',
  socketServer: host.socketServer,
  uploadServer: host.uploadServer,
  url_conversation: host.socketServer + '/api/v1/conversation',
  url_member: host.socketServer + '/api/v1/member',
  url_chatuserins: host.socketServer + '/api/v1/user',
  url_getgrouptype: host.socketServer + '/api/v1/grouptype',
  apiChatGetGroupType: host.socketServer + '/api/v1/grouptype',
  authenSettings: authenSettings,
  firebase: {
    apiKey: 'AIzaSyAb3orVc8nnGT0L2JgbdzXrRND393mFiFU',
    authDomain: 'sunshine-app-production.firebaseapp.com',
    databaseURL: 'https://sunshine-app-production.firebaseio.com',
    projectId: 'sunshine-app-production',
    storageBucket: 'sunshine-app-production.appspot.com',
  },
  webId: 'f0456016-93e0-49e4-9868-cb19ae303472'
};



