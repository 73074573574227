

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../auth/error.service';
const apiShomeBaseUrl = environment.apishomeBase;
@Injectable()
export class CardApiService {
    private _http = inject(HttpClient);
    private _authService = inject(AuthService);
    private _messageService = inject(MessageService);
    private _errorService = inject(ErrorService)
    constructor() {
    }
    options = {
        // headers: new HttpHeaders({
        //     Authorization: this._authService.getAuthorizationHeaderValue(),
        //     'Content-Type': 'application/json',
        // })
    };


    getVehiclePaymentByDayInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetVehiclePaymentByDayInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getCardInfoV2(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetCardInfoV2` + params, this.options).pipe(this.ErrorAsync());
    }

    setVehiclePaymentByDayInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/card/SetVehiclePaymentByDayInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    setCardInfoV2(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/card/SetCardInfoV2`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    delCard(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/card/DeleteCard?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    delVehicleCard(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/card/DeleteVehicleCard?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    setCardLocked(body: {cardCd: string, status: number}): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v2/card/SetCardLocked`, body, this.options).pipe(this.ErrorAsync());
    }

    getResidentVehicleFilter(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetResidentVehicleFilter`, this.options).pipe(this.ErrorAsync());
    }

    getResidentVehiclePage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetResidentVehiclePage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getResidentCardFilter(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetResidentCardFilter`, this.options).pipe(this.ErrorAsync());
    }

    getResidentCardPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetResidentCardPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getGuestCardPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetGuestCardPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getCardInfo(type: string, params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetInfo/${type}?` + params, this.options).pipe(this.ErrorAsync());
    }

    setGuestCardInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/card/SetGuestCardInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getCardBasePage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetCardBasePage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getClassifyInfo(idGuid_Cd: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/ClassifyInfo/${idGuid_Cd}`, this.options).pipe(this.ErrorAsync());
    }

    setClassify(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/card/Classify`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    delCardBase(idCard: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/card/DeleteCardBase/${idCard}`, this.options).pipe(this.ErrorAsync());
    }

    private ErrorAsync() {
        return catchError(error => {
            this.handleError(error)
            return of(error.error);
        })
    }

    private handleError(error: any) {
        this._errorService.setError(error.status);
        this._messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error });
    }
}
