import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Component, inject, Input, OnInit} from '@angular/core';
import {ErrorService} from 'src/app/services/auth/error.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-loading-grid',
  templateUrl: './loading-grid.component.html',
  styleUrls: ['./loading-grid.component.css']
})
export class LoadingGridComponent implements OnInit {
  private _errorService = inject(ErrorService);
  private spinner = inject(NgxSpinnerService);
  @Input() gridtype = 'hsns';
  @Input() cols: number = 20;
  @Input() lists: number = 20;
  listThs: any[] = [];
  isLoad = true;
  public listDatasLoading: any[] = [];
  public columnDefs: any[] = [];

  async ngOnInit() {
    this.spinner.show();
    this.listDatasLoading = Array(this.lists).fill(1).map((x, i) => i);
    this.columnDefs = Array(this.cols).fill(1).map((x, i) => i);
    this._errorService.fetchError().subscribe((res: number) => {
      if (res === 404 || res === 500 || res === 400 || res === 401) {
        this.isLoad = false;
        this.spinner.hide();
      }
    });

  }
}

