export const TYPES = [
    'dropdown',
    'select',
    'selectTree',
    'multiSelect',
    'checkboxList',
    'checkboxradiolist',
    'autocomplete',
]

export const TYPESINPUT = [
    'input'
]

export const TYPESINPUTEDIT = [
    'inputedit'
]

export const TYPESINPUTSEARCH = [
    'inputsearch'
]

export const TYPESDECIMAL = [
    'number',
    'decimal',
]

export const TYPESCURRENCY = [
    'currency',
]

export const TYPESMULTISELECT = [
    'multiSelect',
]

export const TYPETREESELECT = [
    'selectTree',
    'selectTrees',
]

export const TYPESDROPDOWN = [
    'select',
    'dropdown',
]

export const TYPESTEXTAREA = [
    'textarea',
]

export const TYPESCHECKBOX = [
    'checkbox',
]

export const TYPESDATETIME = [
    'datetime',
    'datefulltime',
]

export const TYPESTIME = [
    'time'
]

export const TYPESMARKDOWN = [
    'markdown'
]

export const TYPESAUTOCOMPLETE = [
    'autocomplete',
    'autocompletes'
]
export const TYPESIMAGE = [
    'image',
]

export const TYPEUPLOADEDFILE = [
    'file',
];

export const TYPELINKURL = [
    'linkUrl',
]

export const TYPEINPUTDIALOG = [
    'dialog',
];
  
export const IMAGETYPE = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
export const DOCTYPE = ['docx', 'doc', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/json'];
export const PDFTYPE = ['pdf', 'application/pdf'];
export const EXCELTYPE = ['csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
export const ZIPTYPE = ['application/x-zip-compressed', 'application/octet-stream'];
