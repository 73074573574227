import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TreeModule } from 'primeng/tree';
import { MediaListComponent } from './media-list.component';
@NgModule({
  declarations: [
    MediaListComponent
   ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TreeModule
  ],
  exports: [MediaListComponent],
  entryComponents: [],
  providers: []
})
export class MediaListModule { }
