<div class="grid-default" #container>
    <div class="wrap-grid grid-full-height">
        <app-list-grid-angular [idGrid]="'editGrid'" *ngIf="columnDefs.length > 0" [isShowButton] ="true" [height] = "800" [rowSelection]="'multiple'" [typeConfig]="typeConfig"
        [listsData]="listsData" (doubleClicked)="doubleClicked($event)"  [floatingFilter]="true"
        (showConfig)="cauhinh()"
        [columnDefs]="columnDefs"></app-list-grid-angular>
       
    </div>
    
</div>
<div class="btns" style="position: fixed;
right: 100px;
top: 30px;">
    <p-button label="{{'_Xác nhận lưu cấu hình_' | translate }}" icon="uni-icon uni-send uni-white-color" (onClick)="Save()"></p-button>
</div>

<p-dialog header="{{'_Cài đặt cấu hình_' | translate }}" [(visible)]="displaySetting" (onHide)="find()" [modal]="true" [style]="{width: '50vw'}" [maximizable]="true"
    [draggable]="false" [resizable]="false" [focusTrap]="false" styleClass="popup-setting">
    <app-config-grid-table-form *ngIf="displaySetting && gridKey" [typeConfig]="'TableInfo'"   [gridKey]="gridKey"></app-config-grid-table-form>
</p-dialog>

