import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {finalize, Subject, takeUntil} from 'rxjs';
import {cloneDeep} from 'lodash';
import queryString from 'query-string';
import {MessageService} from 'primeng/api';
import { BButton } from 'src/app/common/form-detail/buttons.mode';
import { CardVehicleService } from 'src/app/services/card-service/card-vehicle.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-vehicle-guest-info',
  templateUrl: './vehicle-guest-info.component.html',
  styleUrls: ['./vehicle-guest-info.component.scss']
})
export class VehicleGuestInfoComponent implements OnInit, OnDestroy {
  @Input() cardVehicleId: number | null = null;
  public listViews: any[] = [];
  optionsButon: BButton[] = [];
  private readonly unsubscribe$: Subject<void> = new Subject();
  public detailInfo: any = null;
  @Output() callback = new EventEmitter<any>();

  constructor(
    private apiCardVehicleService: CardVehicleService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {
    this.getCardvehicleInfo();
  }

  getCardvehicleInfo() {
    this.spinner.show();
    this.listViews = [];
    const type = 'guest'
    const queryParams = queryString.stringify({ id: this.cardVehicleId });
    this.apiCardVehicleService.getCardvehicleInfo(type, queryParams)
      .pipe(takeUntil(this.unsubscribe$),
      finalize(()=> {
        this.spinner.hide()
      }))
      .subscribe((results: any) => {
        if (results.status === 'success') {
          const datass = cloneDeep(results.data.group_fields)
          this.listViews = [...datass];
          this.detailInfo = results.data;
        }
      });
  }

  callbackForm(event: any) {
    this.spinner.show();
    const params = {
      ...this.detailInfo, group_fields: event.forms
    };
    this.apiCardVehicleService.setGuestVehicleCardInfo(params)
      .pipe(takeUntil(this.unsubscribe$),
      finalize(()=> {
        this.spinner.hide()
      }))
      .subscribe((results: any) => {
        if (results.status === 'success') {
          this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results.message });
          this.callback.emit();
        } else {
          this.messageService.add({
            severity: 'error', summary: 'Thông báo', detail: results.message
          });
          this.callback.emit();
        }
      });
  }

  close(data: any) {
    if (data.event === 'CauHinh') {
      this.getCardvehicleInfo();
    } else {
      this.callback.emit();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
