
<div class="main-grid">
    <section class="bread-crumb">
        <div class="flex bet bottom">
            <div class="col-5 pd-0">
                <app-hrm-breadcrumb  [items]="itemsBreadcrumb" *ngIf="itemsBreadcrumb.length > 0" ></app-hrm-breadcrumb>
            </div>
            <div class="flex gap-10 col-7 justify-content-end pd-0">
                <div class="fields search col-6 md:col-6 lg:col-6 xl:col-4  pd-0 d-flex mb-0">
                    <div [CheckHideActions]="{url: MENUACTIONROLEAPI.GetVehiclePage.url, action: ACTIONS.SEARCH}">
                        <svg (click)="find()" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.02893 0.850098C4.51087 0.850098 0.849609 4.51552 0.849609 9.03539C0.849609 13.5553 4.51087 17.2207 9.02893 17.2207C10.8456 17.2207 12.5254 16.6271 13.8828 15.6241L17.1276 18.8716C17.4984 19.2427 18.0999 19.243 18.4711 18.8721C18.8422 18.5013 18.8425 17.8998 18.4716 17.5286L15.2709 14.3252C16.4787 12.8991 17.2083 11.0518 17.2083 9.03539C17.2083 4.51552 13.547 0.850098 9.02893 0.850098ZM2.74961 9.03539C2.74961 5.56338 5.56169 2.7501 9.02893 2.7501C12.4962 2.7501 15.3083 5.56338 15.3083 9.03539C15.3083 10.8568 14.5355 12.496 13.2976 13.645C12.1765 14.6858 10.6778 15.3207 9.02893 15.3207C5.56169 15.3207 2.74961 12.5074 2.74961 9.03539Z" fill="#2B2F33" fill-opacity="0.6"/>
                        </svg>
                    </div>
                    <input type="text" [CheckHideActions]="{url: MENUACTIONROLEAPI.GetVehiclePage.url, action: ACTIONS.SEARCH}"
                        [(ngModel)]="query.filter" 
                        class="text-color p-2 border-1 border-solid surface-border border-round focus:border-primary" 
                        pInputText placeholder="{{'_Nhập từ khóa tìm kiếm_' | translate}}" (keydown.enter)="query.offSet = 0; first = 0;load()"/>
                    <p-button styleClass="p-button-sm btn-option" (click)="op.toggle($event)" [CheckHideActions]="{url: MENUACTIONROLEAPI.GetVehiclePage.url, action: ACTIONS.SEARCH}">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 14V16H6V14H0ZM0 2V4H10V2H0ZM10 18V16H18V14H10V12H8V18H10ZM4 6V8H0V10H4V12H6V6H4ZM18 10V8H8V10H18ZM12 6H14V4H18V2H14V0H12V6Z" fill="#76809B"/>
                        </svg>
                        <span class="dot"></span>
                    </p-button>
                    <span class="remove-text" *ngIf="query.filter" (click)="query.filter = ''; query.pageSize=1000;load()">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.76623 8.00006L16 14.2338L14.2338 16.0001L8 9.76629L1.76623 16.0001L0 14.2338L6.23377 8.00006L0 1.76629L1.76623 6.10352e-05L8 6.23383L14.2338 6.10352e-05L16 1.76629L9.76623 8.00006Z" fill="#76809B"/>
                        </svg>
                    </span>
                </div>
                <p-button type="button" styleClass="box-icon" (click)="menu.toggle($event)" icon="uni-icon uni-dots" label=""></p-button>
                <p-tieredMenu styleClass="dots-menu-content" #menu [model]="menuItem" [popup]="true" appendTo="body"></p-tieredMenu>
            </div>
        </div>
    </section>
    <p-overlayPanel #op [style]="{'width': '40rem'}" [dismissable]="false" appendTo="body" >
        <ng-template pTemplate>
            <app-form-detail *ngIf="listViewsFilter.length> 0" [detailInfo]="detailInfoFilter" (changeValueFilterDate)="handleChangeValueFilterDate($event)" [buttons]="optionsButonFilter" (callback)="filterLoad($event);op.hide()" (reset)="resetFilter($event)"  [isFiler]="true" [listForms]="listViewsFilter"
                (close)="close($event);op.hide()" ></app-form-detail>
        </ng-template>
    </p-overlayPanel>
    <section class="content pb-0 pl-0">
        <div class="grid-default" #container [CheckHideActions]="{url: MENUACTIONROLEAPI.GetVehiclePage.url, action: ACTIONS.VIEW}">
            <app-list-grid-angular  
                #gridList
                *ngIf="columnDefs.length > 0"
                (onCellClicked)="onCellClicked($event)"
                [listsData]="listsData" [height]="heightGrid"
                (showConfig)="cauhinh()"
                [floatingFilter]="true"
                [idGrid]="gridKey + '_45dfs'"
                [columnDefs]="columnDefs"></app-list-grid-angular>
                <app-loading-grid *ngIf="isLoading" [cols]="10" [lists]="17"></app-loading-grid>
            <div class="paginator">
                <span>
                    {{'_Từ_' | translate}} {{ countRecord.currentRecordStart }} {{'_đến_' |  translate}}
                    {{ countRecord.currentRecordEnd }}
                    {{'_trên tổng số_' |  translate}}
                    {{ countRecord.totalRecord }} {{'_kết quả_' |  translate}}</span>
                <p-paginator [rows]="query.pageSize" [totalRecords]="countRecord.totalRecord" [first]="first"
                    (onPageChange)="paginate($event)" [rowsPerPageOptions]="[20,50,80,100,200,500,{ showAll: 'ALL' }]">
                </p-paginator>
            </div>
        </div>
    </section>
</div>

<p-sidebar (onHide)="displayDetail = false"
           [style]="{ width: '70%' }"
           [(visible)]="displayDetail"
           [autoZIndex]="true"
           position="right">
  <ng-template pTemplate="header">
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z" fill="#2B2F33"/>
      <path d="M8 0L6.59 1.41L11.17 6L6.59 10.59L8 12L14 6L8 0Z" fill="#2B2F33"/>
    </svg> &nbsp;&nbsp;
    {{'_Chi tiết thẻ xe khách_' | translate }}</ng-template>
  <ng-template pTemplate="footer"></ng-template>
  <section>
       <app-vehicle-guest-info *ngIf="displayDetail" [cardVehicleId]="cardVehicleId"></app-vehicle-guest-info> 
  </section>
</p-sidebar>

<p-sidebar (onHide)="hideFormPaymentByDay()"
           [style]="{ width: '70%' }"
           [(visible)]="displayPaymentByDay"
           [autoZIndex]="true"
           position="right">
  <ng-template pTemplate="header">
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z" fill="#2B2F33"/>
      <path d="M8 0L6.59 1.41L11.17 6L6.59 10.59L8 12L14 6L8 0Z" fill="#2B2F33"/>
    </svg> &nbsp;&nbsp;
    {{'_Gia hạn thẻ_' | translate }}</ng-template>
  <ng-template pTemplate="footer"></ng-template>
  <section>
    <app-form-detail
    *ngIf="listViewPaymentByDay.length > 0"
    [detailInfo]="detailInfoPaymentByDay"
    [buttons]="optionsButon"
    (callback)="callbackFormPaymentByDay($event)"
    (endDate)="handleEndDate($event)"
    (callbackReload)="reloadListForm($event)"
    [isExtend]="true"
    [listForms]="listViewPaymentByDay">
    </app-form-detail>
  </section>
</p-sidebar>

<p-dialog header="{{'_Cài đặt cấu hình_' | translate }}" [(visible)]="displaySetting" (onHide)="find()" [modal]="true" [style]="{width: '50vw'}" [maximizable]="true"
    [draggable]="false" [resizable]="false" [focusTrap]="false" styleClass="popup-setting">
    <app-config-grid-table-form *ngIf="displaySetting && gridKey" [typeConfig]="'TableInfo'"   [gridKey]="gridKey"></app-config-grid-table-form>
</p-dialog>


