import { Component } from '@angular/core';

@Component({
  selector: 'app-nz-radiobutton',
  templateUrl: './nz-radiobutton.component.html',
  styleUrls: ['./nz-radiobutton.component.scss']
})
export class NzRadiobuttonComponent {

}
