export const ACTIONS = {
    STOP_PUSH: 'stop_push',
    PAYMENT_DEBIT : 'payment_debit',
    SERVICE : 'service',
    RECEIVE : 'receive',
    FOLLOW: 'follow',
    AUTHEN : 'authen',
    UPDATE : 'update',
    UTILITIES: 'utilities',
    BILL : 'bill',
    RECEIPT : 'receipt',
    NOTIFY : 'notify',
    EXPECT : 'expect',
    PRICING : 'pricing',
    EMAIL : 'email',
    MESSAGE : 'message',
    OPEN : 'open',
    LOCK: 'lock',
    SETUP : 'setup',
    EXCEL: 'excel',
    VIEW: 'view',
    DELETE: 'delete',
    ADD: 'add',
    CONFIG_FORM: 'config_form',
    CONFIG_LIST: 'config_list',
    EXPORT: 'export',
    EDIT: 'edit',
    IMPORT: 'import',
    SEARCH: 'search',
    VIEW_DETAIL: 'view_detail',
    UN_LOCK: 'un_lock',
    PAYMENT_BY_DAY: 'payment_by_day',
    CONFIRM : 'confirm',
    MANAGER_CARD : 'manager_card',
    MANAGER_CARD_TYPE : 'manager_card_type',
    CALCULATE : 'calculate',
    TRANSACTION_DETAILS : 'transaction_details',
    TRANSFER : 'transfer',
    COMPLETE : 'complete',
    SETTING : 'setting',
    CANCEL : 'cancel',
    PRINT : 'print',
}

export const MENUACTIONROLEAPI = {
    'GetApartmentPage': {
        'name': 'Danh sách căn hộ',
        'api': '05384fec-8c64-4533-9c0e-439859cc4c02',
        'menu': '',
        'url': '/apartment/apartment-list'
    },
    'GetResidentCardPage': {
        'name': 'Thẻ cư dân',
        'api': '9f7af540-f435-42fa-8931-dc9bfc9f88b4',
        'menu': '',
        'url': '/apartment/the-cu-dan'
    },
    'GetResidentVehiclePage': {
        'name': 'Xe cư dân',
        'api': '65170df6-1e1d-4439-a6c0-dfdbdc2b4920',
        'menu': '',
        'url': '/apartment/xe-cu-dan'
    },
    'GetApartmentHouseholdPage': {
        'name': 'Quản lý hộ khẩu',
        'api': '2264bad9-e2aa-4eb8-adbe-c45e44e0aa2f',
        'menu': '',
        'url': '/apartment/apartment-house-hold'
    },
    'GetAppNotifyPage': {
        'name': 'Thông báo',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/apartment/page-notify'
    },
    'GetCardDailyList': {
        'name': 'Thẻ lượt',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/card/vehicle-daily'
    },
    'GetGuestCardPage': {
        'name': 'Thẻ khách',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/card/custcard'
    },
    'GetVehiclePage': {
        'name': 'Xe khách',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/card/vehicle-guest'
    },
    'GetCardBasePage': {
        'name': 'Quản lý kho thẻ',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': 'card-manager'
    },
    'GetServiceLivingMeterPage': {
        'name': 'Chỉ số công tơ điện',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/fee/tien-dien'
    },
    'GetServiceWaterMeterPage': {
        'name': 'Chỉ số công tơ nước',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/fee/tien-nuoc'
    },
    'GetServiceExpectedPage': {
        'name': 'Dự thu',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/fee/expec-table'
    },
    'GetServiceReceivablePage': {
        'name': 'Hoá đơn',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/fee/hoa-don'
    },
    'GetReceiptPagev2': {
        'name': 'Biên nhận',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/fee/bien-nhan'
    },
    'GetRequestStatusList': {
        'name': 'Yêu cầu',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/request/request-list'
    },
    
    'GetFeedbackList': {
        'name': 'Ý kiến khách hàng',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/request/feedback'
    },
    'GetServicePriceList': {
        'name': 'Bảng giá dịch vụ',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/hoat-dong/tai-lieu-chung'
    },
    'GetFormsTypePage': {
        'name': 'Dự án',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/setting/project-setting'
    },
    'GetAnnualAddPage': {
        'name': 'Lịch sử hoạt động',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/chinh-sach/phep-bu'
    },
    'GetAnnualLeavePage' : {
        'name': 'Báo cáo',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/chinh-sach/phep-nam'
    },
  }

const LOCAL_STORAGE = {
    SETTINGS: 'ServiceSettings'
}

export default LOCAL_STORAGE;


