
import * as numeral from "numeral";
import { DatePipe } from '@angular/common';
import { inject } from "@angular/core";
import * as moment from "moment";

export function AgGridFn(lists: Array<any>) {
    let arrAgGrids: any[] = [];
    lists.forEach(value => {
        let row: any = null;
        if (value.isStatusLable) {
            row = {
                headerName: value.columnCaption,
                field: value.columnField,
                cellClass: value.cellClass,
                menuTabs: ['filterMenuTab', 'generalMenuTab'],
                headerClass: 'BGE8E9ED',
                filter: value.isFilter ? 'agTextColumnFilter' : '',
                sortable: true,
                minWidth: value.columnWidth,
                width: value.columnWidth,
                cellRenderer: (params: any) => {
                    return `${params.value}`
                },
                hide: value.isHide ? true : false,
                pinned: value.pinned,
                headerTooltip: value.columnCaption,
                tooltipField: value.columnField
            }
        } else {
            if (value.columnField === 'avatarUrl' || value.fieldType === 'image') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    headerClass: 'BGE8E9ED',
                    // cellClass: value.cellClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: false,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    cellRenderer: "avatarRendererFull",
                    headerTooltip: value.columnCaption,
                    tooltipField: value.columnField,
                    cellClass: ['text-center', 'text-right', 'border-right', 'd-flex', 'align-items-center', 'justify-content-center'],
                }
            } else if (value.fieldType === 'check') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    cellClass: value.cellClass,
                    headerClass: 'BGE8E9ED',
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: false,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    cellRenderer: (params: any) => {
                        return `<span class="custom-control custom-checkbox float-left" style="margin-top: 7%;">
                        <input type="checkbox" ${params.value ? 'checked' : ''} class="custom-control-input" style="pointer-events: none;" >
                        <label class="custom-control-label"></label>
                      </span>`;
                    },
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    headerTooltip: value.columnCaption,
                }
            } else if (value.fieldType === 'decimal') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    cellClass: value.cellClass || [],
                    headerClass: value.headerClass ? value.headerClass : 'BGE8E9ED',
                    cellStyle: value.cellStyle,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    cellClassRules: value.conditionClass,
                    filter: value.isFilter ? 'agSetColumnFilter' : '',
                    sortable: true,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    cellRenderer: value.isMasterDetail ? 'agGroupCellRenderer' : '',
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    editable: value.editable ? value.editable : false,
                    aggFunc: 'sum',
                    filterParams: {
                        caseSensitive: true,
                        textFormatter: (r: any) => TextFormatter(r),
                        cellRenderer: cellRendererSanPham,
                    },
                    tooltipField: value.columnField,
                    headerTooltip: value.columnCaption,
                    valueFormatter: value.customFormat ? formatMargin : formatNumber2
                };
            } else if (value.fieldType === 'datetime') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    cellClass: value.cellClass || [],
                    headerClass: value.headerClass ? value.headerClass : 'BGE8E9ED',
                    cellStyle: value.cellStyle,
                    cellClassRules: value.conditionClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    // cellRenderer: value.isMasterDetail ? 'agGroupCellRenderer' : '',
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    editable: value.editable ? value.editable : false,
                    aggFunc: 'sum',
                    tooltipField: value.columnField,
                    headerTooltip: value.columnCaption,
                    cellRenderer: (data: any) => {
                        return data.value ? moment(data.value).format('DD/MM/YYYY') : ''
                    }
                };
            }else if (value.fieldType === 'datefulltime') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    cellClass: value.cellClass || [],
                    headerClass: value.headerClass ? value.headerClass : 'BGE8E9ED',
                    cellStyle: value.cellStyle,
                    cellClassRules: value.conditionClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    // cellRenderer: value.isMasterDetail ? 'agGroupCellRenderer' : '',
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    editable: value.editable ? value.editable : false,
                    aggFunc: 'sum',
                    tooltipField: value.columnField,
                    headerTooltip: value.columnCaption,
                    cellRenderer: (data: any) => {
                        return data.value ? moment(new Date(data.value)).format('DD/MM/YYYY HH:mm') : ''
                    }
                };
            } else {
                row = {
                    width: value.columnWidth,
                    minWidth: value.columnWidth,
                    // maxWidth: value.cellClass && value.cellClass.length > 0 && value.cellClass.indexOf('not-auto') > -1 ? value.columnWidth : null,
                    headerClass: 'BGE8E9ED',
                    // suppressSizeToFit: true,
                    headerName: value.columnCaption,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    field: value.columnField,
                    cellClass: value.cellClass ? value.cellClass : ["border-right", "d-flex", "align-items-center"],
                    filter: value.isFilter ? 'agSetColumnFilter' : '',
                    sortable: true,
                    editable: value.editable ? value.editable : false,
                    filterParams: {
                        caseSensitive: true,
                        textFormatter: (r: any) => TextFormatter(r),
                        cellRenderer: cellRendererSanPham,
                    },
                    cellRenderer: value.isMasterDetail ? 'agGroupCellRenderer' : '',
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    tooltipField: value.columnField,
                    headerTooltip: value.columnCaption
                }
                
            }
        }
        arrAgGrids.push(row);
    })

    return arrAgGrids
}



export function AgGridFnEdit(lists: Array<any>) {
    let arrAgGrids = [];
    for (let value of lists) {
        let row: any = null;
        if (value.isStatusLable) {
            row = {
                headerName: value.columnCaption,
                field: value.columnField,
                cellClass: value.cellClass,
                menuTabs: ['filterMenuTab', 'generalMenuTab'],
                suppressSizeToFit: checksuppressSizeToFit(value),
                filter: value.isFilter ? 'agTextColumnFilter' : '',
                sortable: true,
                minWidth: value.columnWidth,
                width: value.columnWidth,
                cellRenderer: (params: any) => {
                    return `${params.value}`
                },
                hide: value.isHide ? true : false,
                pinned: value.pinned,
                headerTooltip: value.columnCaption,
                tooltipField: value.columnField
            }
        } else {
            if (value.columnField === 'avatarUrl' || value.fieldType === 'image') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    // cellClass: value.cellClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    cellRenderer: "avatarRendererFull",
                    headerTooltip: value.columnCaption,
                    tooltipField: value.columnField,
                    cellClass: ['text-center', 'text-right', 'border-right'],
                    // valueFormatter: value.fieldType == 'decimal' ? ""
                }
            } else if (value.fieldType === 'check') {
                row = {
                    headerName: value.columnCaption,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    field: value.columnField,
                    cellClass: value.cellClass ? value.cellClass : [],
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    editable: true,
                    cellRenderer: (params: any) => {
                        return ` <span class="custom-control custom-checkbox float-left" style="margin-top: 7%;">
                      <input type="checkbox" ${params.value ? 'checked' : ''} disabled class="custom-control-input"  >
                      <label class="custom-control-label"></label>
                    </span>`;
                    },
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    headerTooltip: value.columnCaption,
                    // tooltipField: value.columnField
                    // valueFormatter: value.fieldType == 'decimal' ? "x.toLocaleString()" : ""
                }
            } else if (value.fieldType === 'decimal') {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    cellClass: value.cellClass || [],
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    headerClass: value.headerClass,
                    cellStyle: value.cellStyle,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    cellClassRules: value.conditionClass,
                    filter: value.isFilter ? 'agTextColumnFilter' : '',
                    sortable: true,
                    cellRenderer: value.isMasterDetail ? 'agGroupCellRenderer' : '',
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    tooltipField: value.columnField,
                    headerTooltip: value.columnCaption,
                    valueFormatter: value.customFormat ? formatMargin : formatNumber2
                };
            } else {
                row = {
                    headerName: value.columnCaption,
                    field: value.columnField,
                    menuTabs: ['filterMenuTab', 'generalMenuTab'],
                    cellClass: value.cellClass ? value.cellClass : [],
                    filter: value.isFilter ? 'agSetColumnFilter' : '',
                    sortable: true,
                    minWidth: value.columnWidth,
                    width: value.columnWidth,
                    editable: true,
                    filterParams: {
                        caseSensitive: true,
                        textFormatter: (r: any) => TextFormatter(r),
                        cellRenderer: cellRendererSanPham,
                    },
                    cellRenderer: value.isMasterDetail ? 'agGroupCellRenderer' : '',
                    hide: value.isHide ? true : false,
                    pinned: value.pinned,
                    tooltipField: value.columnField,
                    headerTooltip: value.columnCaption
                }
            }
        }

        arrAgGrids.push(row);
    }
    return arrAgGrids
}

function checksuppressSizeToFit(value: any) {
    if (value && value.length > 0) {
        return value.cellClass.indexOf('no-auto') > -1 ? true : false
    }
    return false

}


export function cellRendererSanPham(params: any) {
    let rowData = [];
    if (!params.value || params.value === '(Select All)') {
        return params.value;
    }
    params.api.forEachNodeAfterFilter((node: any) => {
        rowData.push(node.data)
    });
    return params.value;
}

export function TextFormatter(r: any) {
    if (r == null) return null;
    return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
}



export function formatNumber2(params: any) {
    const number = params.value;
    return numeral(number).format('0,0[.][000]')
}

export function formatMargin(params: any) {
    const numb = +params.value;
    return (numb * 100).toFixed(2);
}


export function getParamString(lists: any[]) {
    const datepipe = inject(DatePipe);
    const params: any = {};
    lists.forEach(results => {
        results.fields.forEach((data: any) => {
            if (data.columnType === 'datetime' && data.isVisiable) {
                params[data.field_name] = data.columnValue ? datepipe.transform(new Date(data.columnValue), 'DD/MM/YYYY') : null
            } else if (data.columnType === 'datefulltime' && data.isVisiable) {
                params[data.field_name] = data.columnValue ? datepipe.transform(new Date(data.columnValue), 'DD/MM/YYYY HH:mm:ss') : null
            } else if (data.columnType === 'timeonly') {
                params[data.field_name] = data.columnValue ? `${data.columnValue}:00` : null

            } else if (data.columnType === 'selectTree') {
                params[data.field_name] = data.columnValue ? data.columnValue.orgId : null;
                delete data.options;
            } else if (data.columnType === 'currency') {
                params[data.field_name] = data.columnValue ? numeral(data.columnValue).value() : null
            } else if ((data.columnType === 'select') || (data.columnType === 'dropdown')) {
                params[data.field_name] = data.columnValue ? isNaN(data.columnValue) ? data.columnValue : parseInt(data.columnValue) : null;
                delete data.options;
            } else if ((data.columnType === 'multiSelect')) {
                params[data.field_name] = data.columnValue ? data.columnValue.map((d: any) => d.code).toString() : null;
                delete data.options;
            } else if ((data.columnType === 'checkboxList')) {
                params[data.field_name] = data.columnValue ? data.columnValue.toString() : null;
                delete data.options;
            } else if ((data.columnType === 'autocomplete')) {
                params[data.field_name] = data.columnValue ? data.columnValue.code : null;
                delete data.options;
            } else if ((data.columnType === 'number')) {
                data.columnValue = data.columnValue ? formatNumber(+data.columnValue) : 0;
                params[data.field_name] = numeral(data.columnValue).value();
            } else if ((data.columnType === 'input')) {
                params[data.field_name] = data.columnValue ? data.columnValue : '';
            }
        })
    });

    return params;
}

function formatNumber(value: any) {
    return numeral(value).format('0,0[.][00]');
}


export function ShowFullScreen() {
    const c: any = document.querySelector(".bread-filter");
    // const d: any = document.querySelector(".filterInput");
    const e: any = document.querySelector(".paginator");
    let eHeight = 0;
    if (e) {
        eHeight = e.clientHeight
    }
    const totalHeight = c.clientHeight + eHeight + 24;
    const main: any = document.querySelector(".main");
    main.className = main.className + ' grid-fixed';
    return window.innerHeight - totalHeight
}

export function HideFullScreen() {
    const main: any = document.querySelector(".main");
    main.className = 'main';
    const a: any = document.querySelector(".header");
    const b: any = document.querySelector(".sidebarBody");
    const c: any = document.querySelector(".bread-filter");
    // const d: any = document.querySelector(".filterInput");
    const e: any = document.querySelector(".paginator");
    let eHeight = 0;
    if (e) {
        eHeight = e.clientHeight
    }
    const totalHeight = a.clientHeight + b.clientHeight + c.clientHeight + eHeight + 45;
    return window.innerHeight - totalHeight
}

