import {Module, AllModules, RowNode, Column, ColDef, ColumnResizedEvent} from '@ag-grid-enterprise/all-modules';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {Subject, } from 'rxjs';
import {ButtonAgGridComponent} from '../ag-buttons';
import {NgxSpinnerService} from 'ngx-spinner';
import {AppApiService} from 'src/app/services/app.service';
import {TranslateService} from '@ngx-translate/core';
import {AvatarFullComponent} from '../avatarFull.component';
@Component({
  selector: 'app-list-grid-angular',
  templateUrl: './list-grid-angular.component.html',
  styleUrls: ['./list-grid-angular.component.css']
})
export class ListGridAngularComponent implements OnInit, OnChanges, AfterViewInit {


  constructor(
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private _spinner: NgxSpinnerService,
    private apiService: AppApiService,
    private translate: TranslateService,
    private elem: ElementRef,
  ) {
    this.isRowSelectable = (rowNode: any) => {
      // return rowNode.data.tontai ? false : true;
      return true;
    };
    this.translate.get(['_Không có bản ghi nào_', '_Hồ sơ nhân sự_', '_Danh sách nhân viên_'])
      .subscribe(translations => {
        this.overlayNoRowsTemplate =
          `<span class="ag-overlay-loading-center aggrid-no-data" style="border: none; background: none">${translations['_Không có bản ghi nào_']}. </span>`;
      });

    this.tooltipShowDelay = 0;
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Ẩn hiện cột',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: false,
            suppressValues: false,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
            suppressSyncLayoutWithGrid: true,
            suppressColumnMove: false,
          },
        },
      ],
      defaultToolPanel: '',
    };
    this.frameworkComponents = {
      buttonAgGridComponent: ButtonAgGridComponent,
      avatarRendererFull: AvatarFullComponent,
    };
    this.getRowHeight = (params: any) => {
      if (params.node && params.node.detail) {
        const offset = 80;
        const allDetailRowHeight = params.data.childrens?.length > 5 ? params.data.childrens.length * this.heightRow : 200;
        return allDetailRowHeight + offset;
      } else {
        return this.heightRow;
      }
    };

    this.isRowMaster = (dataItem: any) => {
      if (dataItem) {
        if (dataItem.Details) {
          return dataItem && dataItem.Details ? dataItem.Details.length > 0 : false;
        } else {
          return false;
        }
      } else {
        return false;
      }

    };
  }
  public modules: Module[] = AllModules;
  @Input() listsData: Array<any> = [];
  @Output() FnClick = new EventEmitter<any>();
  @Output() rowDoubleClicked = new EventEmitter<any>();
  @Output() firstDataRendered = new EventEmitter<any>();
  @Output() cellDoubleClicked = new EventEmitter<any>();
  @Output() onRowSelectedCallback = new EventEmitter<any>();
  @Output() rowGroupOpenedCallback = new EventEmitter<any>();
  @Output() onCellClicked = new EventEmitter<any>();
  @Output() callback = new EventEmitter<any>();
  @Output() showConfig = new EventEmitter<any>();
  @Input() typeConfig: string = 'myGrid';
  @Input() columnDefs: Array<any> = [];
  @Input() masterDetail: boolean = false;
  @Input() isConfig: boolean = true;
  @Input() rowSelection: string = 'single';
  @Input() frameworkComponents = {};
  @Input() detailCellRendererParams: any = null;
  @Input() autoGroupColumnDef: any = {};
  @Input() rowClassRules: any;
  @Input() noRowsTemplate: any = 'Không có kết quả phù hợp';
  @Input() floatingFilter: boolean = false;
  @Input() groupDefaultExpanded: number = 1;
  @Input() isShowButton: boolean = false;
  @Input() isShowTotalBottom: boolean = false;
  @Input() idGrid: string = 'myGrid';
  @Input() columnsWithAggregation: any[] = []; // danh sách sum bottom
  @Input() defaultColDef: any = {
    tooltipComponent: 'customTooltip',
    suppressSorting: false,
    sortable: false,
    resizable: true,
    filter: true,
    // floatingFilter: true,
    flex: 1,
    rowHeight: 90,
    cellClass: [],
    tooltipComponentParams: {color: '#ececec'},
  };
  @Input() domLayout: string = '';
  @Input() height: number = 0;
  @Input() heightRow: number = 37;
  @Input() headerHeight: number = 35;
  @Input() floatingFiltersHeight: number = 35;
  @Input() getContextMenuItems: any = null;
  @Input() pinnedBottomData: any[] = [];
  @Input() excelStyles: any[] = [
    {
      id: 'stringType',
      dataType: 'string'
    },
    {
      id: 'dateFormat',
      dataType: 'dateTime',
      numberFormat: {format: 'mm/dd/yyyy;@'},
    },
    {
      id: 'text-right',
      dataType: 'number',
      numberFormat: {format: '#,##0'},
    }
  ];
  public overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center aggrid-no-data" style="border: none; ">Loading...</span>';
  public overlayNoRowsTemplate =
    `<span class="ag-overlay-loading-center aggrid-no-data" style="border: none; background: none">Không có bản ghi nào. </span>`;
  sideBar: any;
  gridApi: any;
  getRowHeight: any;
  gridColumnApi: any;
  heightAuto = 0;
  tooltipShowDelay = 0;
  titlePage = '';
  listsDataCloone: any[] = [];
  isRowMaster: any;
  isRowSelectable;

  gridWidth = 0;

  private readonly unsubscribe$: Subject<void> = new Subject();

  dataChange: any = null;


  isChange: boolean = false;

  ngOnInit(): void {
    this.getDom();
  }

  getDom() {
    const pivotModeOn = document.getElementById(`${this.idGrid}`);
    pivotModeOn?.addEventListener('change', (event: any) => {
      if (event.target.ariaLabel === 'Press SPACE to toggle visibility (visible)' || event.target.ariaLabel === 'Press SPACE to toggle visibility (hidden)') {
      } else if (event.target.ariaLabel === 'Press Space to toggle row selection (checked)'
        || event.target.ariaLabel === 'Press Space to toggle row selection (unchecked)'
        || event.target.ariaLabel === 'Press Space to toggle all rows selection (checked)'
        || event.target.ariaLabel === 'Press Space to toggle all rows selection (unchecked)') {
      }
    });
  }

  handleScroll(event: any) {
    const grid = document.getElementById(`${this.idGrid}`);
    if (grid) {
      if ((event.left > 0) || (event.left > 200 && event.left < 220)
        || (event.left > 400 && event.left < 420)
        || (event.left > 600 && event.left < 620)
        || (event.left > 800 && event.left < 820)
        || (event.left > 1200)) {
        const gridBody = grid.querySelector('.ag-body-viewport') as any;
        this.autosizeAll2();
      }
    }
  }

  CellClicked(event: any) {
    this.onCellClicked.emit(event);
  }

  rowGroupOpened(event: any) {
    this.rowGroupOpenedCallback.emit(event);
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    this.gridColumnApi = params.columnApi;
    if (this.listsData.length === 0) {
      this.gridApi.showNoRowsOverlay();
      this.gridApi.sizeColumnsToFit();
    }
    this._spinner.hide();
    this.gridColumnApi.applyColumnState({ defaultState: { sort: null } });
    const allColumns = params.columnApi.getAllColumns();
    for (let i = 0; i < allColumns.length; i++) {
      const column = allColumns[i];
      this.gridWidth += column.getMinWidth();
    }
    // setTimeout(() => {

    //   this.autoSizeAll(false)
    // }, 500);
    // window.onresize = () => {
    //   this.gridApi.sizeColumnsToFit();
    // }
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    const result: any = {};
    this.gridColumnApi.getAllGridColumns().forEach((item: any) => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
  }

  calculatePinnedBottomData(target: any) {
    // **list of columns fo aggregation**
    this.columnsWithAggregation.forEach(element => {
      this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {

        if (element === 'customerName') {
          target[element] = `Khách hàng: ${this.listsData.length} `;
        } else if (element === 'areaName') {
          target[element] = `Khu vực: ${this.listsData.length} `;
        } else if (element === 'purchaseDate') {
          target[element] = `Count: ${this.listsData.length} `;
        } else {
          if (rowNode.data && rowNode.data[element]) {
            target[element] += Number(rowNode.data[element].toFixed(2));
          }
        }
      });
      // if (target[element])
      //     target[element] = target[element].toFixed(2);
    });

    return target;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('groupDefaultExpanded') && this.gridApi) {
      changes['groupDefaultExpanded']['currentValue'] === 1 ? this.gridApi.expandAll() : this.gridApi.collapseAll();
    }
    this.heightAuto = this.height;
  }

  onGridSizeChanged(params: any) {
    // option chưa dùng được
    // get the current grids width
    const gridWidth: any = document.getElementById(this.idGrid);
    const columnsToShow = [];
    const columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = params.columnApi.getAllColumns();
    for (let i = 0; i < allColumns.length; i++) {
      const column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth.offsetWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }

    // show/hide columns based on current grid width
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);

    // fill out any available space to ensure there are no gaps
    params.api.sizeColumnsToFit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onColumnResized(params: any) {
    const tableWidth = params.columns
      .reduce((i: number, current: any) => i += current.getActualWidth(), 0);
  }

  onFirstDataRendered(event: any) {
    // this.gridColumnApi.autoSizeAllColumns()
    // event.api.sizeColumnsToFit();
    // setTimeout(() => {
    //   this.autoSizeAll(true)
    //   if (this.isShowTotalBottom) {
    //     let pinnedBottomData = this.generatePinnedBottomData();
    //     this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    //   }
    //   this.gridApi.hideOverlay();
    // }, 500);
    //   event.api.forEachNode((node: any) =>
    //   node.setSelected(!!node.data && node.data.tontai)
    // );
    // event.api.forEachNode((node: any) =>
    //    node.setSelected(!!node.data && (node.data.is_check == 1 || node.data.tontai))
    // );
    let allColumnIds: any = [];
    this.gridColumnApi.getAllColumns()
      .forEach((column: any) => {
        if (column.colDef.cellClass && column.colDef.cellClass.indexOf('not-auto') < 0) {
          allColumnIds.push(column);
        } else {
          column.colDef.resizable = false;
        }
      });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    this.autosizeAll2();
  }

  autosizeAll2() {
    setTimeout(() => {
      this.gridApi.hideOverlay();
      const grid = document.getElementById(`${this.idGrid}`);
      if (grid) {
        const container = this.elem.nativeElement.querySelectorAll(`#${this.idGrid}`);
        if (container[0] && (this.gridColumnApi.columnModel.scrollWidth >= this.gridColumnApi.columnModel.bodyWidth) && this.gridApi) {
          this.sizeToFit();
        } else {
          const allColumnIds: any = [];
          this.gridColumnApi.getAllColumns()
            .forEach((column: any) => {
              if (column.colDef.cellClass && column.colDef.cellClass.indexOf('not-auto') < 0) {
                allColumnIds.push(column);
              } else {
                column.colDef.resizable = false;
              }
            });
          this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
      }
    }, 100);
  }

  ngAfterViewInit(): void {
    // this.gridApi.hideOverlay();
  }

  sizeToFit() {
    if (this.gridApi) {
      const allColumnIds: any = [];
      this.gridColumnApi.getAllColumns()
        .forEach((column: any) => {
          if (column.colDef.cellClass && column.colDef.cellClass.indexOf('not-auto') < 0) {
            allColumnIds.push(column);
          } else {
            column.colDef.suppressSizeToFit = true;
            allColumnIds.push(column);
          }
        });
      this.gridApi.sizeColumnsToFit();
    }
  }

  autoSizeAll(skipHeader: boolean) {
    if (this.gridColumnApi) {
      if (this.gridColumnApi) {
        if ((this.gridColumnApi.columnModel.scrollWidth >= this.gridColumnApi.columnModel.bodyWidth) || (this.gridColumnApi.columnModel.bodyWidth < 1000)) {
          this.sizeToFit();
        } else {

          const allColumnIds: any = [];
          this.gridColumnApi.getAllColumns()
            .forEach((column: any) => {
              if (column.colDef.cellClass.indexOf('not-auto') < 0) {
                allColumnIds.push(column);
              } else {
                column.colDef.resizable = false;
              }
            });
          this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
      }
    }

  }

  getMainMenuItems = (params: any) => {
    let athleteMenuItems = params.defaultItems.slice(0);
    athleteMenuItems = [{
      name: this.translate.instant('_Cấu hình_'),
      icon: '<span class=\'pi pi-cog\'></span>',
      action: (event: any) => {
        this.showConfig.emit();
      },
    }, ...athleteMenuItems];
    return athleteMenuItems;
  }

  RowDoubleClicked(event: any) {
    this.rowDoubleClicked.emit(event);
  }

  CellDoubleClicked(event: any) {
    this.cellDoubleClicked.emit(event);
  }

  onRowSelected(event: any) {
    if (!event.node.isSelected() && this.isChange) {
      this.dataChange = event.data;

      // if (this.typeConfig === 'FormInfo') {
      //   this.callApiForm();
      // } else {
      //   this.callApi();
      // }
    }
    this.firstDataRendered.emit({datas: this.gridApi.getSelectedRows(), event: event});
    this.callback.emit(this.gridApi.getSelectedRows());
  }

  // xem lại
  callApiForm() {
    if (this.listsDataCloone.map((d: any) => d.field_name).indexOf(this.dataChange.field_name) < 0) {
      for (const key in this.dataChange) {
        if (key === 'isSpecial' || key === 'isRequire' || key === 'isDisable' || key === 'isVisiable' || key === 'hideInApp' || key === 'isChange' || key === 'isEmpty') {
          this.dataChange[key] = this.dataChange[key] == 1 || this.dataChange[key] == true ? true : false;
        }
      }
      this.setGridViewInfo();
    } else {
      const items = this.listsData.filter(d => d.field_name === this.dataChange.field_name);
      if (items.length > 1) {
        const index = this.listsData.findIndex(d => d.id === this.dataChange.id);
        this.listsData.splice(index, 1);
        this.listsData = [...this.listsData];
        this.dataChange = null;
        this.isChange = false;
        this.messageService.add({severity: 'error', summary: 'Thông báo', detail: 'Đã tồn tại tên trường !'});
      } else {
        for (const key in this.dataChange) {
          if (key === 'isSpecial' || key === 'isRequire' || key === 'isDisable' || key === 'isVisiable' || key === 'hideInApp' || key === 'isChange' || key === 'isEmpty') {
            this.dataChange[key] = this.dataChange[key] == 1 || this.dataChange[key] == true ? true : false;
          }
        }
        this.setGridViewInfo();
      }
    }
  }

  callApi() {
    if (this.listsDataCloone.map((d: any) => d.columnField).indexOf(this.dataChange.columnField) < 0) {
      for (const key in this.dataChange) {
        if (key === 'isUsed' || key === 'isHide' || key === 'isFilter' || key === 'isMasterDetail' || key === 'isStatusLable') {
          this.dataChange[key] = this.dataChange[key] == 1 || this.dataChange[key] == true ? true : false;
        }
      }
      this.setGridViewInfo();
    } else {
      const items = this.listsData.filter(d => d.columnField === this.dataChange.columnField);
      if (items.length > 1) {
        const index = this.listsData.findIndex(d => d.id === this.dataChange.id);
        this.listsData.splice(index, 1);
        this.listsData = [...this.listsData];
        this.dataChange = null;
        this.isChange = false;
        this.messageService.add({severity: 'error', summary: 'Thông báo', detail: 'Đã tồn tại tên trường !'});
      } else {
        for (const key in this.dataChange) {
          if (key === 'isUsed' || key === 'isHide' || key === 'isFilter' || key === 'isMasterDetail' || key === 'isStatusLable') {
            this.dataChange[key] = this.dataChange[key] == 1 || this.dataChange[key] == true ? true : false;
          }
        }
        this.setGridViewInfo();
      }
    }
  }

  setGridViewInfo() {
    this._spinner.show();
    // this.apiService.setGridViewInfo(this.typeConfig === 'FormInfo' ? 'SetFormViewInfo' : 'SetGridViewInfo', this.dataChange).subscribe(results => {
    //   if (results.status === 'success') {
    //     this.listsDataCloone = [];
    //     this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results.data ? results.data : 'Cập nhật thành công' });
    //     this.isChange = false;
    //     this.listsData = [...this.listsData];
    //     this.listsDataCloone = cloneDeep(this.listsData);
    //     this.dataChange = null;
    //     // this.doubleClicked.emit()
    //     this._spinner.hide();
    //   } else {
    //     setTimeout(() => {
    //       let index = this.listsData.findIndex(d => d.id === this.dataChange.id)
    //       this.gridApi.setFocusedCell(index, 'columnCaption');
    //       this.gridApi.startEditingCell({
    //         rowIndex: index,
    //         colKey: 'columnCaption',
    //       });
    //       this.dataChange = null;
    //     }, 500);
    //     this._spinner.hide();
    //     this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results ? results.message : null });
    //   }
    // })
  }

  onCellValueChanged(event: any) {
    if (event.value != event.oldValue) {
      event.data.isChange = true;
      if (this.typeConfig === 'FormInfo') {
        for (const key in event.data) {
          if (key === 'isSpecial' || key === 'isRequire' || key === 'isDisable' || key === 'isVisiable' || key === 'hideInApp' || key === 'isChange' || key === 'isEmpty') {
            event.data[key] = event.data[key] == 1 || event.data[key] == true ? true : false;
          }
        }
      } else {
        for (const key in event.data) {
          if (key === 'isUsed' || key === 'isHide' || key === 'isFilter' || key === 'isMasterDetail' || key === 'isStatusLable') {
            event.data[key] = event.data[key] == 1 || event.data[key] == true ? true : false;
          }
        }
      }

      console.log(event);
      this.isChange = true;
      setTimeout(() => {
        this.gridApi.applyTransaction({update: [event.data]});
      }, 500);
    } else {
      this.isChange = false;
    }
  }

  getGridApi() {
    return this.gridApi;
  }

}
