
<div class="bread-crumb">
    <div class="grid ">
        <div class="col-3 set-height-left bg-white " style="padding: 0px 10px">
                <div class="flex align-items-center justify-content-between gap-2">
                    <button (click)="getRoom()">Phòng chat</button>
                    <!-- <button>Danh bạ</button>
                    <i class="uni-icon uni-user cursor-pointer" title="Thêm danh bạ" style="font-size: 1.5rem"></i> -->
                    <i class="uni-icon uni-users cursor-pointer" title="Chat nhóm" style="font-size: 1.5rem"></i>
                </div>
                <div class="p-field mt-2">
                    <input type="text" #searchBox placeholder="Tìm kiêm" (keyup)="getDataMessagesSearch(searchBox.value)">
                </div>
                <div class="users-list-padding media-list" *ngIf="rooms.length > 0 && !bListSearchConverstion">
                  <a *ngFor="let item of rooms" (click)="roomClick(item?.id,item?.name, item)">
                      <div class="media flex justify-content-start gap-2 p-2 cursor-pointer border-round-sm mb-2" [ngClass]="item.active ==true ? 'surface-200 border-right-primary' : ''">
                        <div class="media-left pr-1" >
                          <span class="avatar avatar-online">
                            <div *ngIf="item?.type === 'private'">
                              <div *ngIf="item?.avatar !== null">
                                <img class="media-object rounded-circle" src="{{item?.avatar}}" alt="ảnh">
                              </div>
                              <div *ngIf="item?.avatar == null">
                                <img class=""
                                src="../../../../../assets/images/portrait/add-user.png" alt="ảnh"/>
                              </div>
                              <i></i>
                            </div>
                            <div *ngIf="item?.type !== 'private'">
                              <div *ngIf="item?.type !== 'service'">
                                <div *ngIf="item?.avatar !== null">
                                  <img class="media-object rounded-circle" src="{{item?.avatar}}" alt="ảnh">
                                </div>
                                <div *ngIf="item?.avatar === null">
                                  <img class="media-object rounded-circle"
                                  src="../../../assets/images/portrait/icon-task.png" alt="ảnh">
                                </div>
                                <i></i>
                              </div>
                              <div *ngIf="item?.type === 'service'">
                                  <img class="media-object rounded-circle"
                                  src="../../../assets/images/portrait/icon-task.png" alt="ảnh">
                              </div>
                            </div>
                          </span>
                        </div>
                        <div class="media-body w-9">
                          <span *ngIf="item?.unread === 0">
                            <h6 class="list-group-item-heading">[{{item?.name}}]
                            </h6>
                            <span class="text-xs float-right text-green-500">{{ item?.created }}</span>
                          </span>
                          <span *ngIf="item?.unread === 1">
                            <h6 class="list-group-item-heading" style="font-weight: bold;">[{{item?.name}}]
                            </h6>
                            <span class="text-xs float-right primary">{{ item?.created }}</span>
                          </span>
                          <p class="list-group-item-text text-muted mb-0">
                            <span >
                              <span style="width:50%" *ngIf="item?.last_message.type == 'image'">
                                <i class="fa fa-file-image-o"></i>
                              </span>
                              <span style="width:50%" *ngIf="item?.last_message?.type == 'text'">
                                {{item?.last_message?.payload}}
                              </span>
                              <span style="width:50%" *ngIf="item?.last_message?.type == 'file'">
                                <i class="fa fa-file"></i> {{item?.last_message?.payload}}
                              </span>
                              <span style="width:50%" *ngIf="item?.last_message?.type == 'file_image'">
                                <i class="fa fa-file-image-o"></i>
                              </span>
                              <span style="width:50%" *ngIf="item?.last_message?.type == 'sticker'">
                                <img style="width:10%" src="{{item?.last_message?.payload}}">
                              </span>
                              <span style="width:50%" *ngIf="item?.last_message?.type == 'location'">
                                <i class="fa fa-location-arrow"></i>
                              </span>
                              <span *ngIf="item?.last_message?.type != 'image'">
                                <span *ngIf="item?.last_message?.type != 'text'">
                                  <span *ngIf="item?.last_message?.type != 'ticket'">
                                    <span *ngIf="item?.last_message?.type != 'file'">
                                      <span *ngIf="item?.last_message?.type != 'sticker'">
                                        <span *ngIf="item?.last_message?.type != 'file_image'">
                                          <span *ngIf="item?.last_message?.type != 'location'">
                                            <p>{{item?.last_message?.type}}</p>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                  </a>
            </div>
            <div class="users-list-padding media-list" *ngIf="bListSearchConverstion == true">
              <a *ngFor="let item of conversations_search" (click)="gotoConversation(item)">
                <div class="media flex align-items-start gap-2">
                  <div class="media-left">
                    <span class="avatar-user">
                      <div *ngIf="item.type === 'private'">
                        <div *ngIf="item.avatar !== null">
                          <img class="avatar" src="{{item.avatar}}" alt="ảnh">
                        </div>
                        <div *ngIf="item.avatar === null">
                          <img class="avatar"
                            src="../../../../../assets/images/portrait/avatar.png" alt="ảnh">
                        </div>
                        <i></i>
                      </div>
                      <div *ngIf="item.type !== 'private'">
                        <div *ngIf="item.type !== 'service'">
                          <div *ngIf="item.avatar !== null">
                            <img class="avatar" src="{{item.avatar !== '' ? item.avatar : '../../../../../assets/images/portrait/avatar.png'}}" alt="ảnh">
                          </div>
                          <div *ngIf="item.avatar === null">
                            <img class="avatar"
                              src="../../../../../assets/images/portrait/avatar.png" alt="ảnh">
                          </div>
                          <i></i>
                        </div>
                        <div *ngIf="item.type === 'service'">ddddddd
                          <img class="avatar" src="../../../assets/images/portrait/small/icon-task.png"
                            alt="ảnh">
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="media-body">
                    <h6 class="list-group-item-heading" style="font-weight: bold;">[{{item?.name}}]
                      <span class="font-small-3 float-right primary">{{ item?.created }}</span>
                    </h6>
                    <p class="list-group-item-text text-muted mb-0">
                      <span style="width:50%" *ngIf="item.type == 'text'">
                        {{item.payload}}
                      </span>
                    </p>
                  </div>
                </div>
              </a>
          </div>
        </div>
        <div class="col-9 bg-white relative" style="border-left: 2px solid #ccc">
            <div *ngIf="roomName">
                <div class="content-header grid align-items-center">
                    <div class="col-10">
                        <h3 style="font-weight: bold;">{{roomName}}</h3>
                          Tổng số người: {{totaluserchat}}
                    </div>
                    <div class="col-2 flex justify-content-end">
                        <i class="pi pi-user-plus cursor-pointer" style="font-size: 2rem" (click)="showListUser()"></i>
                    </div>
                </div>
                <hr>
            </div>  
            <section class="chat-app-window" id="chat-app-window" style="visibility: visible;">
                <div class="chats set-height">
                    <div #item class="chats" *ngFor="let advert of conversations; let i = index">
                      <!-- Customer User -->
                      <div class="chat chat-left mt-1" id={{advert?.id}} *ngIf="advert.owner != iduser">
                        <div class="chat-avatar d-flex">
                          <a class="avatar" style="width: auto;" >
                            <img *ngIf="!advert.avatar" src="../../../../../assets/images/portrait/add-user.png"
                              alt="avatar" style="width: 30px;height: 30px;object-fit: cover;"/>
                            <img *ngIf="advert.avatar" src="{{advert.avatar}}" lt="avatar" style="width: 30px;height: 30px;object-fit: cover;"/>
                          </a><p class="author-chat  ml-1">{{advert?.name}} - {{advert?.timestring}}</p>
                        </div>
                        <div class="chat-body">
                          <div class="chat-content" *ngIf="advert.type == 'text'">
                            <p>{{advert.payload}}</p>
                          </div>
                          <div class="chat-content"  *ngIf="advert.type == 'image'">
                            <img style="width:65%" src="{{advert.image}}">
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'ticket'">
                            <p>{{advert.title}}</p> <br>
                            <p>{{advert.summary}}</p>
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'file'">
                            <a href="{{advert.image}}" target="_blank">{{advert.payload}}</a>
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'file_image'">
                            <img style="width:100%" src="{{advert.image}}">
                          </div>
                          <div class="chat-content"  *ngIf="advert.type == 'sticker'">
                            <img style="width:7%" src="{{advert.image}}">
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'location'">
                            <img style="width:100%"
                              src="https://maps.googleapis.com/maps/api/staticmap?center={{advert.lat}},{{advert.lng}}&markers=color:red%7Clabel:V%7C{{advert.lat}},{{advert.lng}}&zoom=16&size=600x300&key=AIzaSyDLjfTDQULS3G94_s1cPpHQcBX5shNOngY">
                            <p>{{advert.payload}}</p>
                          </div>
                          <div *ngIf="advert.type != 'image'">
                            <div *ngIf="advert.type != 'text'">
                              <div *ngIf="advert.type != 'ticket'">
                                <div *ngIf="advert.type != 'file'">
                                  <div *ngIf="advert.type != 'sticker'">
                                    <div *ngIf="advert.type != 'file_image'">
                                      <div *ngIf="advert.type != 'location'">
                                        <p>{{advert.type}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- admin chat -->
                       <div class="chat admin-chat" id={{advert.id}} *ngIf="advert.owner == iduser">
                        <div class="chat-avatar d-flex justify-content-end">
                          <p class="author-chat">{{advert.name ? advert.name : 'No Name'}} - {{advert.timestring}}</p>
                          <a class="avatar ml-1" style="width: auto;"  data-toggle="tooltip" data-placement="left" title="" data-original-title="">
                            <img *ngIf="!advert.avatar" style="width: 30px;height: 30px;object-fit: cover;" src="../../../../../assets/images/portrait/add-user.png"
                              alt="avatar" />
                            <img *ngIf="advert.avatar" src="{{advert.avatar}}" alt="avatar" style="width: 30px;height: 30px;object-fit: cover;" />
                          </a>
                        </div>
                        <div class="chat-body">
                          <div class="chat-content" *ngIf="advert.type == 'text'">
                            <p>{{advert.payload}}</p>
                          </div>
                          <div class="chat-content"  *ngIf="advert.type == 'image'">
                            <img style="width:65%" src="{{advert.image}}">
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'ticket'">
                            <p>{{advert.title}}</p> <br>
                            <p>{{advert.summary}}</p>
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'file'">
                            <a href="{{advert.image}}" target="_blank">{{advert.payload}}</a>
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'file_image'">
                            <img style="width:100%" src="{{advert.image}}">
                          </div>
                          <div class="chat-content"  *ngIf="advert.type == 'sticker'">
                            <img style="width:7%" src="{{advert.image}}">
                          </div>
                          <div class="chat-content" style="width:50%" *ngIf="advert.type == 'location'">
                            <img style="width:100%"
                              src="https://maps.googleapis.com/maps/api/staticmap?center={{advert.lat}},{{advert.lng}}&markers=color:red%7Clabel:V%7C{{advert.lat}},{{advert.lng}}&zoom=16&size=600x300&key=AIzaSyDLjfTDQULS3G94_s1cPpHQcBX5shNOngY">
                            <p>{{advert.payload}}</p>
                          </div>
                          <div *ngIf="advert.type != 'image'">
                            <div *ngIf="advert.type != 'text'">
                              <div *ngIf="advert.type != 'ticket'">
                                <div *ngIf="advert.type != 'file'">
                                  <div *ngIf="advert.type != 'sticker'">
                                    <div *ngIf="advert.type != 'file_image'">
                                      <div *ngIf="advert.type != 'location'">
                                        <p>{{advert.type}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                    </div>
                    <div class="chats mb-1">
                      <div class="chat chat-left typing" *ngIf="flagtyping == true" style="position: absolute;
                      z-index: 9999;
                      left: 20px;
                      bottom: 55px;">
                        <img src="../../../../../assets/images/portrait/loading-chat.gif" alt="typing" style="width: 94px; height: 18px;" />
                      </div>
                    </div>
                  </div>
            </section>
            <form *ngIf="isShowEmoji === true" class="chat-app-input flex">
              <fieldset class="form-group position-relative has-icon-left col-10 m-0" style="
                            padding: 5px 10px;
                            overflow-y: scroll;
                            height: calc(100%);
                            background-color: #fff;">
                <span *ngFor="let advert of emojis; let i = index">
                  <a (click)="sendMessageEmoji(emojis[i].url)" class="avatar cursor-pointer" 
                    data-placement="left" title="{{emojis[i].name}}">
                    <img src="{{emojis[i].url}}" alt="avatar" />
                  </a>
                </span>
              </fieldset>
              <fieldset class="form-group position-relative has-icon-left col-2 m-0">
              </fieldset>
            </form>
            <form *ngIf="isShowSendFile === true">
                <fieldset style="padding: 10px 20px;
                              overflow-y: scroll;
                              height: calc(100%);
                              background-color: #fff;">
                  <span *ngFor="let advert of arrFile; let i = index">
                    <a *ngIf="arrFile[i].type === 'image'" data-toggle="tooltip" data-placement="left"
                      title="{{arrFile[i].name}}" data-original-title="">
                      <span class="image-area">
                        <img data-target="#myModal" data-toggle="modal" src="{{arrFile[i].url}}" alt="image"
                          height="100px">
                        <i class="pi pi-times cursor-pointer" (click)="deleteAvatar(arrFile[i].url)"></i>
                      
                      </span>
                    </a>
                    <a *ngIf="arrFile[i].type === 'file'"
                      title="{{arrFile[i].name}}">
                      <span class="image-area">
                        <img data-target="#myModal" data-toggle="modal" src="/assets/images/no-image.png" alt="image"
                          height="100px">
                          <i class="pi pi-times cursor-pointer" (click)="deleteAvatar(arrFile[i].url)"></i>
                      </span>
                    </a>
                  </span>
                </fieldset>
                <fieldset class="form-group position-relative has-icon-left col-2 m-0">
                </fieldset>
              </form>
            <section class="chat-app-form" style="bottom: -10px; left: 10px">
                <div class="flex align-items-center gap-2">
                    <div class="p-field flex-1" style="margin-bottom: 0px;">
                        <input type="text" pInputText placeholder="Nhập nội dung chat" (input)="onChangeInput($event)" [(ngModel)]="message" (keydown.enter)="sendMessage()" /> 
                    </div>
                    <input id="file-upload-vitri" type="file" accept="*/*" (change)="onUploadOutput($event)" hidden />
                    <label for="file-upload-vitri" class="custom-file-upload">
                      <i class="pi pi-cloud-upload cursor-pointer" style="font-size: 1.2rem"></i>
                    </label>
                    <i class="pi pi-palette cursor-pointer" style="font-size: 1.2rem" (click)="isShowEmoji = !isShowEmoji"></i>
                    <p-button label="Gửi" icon="uni-icon uni-send uni-white-color" (click)="sendMessage()"></p-button>
                </div>
            </section>
        </div>
    </div>
</div>

<p-dialog [header]="roomName" [(visible)]="showListUserInRoom" [style]="{width: '20vw'}" [modal]="true">
    <h3>Danh sách thành viên</h3>
    <hr>
    <tbody class="users"> 
        <tr *ngFor="let item of usersChats; let i = index" class="users-item flex align-items-center justify-content-between">
            <div class="users-info flex gap-2 align-items-center">
                <td>
                    <div *ngIf="item.avatar !== null">
                        <img class="media-object rounded-circle"
                            style="width: 30px;height: 30px;object-fit: cover;"
                            src="{{item.avatar}}" alt="ảnh">
                    </div>
                    <div *ngIf="item.avatar == null">
                        <img class="media-object rounded-circle"
                            style="width: 30px;height: 30px;object-fit: cover;"
                            src="../../../assets/images/portrait/icon-man.png" alt="ảnh">
    
                    </div>
                </td>
                <td>
                    <div class="list-group-item-heading">{{item.name}}
                    </div>
                    <div style="font-style: italic; ">
                        {{item.nameroles}}
                    </div>
                </td>
            </div>
            <td class="col-center">
                <!-- <div class="btn-group" *ngIf="item.id !== iduser">
                    <button title="Xóa" type="button" class="btn btn-danger"
                        (click)="onDeleteUserInRoom(item.id)">
                        <i class="fa fa-remove"></i>
                    </button>
                </div> -->
            </td>
        </tr>
    </tbody>
</p-dialog>