import { Injectable } from '@angular/core';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';

@Injectable()
export class FirebaseAuthService {

    authState: any = null;

    constructor(
        private auth: Auth,
    ) {
      
    }

    get authenticated(): boolean {
        return this.authState !== null;
    }

    // Returns current user UID
    get currentUserId(): string {
        return this.authenticated ? this.authState.uid : '';
    }

    customLogin(token: string) {
        return signInWithCustomToken(this.auth, token)
        .then((user) => {
            this.authState = user;
            this.updateUserData();
        }).catch(error => console.log(error));
    }

    private updateUserData(): void {
        // Writes user name and email to realtime db
        // useful if your app displays information about users or for admin features

        const path = `users/${this.currentUserId}`; // Endpoint on firebase
        const data = {
            email: this.authState.email,
            name: this.authState.displayName
        };
    }

}
