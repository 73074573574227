import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { getDownloadURL, ref, uploadBytesResumable } from '@angular/fire/storage';
import { MessageService } from 'primeng/api';
import queryString from 'query-string';
import { Subject, takeUntil } from 'rxjs';
import { Storage } from '@angular/fire/storage';
import { NotifyApiService } from 'src/app/services/notify/notify.service';
import { result } from 'lodash';
import LOCAL_STORAGE from '../../constants/constant';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-nz-markdown',
  templateUrl: './nz-markdown.component.html',
  styleUrls: ['./nz-markdown.component.scss']
})

export class NzMarkdownComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy, AfterViewChecked{
  private readonly unsubscribe$: Subject<void> = new Subject();
  _editor: any;
  showDialogImage: boolean = false;
  showMedia: boolean = false;
  public mode: string = 'editor';
  file: any = {};
  listImages: any[] = [];
  first: number = 0;
  content: string = ''
  query = {
    ProjectCd: '',
    filter: '',
    offSet: 0,
    pageSize: 12
  }
  countRecord: any = {
    totalRecord: 0,
  };
  constructor(
    private apiNotifyService: NotifyApiService,
    private changeDetector: ChangeDetectorRef,
    private messageService: MessageService,
    private storage: Storage
  ) {
    super();
  }
  ngOnInit(): void {

  }

  onPreviewDomChanged(dom: HTMLElement) {
  }

  onMarkdownChange(event: any) {
    this.field.defaultValue = event;
    const data = this.field.defaultValue
    this.field.formControl.setValue(data);
  }

  handleAttackFile() {
    this.showMedia = true;
  }

  onEditorLoaded(editor: any) {
    this._editor = editor;
  }

  chooseImageContent(): void {
    this.showDialogImage = true;
    this.loadImage();
  }

  loadImage(): void {
    if (localStorage.hasOwnProperty(LOCAL_STORAGE.SETTINGS) && localStorage.getItem(LOCAL_STORAGE.SETTINGS) != null) {
      const projectCd = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SETTINGS) || '{}') 
      this.query.ProjectCd = projectCd;
    }
    const queryParams = queryString.stringify({...this.query})
    this.apiNotifyService.getDocumentUrl(queryParams)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((results:  any)=> {
      this.listImages = results.data;
      this.countRecord.totalRecord = results.recordsTotal;
      this.countRecord.currentRecordStart = results.recordsTotal === 0 ? this.query.offSet = 0 : this.query.offSet + 1;
    })
  }

  chooseImage(documentUrl: string) {
    if(this.field.defaultValue) {
      this.field.defaultValue += '\r\n![](' + documentUrl + ')';
    } else {
      this.field.defaultValue = '\r\n![](' + documentUrl + ')';
    }
    const data = this.field.defaultValue
    this.field.formControl.setValue(data);
    this.showDialogImage = false;
  }

  onUploadOutput(event: any, field: any) {
    this.file = event.target.files[0];
    const getDAte = new Date();
    const getTime = getDAte.getTime();
    const storageRef = ref(this.storage, `housing/avatars/${getTime}-${this.file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, this.file);
    uploadTask.on('state_changed',
    (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
    },
    (error)=> {
      console.log(">>>error", error.message)
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL)=> {   
        console.log('downloadURL', downloadURL);
        if(downloadURL) {
          const body = {
            projectCd: this.query.ProjectCd,
            documentTitle: '',
            documentUrl: downloadURL
          }
          this.apiNotifyService.setDocumentUrl(body)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((response: any) => {
            if(response.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Upload thành công' });
              this.loadImage();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Upload thất bại' });
            }
          })
        }
      })
    }
    )
  }

  paginate(event: any) {
    this.query.offSet = event.first;
    this.first = event.first;
    this.query.pageSize = event.rows;
    this.loadImage()
  }

  reset() {
    this.query.offSet = 0;
    this.query.pageSize = 12;
    this.countRecord.totalRecord = 0;
    this.first = 0;
    this.loadImage();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
