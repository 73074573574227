import {Component, OnInit} from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'app-nz-upload',
  templateUrl: './nz-upload.component.html',
  styleUrls: ['./nz-upload.component.scss']
})
export class NzUploadComponent extends FieldType<FieldTypeConfig> implements OnInit {
  uploadedFiles: any[] = [];
  cont = 0;
  ngOnInit(): void {

  }
}
