import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormDetailComponent } from './form-detail.component';
import { NzFormLyModule } from '../formLy/nzFormLy.module';
import { FormlyModule } from '@ngx-formly/core';
import { AppApiService } from 'src/app/services/app.service';
import { ConfigGridTableFormModule } from '../config-grid-table-form/config-grid-table-form.module';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NzFormLyModule,
    FormlyModule.forRoot(),
    ConfigGridTableFormModule,
    DialogModule,
    TranslateModule,
    ButtonModule
  ],

  declarations: [
    FormDetailComponent
  ],
  exports: [
    FormDetailComponent
  ],
  providers: [
    AppApiService
  ]

})
export class FormDetailModule { }