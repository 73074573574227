<p-toolbar>
  <div class="p-toolbar-group-left">
    <!-- <img class="logo" [routerLink]="['/home']" src="./assets/images/logo.png"> -->
    <!-- <span style="font-style: oblique 40deg;">Sunshine SHRM</span> -->
    <!-- routerLink="/home"  -->
   <a (click)="goToHome()" class="go-to-home">
     <img src="../../../assets/images/logo-service.svg">
    </a>
  </div>
  <div class="flex gap12 col-6 middle p-0 justify-content-end">
    <div class="p-toolbar-group-right profile">
        <div class="card flex align-items-center">
          <div class="project-dropdown">
            <svg class="project-dropdown--icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1667 7.16667V0.5H3.83333V3.83333H0.5V15.5H7.16667V12.1667H8.83333V15.5H15.5V7.16667H12.1667ZM3.83333 13.8333H2.16667V12.1667H3.83333V13.8333ZM3.83333 10.5H2.16667V8.83333H3.83333V10.5ZM3.83333 7.16667H2.16667V5.5H3.83333V7.16667ZM7.16667 10.5H5.5V8.83333H7.16667V10.5ZM7.16667 7.16667H5.5V5.5H7.16667V7.16667ZM7.16667 3.83333H5.5V2.16667H7.16667V3.83333ZM10.5 10.5H8.83333V8.83333H10.5V10.5ZM10.5 7.16667H8.83333V5.5H10.5V7.16667ZM10.5 3.83333H8.83333V2.16667H10.5V3.83333ZM13.8333 13.8333H12.1667V12.1667H13.8333V13.8333ZM13.8333 10.5H12.1667V8.83333H13.8333V10.5Z" fill="#76809B"/>
            </svg>
            <p-dropdown [appendTo]="'body'" [options]="projectList" optionLabel="name" optionValue="value" (onChange)="changeProject($event)" [(ngModel)]="selectProject" [filter]="true" placeholder="Chọn dự án"></p-dropdown>
        </div>
          <span class="noti item">
            <i class="pi pi-comments" pBadge severity="danger" [value]="itemUnreads.length.toString()" style="font-size: 1.2rem" [routerLink]="'/chat'"></i>
          </span>
          <span class="item flag d-flex middle gap6" (click)="op.toggle($event)">
            <i class="uni-icon uni-language"></i>
          </span>
          <span class="noti item">
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 19.75C9.1 19.75 10 18.85 10 17.75H6C6 18.85 6.89 19.75 8 19.75ZM14 13.75V8.75C14 5.68 12.36 3.11 9.5 2.43V0.25H6.5V2.43C3.63 3.11 2 5.67 2 8.75V13.75L0 15.75V16.75H16V15.75L14 13.75Z" fill="#212633"/>
            </svg>
          </span>
          <span class="item">
            <span (click)="optionTopbar.toggle($event)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 16H16V12H12M12 10H16V6H12M6 4H10V0H6M12 4H16V0H12M6 10H10V6H6M0 10H4V6H0M0 16H4V12H0M6 16H10V12H6M0 4H4V0H0V4Z" fill="#212633"/>
              </svg>
            </span>
          </span>
          <p-avatar  image="../../../assets/images/walter.png" size="large"></p-avatar>
          <span  class="ml-12 mr-15 name" (click)="menu.toggle($event)"> {{userName}} &nbsp;
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z" fill="#212633" />
            </svg>
          </span>
          <p-menu #menu [popup]="true" [model]="items" styleClass="my-account-menu"></p-menu>
      </div>
    </div>
  </div>

</p-toolbar>
<p-overlayPanel #optionTopbar styleClass="option-topbar">
  <div class="grid" *ngIf="cats && cats.length > 0">
    <div class="item" *ngFor="let cat of cats">
      <a href="{{ cat.webUrl }}">
        <div class="in">
            <p-button [label]="cat.webName" styleClass="p-button-sm btn-white-image" >
             <span class="icon">
                    <i class="{{cat.iconClass ? cat.iconClass : 'uni uni-overview'}}"></i>
                </span>
            </p-button>
        </div>
      </a>
    </div>
  </div>
</p-overlayPanel>

<p-overlayPanel #op styleClass="choose-language" [style]="{'z-index': 99999}">
  <ng-template pTemplate="content">
      <h3>{{"_select_language_" | translate}}</h3>
        <ul class="list-lang">
          <li class="active" [ngClass]="{'active': translate.currentLang === 'vi'}" (click)="translate.use('vi');op.toggle($event);saveLang('vi')">
            <div class="d-flex middle gap-10">
              <span class="uni-icon uni-flag-vn"></span>
              <span>{{"vietnamese" | translate}}</span>
              <span class="checked" *ngIf="translate.currentLang === 'vi'">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
            </div>
          </li>
          <li (click)="translate.use('en');op.toggle($event);saveLang('en')" [ngClass]="{'active': translate.currentLang === 'en'}">
            <div class="d-flex middle gap-10">
              <span class="uni-icon uni-flag-en"></span>
              <span>{{"english" | translate}}</span>
              <span class="checked" *ngIf="translate.currentLang === 'en'">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
            </div>
          </li>
        </ul>
  </ng-template>
</p-overlayPanel>

<p-sidebar (onHide)="displayChangePassword = false; resetPassword()"
           [style]="{ width: '30%' }"
           [(visible)]="displayChangePassword"
           [autoZIndex]="true"
           [appendTo]="'body'"
           position="right">
  <ng-template pTemplate="header">
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z" fill="#2B2F33"/>
      <path d="M8 0L6.59 1.41L11.17 6L6.59 10.59L8 12L14 6L8 0Z" fill="#2B2F33"/>
    </svg> &nbsp;&nbsp;
    <div class="header-sidebar">
      {{'_Cập nhật mật khẩu tài khoản_' | translate}} [{{userName}}]
    </div>
  </ng-template>
  <ng-template pTemplate="footer"></ng-template>
  <form #editSMSF="ngForm" class="form-horizontal change-password">
    <div class="box-body">
      <div class="form-row">
        <label class="col-4 control-label">{{"_Mật khẩu cũ_" | translate}}
          <span style="color:red">*</span>
        </label>
        <div class="col-12">
          <div class="fields">
            <input type="{{isShowOld ? 'text' : 'password'}}" class="form-control" name="oldPassword" autocomplete="off" [(ngModel)]="modelPass.oldPassword" required
              value="" #oldPassword="ngModel" placeholder="Mật khẩu cũ">
              <span class="pi pi-eye-pass {{ isShowOld ? 'pi-eye-slash' : 'pi-eye' }} cursor-pointer" 
              (click)="isShowOld = !isShowOld" style="
              position: absolute;
              right: 10px;
              top: 12px;
          "></span><div *ngIf="submitPass && !modelPass.oldPassword " class="alert-validation alert-danger">
              <div style="color:red; margin-top:5px" *ngIf="!modelPass.oldPassword">
                {{"_Mật khẩu bắt buộc nhập!_" | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <label class="col-4 control-label">{{"_Mật khẩu_" | translate}}
          <span style="color:red">*</span>
        </label>
        <div class="col-12">
          <div class="fields">
            <input type="{{isShowPass ? 'text' : 'password'}}" class="form-control" name="userPassword" autocomplete="off" (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassword" required
              value="" #userPassword="ngModel" placeholder="Mật khẩu">
              <span class="pi pi-eye-pass {{ isShowPass ? 'pi-eye-slash' : 'pi-eye' }} cursor-pointer" 
              (click)="isShowPass = !isShowPass" style="
              position: absolute;
              right: 10px;
              top: 12px;
          "></span><div *ngIf="submitPass && !modelPass.userPassword " class="alert-validation alert-danger">
              <div style="color:red; margin-top:5px" *ngIf="!modelPass.userPassword">
                {{"_Mật khẩu bắt buộc nhập!_" | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <label class="col-4 control-label">{{"_Nhập lại mật khẩu_" | translate}}
          <span style="color:red">*</span>
        </label>
        <div class="col-12">
          <div class="fields">
            <input type="{{isShowRepass ? 'text' : 'password'}}" class="form-control" name="userPassCf" autocomplete="off"  (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassCf" required
              #userPassCf="ngModel" id="userPassCf" placeholder="Nhập lại mật khẩu">
              <span class="pi pi-eye-pass {{ isShowRepass ? 'pi-eye-slash' : 'pi-eye' }} cursor-pointer" 
                (click)="isShowRepass = !isShowRepass" style="
                position: absolute;
                right: 10px;
                top: 12px;
            "></span>
              <div *ngIf="submitPass && (confimPassword || !modelPass.userPassCf) " class="alert-validation alert-danger">
                <div style="color:red; margin-top:5px" *ngIf="!modelPass.userPassCf || confimPassword">
                  {{"_Nhập lại mật khẩu không đúng!_" | translate}}
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="form-row" style="margin:10px 0px;">
        <div class="col-md-12 text-right">
          <p-button label="{{'_Lưu lại_' | translate}}" styleClass="mr-1" (click)="saveChangePass()"></p-button>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>