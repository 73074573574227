import { Component, OnInit } from '@angular/core';
import {FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-nz-link-url',
  templateUrl: './nz-link-url.component.html',
  styleUrls: ['./nz-link-url.component.scss']
})
export class NzLinkUrlComponent extends FieldType<FieldTypeConfig> {



}