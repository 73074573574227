import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-nz-input-search',
  templateUrl: './nz-input-search.component.html',
  styleUrls: ['./nz-input-search.component.scss']
})
export class NzInputSearchComponent extends FieldType<FieldTypeConfig> implements OnInit{
  value: string | number = ''
  get type() {
    return this.props.type || 'text';
  }
  constructor(
    private shareService: ShareService
  ) {
    super();
  }
  ngOnInit(): void {
  }

  changeValueSearch(event: any) {
    this.value = event.target.value;
  }

  onSearch() {
    this.shareService.emitdata({type: 'search', value: this.value})
  }
}
