import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ListGridAngularComponent } from './list-grid-angular.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { AgGridModule } from '@ag-grid-community/angular';
import { TotalValueFooterComponent } from '../total-value-component/total-value-component';
import { ButtonAgGridComponent } from '../ag-buttons';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarFullComponent } from '../avatarFull.component';
import { AvatarModule } from 'primeng/avatar';
@NgModule({
    declarations: [ListGridAngularComponent, TotalValueFooterComponent, ButtonAgGridComponent, AvatarFullComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        AvatarModule,
        AgGridModule.withComponents([
            ButtonAgGridComponent,
            AvatarFullComponent
        ]),
        ButtonModule,
        DialogModule,
        SplitButtonModule,
        TranslateModule

    ],
    entryComponents: [],
    exports: [
        ListGridAngularComponent,
        ButtonAgGridComponent
    ],
    providers: []
})
export class ListGridAngularModule {}