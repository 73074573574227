import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {catchError, map, Observable, of} from 'rxjs';
import {MessageService} from 'primeng/api';
import {AuthService} from './auth/auth.service';
import {ErrorService} from './auth/error.service';

const apiBaseUrl = environment.apiBase;

@Injectable()
export class ApiDownloadService {
  private _http = inject(HttpClient);
  private _messageService = inject(MessageService);
  private _errorService = inject(ErrorService);

  constructor() {
  }

  options = {
    headers: new HttpHeaders({
      // Authorization: this._authService.getAuthorizationHeaderValue(),
    })
  };
  optionsUpload = {
    headers: new HttpHeaders({
      // Authorization: this._authService.getAuthorizationHeaderValue(),
    })
  };
  optionsUploadExport = {
    headers: new HttpHeaders({
      // Authorization: this._authService.getAuthorizationHeaderValue(),
    }),
    responseType: 'blob'
  };

  exportReportLocalhost(url: string): Observable<Blob> {
    return this._http.get(url, {
      responseType: 'blob'
    });
}
  
}
