import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {catchError, map, Observable, of} from 'rxjs';
import {MessageService} from 'primeng/api';
import {AuthService} from './auth/auth.service';
import {ErrorService} from './auth/error.service';
const apiShomeBaseUrl = environment.apishomeBase;
const apiCoreBaseUrl = environment.apiCoreBase;
const apiServiceConfig = environment.apiServiceConfig;
@Injectable()
export class AppApiService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _messageService = inject(MessageService);
  private _errorService = inject(ErrorService);

  constructor() {
  }

  options = {
    // headers: new HttpHeaders({
    //     Authorization: this._authService.getAuthorizationHeaderValue(),
    //     'Content-Type': 'application/json',
    // })
  };

  // clientMenuGetListByUserId(queryParams: string): Observable<any> {
  //   return this._http.get(`${apiCoreBaseUrl}/api/v1/corewebmanager/ClientMenuGetListByUserId?` + queryParams, this.options);
  // }

  GetFilterForm(filterName: string): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/sysmanage/GetFilterForm/${filterName}`, this.options).pipe(this.ErrorAsync());
  }

  getGroupInfo(queryParams: string): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/config/GetGroupInfo?`+ queryParams, this.options).pipe(this.ErrorAsync());
  }

  setGroupInfo(queryParams: string): Observable<any> {
    return this._http.post(`${apiShomeBaseUrl}/api/v2/config/SetGroupInfo`, queryParams, this.options).pipe(this.ErrorAsync());
  }

  getGridViewPage(url: string, queryParams: string): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/config/${url}?` + queryParams, this.options).pipe(this.ErrorAsync());
  }

  setGridViewInfo(url: string, queryParams: string): Observable<any> {
    return this._http.post(`${apiShomeBaseUrl}/api/v2/config/${url}`, queryParams, this.options).pipe(this.ErrorAsync());
  }

  delFormViewInfo(url: string, queryParams: string): Observable<any> {
    return this._http.delete(`${apiShomeBaseUrl}/api/v2/config/${url}?` + queryParams, this.options).pipe(this.ErrorAsync());
  }

  delGridViewInfo(queryParams: string): Observable<any> {
    return this._http.delete(`${apiShomeBaseUrl}/api/v2/config/DelGridViewInfo?` + queryParams, this.options).pipe(this.ErrorAsync());
  }

  getFormViewPage(queryParams: string): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/config/GetFormViewPage?` + queryParams, this.options).pipe(this.ErrorAsync());
  }

  setFormViewInfo(queryParams: string): Observable<any> {
    return this._http.post(`${apiShomeBaseUrl}/api/v2/config/SetFormViewInfo`, queryParams, this.options).pipe(this.ErrorAsync());
  }

  getCommonList(queryParams: string): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/sysmanage/GetCommonList?` + queryParams, this.options).pipe(this.ErrorAsync());
  }

  getGrid(gridKey: string): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/config/GetGrid/${gridKey}`, this.options).pipe(this.ErrorAsync());
  }

  getCustObjectList(url: string, field_name: string): Observable<any> {
    const data: Observable<string[]> = of([]);
    return this._http.get(`${apiShomeBaseUrl}` + url, this.options).pipe(
      map((repon: any) => {
        return {key: field_name, result: repon.data};
      }), this.ErrorAsync()
    );
  }

  getProjectList(): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/sysmanage/GetProjectList`, this.options).pipe(this.ErrorAsync());
  }

  getProjectList1(): Observable<any> {
    return this._http.get(`${apiShomeBaseUrl}/api/v2/sysmanage/GetProjectList1`, this.options).pipe(this.ErrorAsync());
  }

  clientMenuGetListByUserId(query: string): Observable<any> {
    return this._http.get<any>(apiServiceConfig + `/api/v1/webmanager/ClientMenuGetListByUserId?` + query).pipe(this.ErrorAsync());
  }

  getWebManagements(): Observable<any> {
    return this._http.get(`${apiServiceConfig}/api/v1/webmanager/GetWebManagements`, this.options);
  }

  resetUserPassword(body: {oldPassword: string, newPassword: string}): Observable<any> {
    return this._http.put(`${apiServiceConfig}/api/v1/UniUser/ChangePassword`, body, this.options).pipe(this.ErrorAsync());
  }

  private ErrorAsync() {
    return catchError(error => {
      this.handleError(error);
      return of(error.error);
    });
  }

  private handleError(error: any) {
    this._errorService.setError(error.error);
    this._messageService.add({severity: 'error', summary: 'Error Message', detail: error.error});
  }


}
