import { Directive, OnInit, ElementRef, Input, Output, AfterViewInit, SimpleChanges, HostListener, OnChanges, AfterViewChecked, AfterContentChecked, EventEmitter, NgModule } from '@angular/core';
import { UserManager } from 'oidc-client';
import { environment } from 'src/environments/environment';
import { cloneDeep } from 'lodash'
import queryString from 'query-string';
import { AuthService } from '../services/auth/auth.service';
import { AppApiService } from '../services/app.service';
declare var $: any
@Directive({
  selector: '[CheckHideActions]',
  host: {
    '(change)': 'onInputChange($event)',
    '(keydown.backspace)': 'onInputChange($event.target.value, true)'
  }
})
export class CheckHideActionsDirective implements OnInit, AfterViewInit, AfterContentChecked {
  @Output() rawChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() CheckHideActions: any;
  accountAPI = null;
  dsMenu = []
  private manager: UserManager = new UserManager(environment.authenSettings);
  constructor(
    private el: ElementRef,
    private authService: AuthService,
    private apiService: AppApiService
  ) {
    // this.accountAPI = this.authService.getPermissions();

  }


  ngAfterViewInit() {

  }
  ngOnInit() {
    // this.el.nativeElement.hidden = false;
    this.el.nativeElement.hidden = false;
  //   const queryMeny = queryString.stringify({ userId: this.authService.getClaims().sub, webId: environment.webId });
  //   this.apiService.clientMenuGetListByUserId(queryMeny).subscribe(results => {
  //     if (results.status === 'success') {
  //         let newArray: any = []
  //         results.data.forEach((element: any) => {
  //             newArray.push(element);
  //             if (element.submenus && element.submenus.length > 0) {
  //                 element.submenus.forEach((element1: any) => {
  //                     newArray.push(element1);
  //                 });
  //             }
  //         });;
  //         if (newArray) {
  //             const menus = newArray.find((m: any) => m.path === this.CheckHideActions.url);
  //             if (menus) {
  //                 const arrAction = menus.actions.map((d: any) => d.actionCd);
  //                 if (arrAction.indexOf(this.CheckHideActions.action) > -1) {
  //                     this.el.nativeElement.hidden = false;
  //                 } else {
  //                     this.el.nativeElement.hidden = true;
  //                 }
  //             }
  //         }else {
  //             this.el.nativeElement.hidden = true;
  //         }
  //     }
  // })
    // phân quyền S-Service

    // this.organizeInfoService.organizeInfo$.subscribe((results: any) => {
    //   if(results && results.length>0){
    //     this.manager.getUser().then(user => {
    //         const query = {  }
    //         // const queryParams = queryString.stringify({ organizeIds: results });
    //         const queryMeny = queryString.stringify({ userId: this.authService.getClaims().sub, webId: '70e930b0-ffea-43d3-b3a9-0e6b03f2b433' });
    //         // this.apiService.getUserMenus(queryParams).subscribe((results: any) => {

    //     });
    //   }
    // });
  }

  ngAfterContentChecked() {

  }
  onInputChange(event: any, backspace: any) {
    // if (event) {
    //     if (event.target) {
    //         let newVal = numeral(event.target.value).format('0,0');
    //         // // var myNumeral2 = numeral(newVal);
    //         // // var value2 = myNumeral2.value();
    //         var rawValue = newVal;
    //         console.log(newVal)
    //         event.target.value = newVal
    //         // this.model = newVal
    //         this.rawChange.emit(rawValue)
    //     }
    // }

  }
}
