import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent implements OnInit {
  // value: string= '';
  // libraries: any = [];
  // dataTrees: any[] = [];
  ngOnInit() {

  }

  // async initData() {
  //   this.libraries = await this.getLibraries();
  //   this.dataTrees = this.convertJsonToStructTree(this.libraries);
  // }

  // async getLibraries() {
  //   const libraries: any = [];
  //   try {
  //     // Get all folder to build the trees
  //     await this.firebaseService.getAllDocumentWithCondition('library_media', 'is_file', false, '==').then(res => {
  //       res.forEach(doc => {
  //         const data = initItemMedia(doc.data()).value;
  //         data.documentId = doc.id;
  //         data.id = doc.id;
  //         libraries.push(data);
  //       });
  //     });
    
  //     return libraries;
  //   } catch (error) {
  //     console.log('Error get library:', error);
  //     return [];
  //   }
  // }
}
