<section class="media-wrapper grid">
    <!-- <div class="col-4">
        <div class="p-field">
            <label for=""> Từ khoá</label>
            <input type="text" [(ngModel)]="value" />
        </div>
        <div class="libary-menu">
            <p-tree class="w-full md:w-30rem" [value]="dataTrees"></p-tree>
        </div>
    </div>
    <div class="col-8">
        <div class="flex">
            <div class="p-field ">
                <label for="">Loại tệp</label>
                <input type="text" [(ngModel)]="value" />
            </div>
        </div> 
    </div> -->
</section>
