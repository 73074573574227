<section>
    <app-form-detail
      *ngIf="listViews.length> 0"
      [detailInfo]="detailInfo"
      [buttons]="[]"
      (callback)="callbackForm($event)"
      (close)="close($event)"
      [listForms]="listViews">
    </app-form-detail>
  </section>
  