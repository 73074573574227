<div class="field" *ngIf="field.key != 'ToNum'">
    <input  [type]="type" [formControl]="formControl" (change)="to.change && to.change(field, $event)"
         [formlyAttributes]="field"  [ngClass]="{'ng-pristine ng-invalid ng-star-inserted ng-dirty ng-touched': ((formControl.touched || options.formState.submitted) && formControl.getError('required')) }"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <span class="ng-invalid text-red-500"
          *ngIf="((formControl.touched || options.formState.submitted) && formControl.getError('required'))">Trường bắt
          buộc nhập</span>
</div>

<div class="field" *ngIf="field.key == 'ToNum'">
    <input  [type]="type" [formControl]="formControl" (onChange)="to.change && to.change(field, $event)"
         [formlyAttributes]="field"  [ngClass]="{'ng-pristine ng-invalid ng-star-inserted ng-dirty ng-touched': ((formControl.touched || options.formState.submitted) && formControl.getError('required')) }"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <span class="ng-invalid text-red-500"
          *ngIf="((formControl.touched || options.formState.submitted) && formControl.getError('required'))">Trường bắt
          buộc nhập</span>
</div>