import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, inject, OnDestroy, EventEmitter, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import queryString from 'query-string';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AllModules, ColDef, Module } from '@ag-grid-enterprise/all-modules';
import { finalize, Subject, takeUntil } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { BBreadcrumb, GGridflexs } from 'src/app/models/demo';
import { AgGridFn } from 'src/app/common/funtions/funtion';
import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';
import { getParamString1 } from 'src/app/common/form-detail/funtions';
import { TranslateService } from '@ngx-translate/core';
import { ExportFileService } from 'src/app/services/export-file.service';
import { CardVehicleService } from 'src/app/services/card-service/card-vehicle.service';
import { CardApiService } from 'src/app/services/card-service/card.service';
import { ApartmentApiService } from 'src/app/services/apartment-service/apartment.service';
import { AppApiService } from 'src/app/services/app.service';
import { BButton } from 'src/app/common/form-detail/buttons.mode';
import * as moment from 'moment';
import { CheckHideAction, updateFieldInForm } from 'src/app/common/common';
import LOCAL_STORAGE, { ACTIONS, MENUACTIONROLEAPI } from 'src/app/common/constants/constant';
import { ShareService } from 'src/app/services/share.service';
import { ListGridAngularComponent } from 'src/app/common/ag-grid/list-grid-angular/list-grid-angular.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-vehicle-guest',
  templateUrl: './vehicle-guest.component.html',
  styleUrls: ['./vehicle-guest.component.scss']
})
export class VehicleGuestComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('gridList') gridList?: ListGridAngularComponent;
  MENUACTIONROLEAPI = MENUACTIONROLEAPI;
  ACTIONS = ACTIONS;
  
  constructor(
    private apiCardVehicleService: CardVehicleService,
    private apiCardService: CardApiService,
    private apiApartmentService: ApartmentApiService,
    private appApiService : AppApiService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private changeDetector: ChangeDetectorRef,
    public dialogService: DialogService,
    public fileService: ExportFileService,
    private shareService: ShareService) {
    this.defaultColDef = {
      resizable: true,
    };
  }
  public isLoading: boolean = true;
  private _datepipe = inject(DatePipe);
  public translate = inject(TranslateService);
  public listViewPaymentByDay: any[] = [];
  public detailInfoPaymentByDay: any = null;
  optionsButon: BButton[] = [];
  iconFilter = `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2913 3.47726H0.769231C0.344396 3.47726 0 3.14148 0 2.72726C0 2.31305 0.344396 1.97726 0.769231 1.97726H11.4465C11.8523 0.96494 12.8427 0.25 14 0.25C15.1573 0.25 16.1477 0.96494 16.5535 1.97726H19.2309C19.6557 1.97726 20.0001 2.31305 20.0001 2.72726C20.0001 3.14148 19.6557 3.47726 19.2309 3.47726H16.7087C16.4828 4.76856 15.356 5.75 14 5.75C12.644 5.75 11.5172 4.76856 11.2913 3.47726ZM12.7587 2.85147C12.753 2.90017 12.75 2.94974 12.75 3C12.75 3.69036 13.3096 4.25 14 4.25C14.6904 4.25 15.25 3.69036 15.25 3C15.25 2.30964 14.6904 1.75 14 1.75C13.3762 1.75 12.8591 2.20697 12.7652 2.80443C12.7636 2.82026 12.7614 2.83594 12.7587 2.85147Z" fill="#F3F8FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.64647 9.24997C9.32 8.09573 8.25877 7.25 7 7.25C5.74123 7.25 4.68 8.09573 4.35352 9.24997H0.769231C0.344396 9.24997 0 9.58576 0 9.99997C0 10.4142 0.344396 10.75 0.769231 10.75H4.35351C4.67996 11.9042 5.74121 12.75 7 12.75C8.25879 12.75 9.32004 11.9042 9.64649 10.75H19.2308C19.6557 10.75 20.0001 10.4142 20.0001 9.99997C20.0001 9.58576 19.6557 9.24997 19.2308 9.24997H9.64647ZM7 8.75C6.30964 8.75 5.75 9.30964 5.75 10C5.75 10.6904 6.30964 11.25 7 11.25C7.64529 11.25 8.17638 10.761 8.24297 10.1334C8.23499 10.0901 8.23083 10.0455 8.23083 9.99997C8.23083 9.95444 8.23499 9.90985 8.24296 9.86656C8.17636 9.23895 7.64528 8.75 7 8.75Z" fill="#F3F8FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.44645 18.0227H0.769231C0.344396 18.0227 0 17.6869 0 17.2727C0 16.8585 0.344396 16.5227 0.769231 16.5227H9.29127C9.51724 15.2314 10.644 14.25 12 14.25C13.356 14.25 14.4827 15.2314 14.7087 16.5227H19.2308C19.6557 16.5227 20.0001 16.8585 20.0001 17.2727C20.0001 17.6869 19.6557 18.0227 19.2308 18.0227H14.5536C14.1477 19.035 13.1574 19.75 12 19.75C10.8426 19.75 9.85227 19.035 9.44645 18.0227ZM10.75 17C10.75 16.3096 11.3096 15.75 12 15.75C12.6904 15.75 13.25 16.3096 13.25 17C13.25 17.6904 12.6904 18.25 12 18.25C11.3096 18.25 10.75 17.6904 10.75 17Z" fill="#F3F8FF"/>
  </svg> &nbsp;&nbsp;`;
  iconSetting = `
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.2002 13.2H13.2002V10.2H14.8002V13.2H17.8002V14.8H14.8002V17.7999H13.2002V14.8H10.2002V13.2Z" fill="#F3F8FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.200195 0.199951H7.8002V7.79995H0.200195V0.199951ZM6.2002 1.79995H1.8002V6.19995H6.2002V1.79995Z" fill="#F3F8FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8002 7.79995H10.2002V0.199951H17.8002V7.79995ZM11.8002 6.19995H16.2002V1.79995H11.8002V6.19995Z" fill="#F3F8FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.200195 10.2H7.8002V17.7999H0.200195V10.2ZM6.2002 11.8H1.8002V16.2H6.2002V11.8Z" fill="#F3F8FF"/>
  </svg>`;
  pagingComponent = {
    total: 0
  };
  displayDetail: boolean = false;
  displayPaymentByDay: boolean = false;
  public modules: Module[] = AllModules;
  public agGridFn = AgGridFn;
  columns: GGridflexs[] = [];
  itemsBreadcrumb: BBreadcrumb[] = [];
  columnDefs: ColDef[] = [];
  defaultColDef: any;
  @Output() callback = new EventEmitter<any>();
  query = {
    filter: '',
    projectCd: '',
    status: -1,
    partner_id: -1,
    vehicleTypeId: -1,
    dateFilter: 0,
    endDate: null,
    offSet: 0,
    pageSize: 20
  };
  totalRecord: number = 0;
  first: number = 0;
  countRecord: any = {
    totalRecord: 0,
    currentRecordStart: 0,
    currentRecordEnd: 0
  };
  loading: boolean = false;
  loadjs: number = 0;
  heightGrid: number = 0;
  gridKey: string = '';
  cardVehicleId: number | null = null;
  listsData: any[] = [];
  menuItem: MenuItem[] = [
    {
      label: 'Xuất excel',
      icon: 'uni-icon uni-import',
      visible: !CheckHideAction(MENUACTIONROLEAPI.GetVehiclePage.url, ACTIONS.EXPORT),
      command: () => {
        this.exportCardvehicle()
      }
    }
  ];
  private readonly unsubscribe$: Subject<void> = new Subject();
  displaySetting: boolean = false;
  endTime: string = '';
  checkEndDate: boolean = false;
  listViewsFilter: any[] = [];
  cloneListViewsFilter = [];
  detailInfoFilter: any = null;
  optionsButonFilter = [
    { label: '_Đặt lại_', value: 'Reset', class: 'huy', icon: 'uni-icon uni-refresh', disabled: false },
    { label: '_Áp dụng_', value: 'Search', class: '', icon: 'uni-icon uni-send uni-white-color', disabled: false },
  ];
  public getRowId = (params: any) => {
    return params.data.schemeId;
  }

  ngOnInit() {
    this.fetchDataList();
    const _route: any = this.route.data;
    const titlePage = _route['_value'].title;
    this.translate.get(['_Trang chủ_', '_Dịch vụ thẻ_', '_căn hộ_', `${titlePage}`])
      .subscribe(translations => {
        this.itemsBreadcrumb = [
          { label: translations['_Trang chủ_'], routerLink: '/home' },
          { label: translations[`${titlePage}`] },
        ];
      });
  }

  fetchDataList() {
    this.shareService.data$.subscribe((data)=> {
      this.query.projectCd = data;
      this.query.pageSize = 20;
      this.getFilter(data);
    }) 
    if (localStorage.hasOwnProperty(LOCAL_STORAGE.SETTINGS) && localStorage.getItem(LOCAL_STORAGE.SETTINGS) != null) {
      const projectCd = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SETTINGS) || '{}') 
      this.query.projectCd = projectCd;
      this.query.pageSize = 20;
      this.getFilter(projectCd);
    }
  }

  cancel() {
    this.query = {
      filter: '',
      projectCd: '',
      status: -1,
      partner_id: -1,
      vehicleTypeId: -1,
      dateFilter: 0,
      endDate: null,
      offSet: 0,
      pageSize: 20
    };
    this.load();
  }

  ngAfterViewChecked(): void {
    const a: any = document.querySelector('.header');
    const d: any = document.querySelector('.bread-crumb');
    const e: any = document.querySelector('.paginator');
    this.loadjs++;
    if (this.loadjs === 5) {
      if (a && a.clientHeight) {
        const totalHeight = a.clientHeight + d.clientHeight + e.clientHeight + 60;
        this.heightGrid = window.innerHeight - totalHeight;
        this.changeDetector.detectChanges();
      } else {
        this.loadjs = 0;
      }
    }
  }
  cauhinh() {
    this.displaySetting = true;
  }

  load() {
    this.columnDefs = [];
    this.isLoading = true;
    const query = {...this.query};
    query.filter = this.query.filter.trim();
    const queryParams = queryString.stringify(query);
    this.apiCardVehicleService.getCardvehicle(queryParams)
      .pipe(takeUntil(this.unsubscribe$),
      finalize(() => {
        this.isLoading = false;
      })
      )
      .subscribe(
        (results: any) => {
          if (results?.status === 'success') {
            this.listsData = results?.data?.dataList?.data;
            this.gridKey = results?.data?.dataList?.gridKey;
            if (this.query.offSet === 0) {
              this.columns = results?.data?.gridflexs;
            }
            this.initGrid();
            this.countRecord.totalRecord = results?.data?.dataList?.recordsTotal;
            this.countRecord.currentRecordStart = results?.data?.dataList?.recordsTotal === 0 ? this.query.offSet = 0 : this.query.offSet + 1;
            if ((results.data.dataList.recordsTotal - this.query.offSet) > this.query.pageSize) {
              this.countRecord.currentRecordEnd = this.query.offSet + Number(this.query.pageSize);
            } else {
              this.countRecord.currentRecordEnd = results?.data?.dataList?.recordsTotal;
              setTimeout(() => {
                const noData = document.querySelector('.ag-overlay-no-rows-center');
                if (noData) { noData.innerHTML = 'Không có kết quả phù hợp'; }
              }, 100);
            }
          } else {
            this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results?.message || null });
          }
        });
  }

  showButtons(event: any) {
    return {
      buttons: [
        {
          onClick: this.editRow.bind(this),
          label: this.translate.instant('_Xem_'),
          icon: 'pi pi-eye',
          class: 'btn-primary mr5',
          hide: CheckHideAction(MENUACTIONROLEAPI.GetVehiclePage.url, ACTIONS.VIEW_DETAIL)
        },
        {
          onClick: this.paymentByDayRow.bind(this),
          label: this.translate.instant('_Gia hạn thẻ_'),
          icon: 'pi pi-money-bill',
          class: 'btn-primary mr5',
          hide: CheckHideAction(MENUACTIONROLEAPI.GetVehiclePage.url, ACTIONS.PAYMENT_BY_DAY)
        },
        {
          onClick: this.setVehicleAuth.bind(this),
          label: this.translate.instant('_Xác nhận kiểm duyệt_'),
          icon: 'pi pi-check-circle',
          class: 'btn-primary mr5',
          hide: event.data.Status == 1 || event.data.Status == 2 || event.data.Status == 3 || CheckHideAction(MENUACTIONROLEAPI.GetVehiclePage.url, ACTIONS.CONFIRM)
        },
        {
          onClick: this.delRow.bind(this),
          label: this.translate.instant('_Xóa_'),
          icon: 'pi pi-trash',
          hide: event.data.Status == 1 || event.data.Status == 2 || event.data.Status == 0 || CheckHideAction(MENUACTIONROLEAPI.GetVehiclePage.url, ACTIONS.DELETE)
        },
        {
          onClick: this.lockRow.bind(this),
          label: this.translate.instant('_Khoá_'),
          icon: 'pi pi-lock',
          hide: event.data.Status == 3 || event.data.Status == 2 || event.data.Status == 0 || CheckHideAction(MENUACTIONROLEAPI.GetVehiclePage.url, ACTIONS.LOCK)
        },
        {
          onClick: this.unlockRow.bind(this),
          label: this.translate.instant('_Mở khóa_'),
          icon: 'pi pi-lock-open',
          hide: event.data.Status == 1 || event.data.Status == 2 || event.data.Status == 0 || CheckHideAction(MENUACTIONROLEAPI.GetVehiclePage.url, ACTIONS.UN_LOCK)
        },
      ]
    };
  }

  initGrid() {
    this.columnDefs = [
      ...AgGridFn(this.columns.filter((d: any) => !d.isHide)),
      {
        headerName: '',
        filter: '',
        suppressSizeToFit: true,
        maxWidth: 80,
        pinned: 'right',
        cellRenderer: 'buttonAgGridComponent',
        cellClass: ['border-right', 'no-auto', 'pl-2', 'pr-2'],
        cellRendererParams: (params: any) => this.showButtons(params),
        checkboxSelection: false,
        field: 'checkbox',
      }
    ];
  }
 
  exportCardvehicle(): void {
    const queryParams = queryString.stringify({...this.query, pageSize: 100000 });
    this.apiCardVehicleService.getCardvehicle(queryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (results: any) => {
          if(results.status === 'success') {
            const dataExport: any = [];
            const wscols = [
              {wch: 15}, {wch: 15}, {wch: 30}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 30}
            ];
            results.data.dataList.data.forEach((element: any) => {
              let data: any = {};
              data['Mã thẻ'] = element.CardCd;
              data['Loại phương tiện'] = element.VehicleTypeName;
              data['Chủ thẻ'] = element.FullName;
              data['Biển số'] = element.VehicleNo;
              data['Tên xe'] = element.VehicleName;
              data['Ngày hết hạn'] = element.EndTime;
              data['Trạng thái sử dụng'] = element.IsLock ? 'Sử dụng' : 'Không sử dụng';
              data['Trạng thái thẻ'] = element.StatusName;
              data['Người cập nhật'] = element.CreateByName;
              dataExport.push(data);
            });
            this.fileService.exportAsExcelFile(
              dataExport,
              'Danh sách vé xe khách ngoài_' + new Date(),
              wscols    
            );
          }
        });
  }

  delRow(event: any) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn thực hiện xóa thẻ xe khách này?',
      accept: () => {
        this.spinner.show();
        const queryParams = queryString.stringify({ cardVehicleId: event.rowData.CardVehicleId });
        this.apiCardService.delVehicleCard(queryParams)
          .pipe(takeUntil(this.unsubscribe$),
          finalize(()=> {
            this.spinner.hide();
          }))
          .subscribe(results => {
            if (results?.status === 'success') {
              this.messageService.add({ severity: 'success'
              , summary: 'Thông báo'
              , detail: results?.message || 'Xóa thẻ xe thành công' });
              if(this.gridList) {
                this.gridList.getGridApi().applyTransaction({ remove: [event?.rowData] });
              }
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results?.message || null });
            }
          });
      }
    });
  }

  paymentByDayRow({ rowData }: any) {
    this.displayPaymentByDay = true;
    this.cardVehicleId = rowData.CardVehicleId;
    this.endTime = rowData.EndTime;
    const queryParams = queryString.stringify({ CardVehicleId: this.cardVehicleId, StartDate: rowData?.StartTime, EndDate: this.endTime});
    this.apiCardService.getVehiclePaymentByDayInfo(queryParams)
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any) => {
        if (results?.status === 'success') {
          const datass = cloneDeep(results.data.group_fields);
          this.listViewPaymentByDay = [...datass];
          this.detailInfoPaymentByDay = results.data;
        }
      });
  }

  setVehicleAuth({ rowData }: any) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn thực hiện xác nhận kiểm duyệt thẻ xe này?',
      accept: () => {
        const body = {
          requestId: 0,
          cardVehicleId: rowData?.CardVehicleId || '',
          status: 1
        }
        this.apiCardVehicleService.setCardVehicleServiceAuth(body)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(results => {
            if (results?.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results?.message || 'Xác nhận thành công' });
              this.load();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results?.message || null });
            }
          });
      }
    });
  }

  addRow() {

  }

  lockRow({rowData}: any) {
    this.confirmationService.confirm({
      message: `Bạn có chắc chắn muốn khóa thẻ xe này?`,
      accept: () => {
        const queryParams = queryString.stringify({ CardVehicleId: rowData?.CardVehicleId, Status: 1 });
        this.apiApartmentService.setVehicleLocked(queryParams)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(results => {
            if (results?.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results?.message || 'Thành công'});
              if(this.gridList) {
                const itemsToUpdate: any[] = [];
                this.gridList.getGridApi().forEachNodeAfterFilterAndSort((rowNode: any, index: any) => {
                  if(rowNode.data.CardCd !== rowData.CardCd) {
                    return
                  }
                  const data = rowNode.data;
                  data.Status = 3;
                  data.StatusName = 'Khoá xe';
                  itemsToUpdate.push(data)
                })
                this.gridList.getGridApi().applyTransaction({update: itemsToUpdate});
                this.gridList.getGridApi().redrawRows();
              }
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results?.message || 'Thất bại' });
            }
          });
      }
    });
  }

  unlockRow({rowData}: any) {
    this.confirmationService.confirm({
      message: `Bạn có chắc chắn muốn mở khóa thẻ xe này?`,
      accept: () => {
        const queryParams = queryString.stringify({ CardVehicleId: rowData?.CardVehicleId, Status: 0 });
        this.apiApartmentService.setVehicleLocked(queryParams)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(results => {
            if (results?.status === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: results?.message || null});
              if(this.gridList) {
                const itemsToUpdate: any[] = [];
                this.gridList.getGridApi().forEachNodeAfterFilterAndSort((rowNode: any, index: any) => {
                  if(rowNode.data.CardCd !== rowData.CardCd) {
                    return
                  }
                  const data = rowNode.data;
                  data.Status = 1;
                  data.StatusName = 'Hoạt động';
                  itemsToUpdate.push(data)
                })
                this.gridList.getGridApi().applyTransaction({update: itemsToUpdate});
                this.gridList.getGridApi().redrawRows();
              }
            } else {
              this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results?.message || null });
            }
          });
      }
    });
  }

  editRow({ rowData }: any) {
    this.cardVehicleId = rowData.CardVehicleId;
    this.displayDetail = true;
  }

  callbackFormPaymentByDay(event: any) {
    this.spinner.show();
    const params = {
      ...this.detailInfoPaymentByDay,
      group_fields: event.forms,
    };
    this.apiCardService
      .setVehiclePaymentByDayInfo(params)
      .pipe(takeUntil(this.unsubscribe$),
       finalize(()=> {
        this.spinner.hide();
       })
      )
      .subscribe((results: any) => {
        if (results?.status === 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Thông báo',
            detail: results?.message || 'Thành công',
          });
          this.displayPaymentByDay = false;
          this.load();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Thông báo',
            detail: results?.message || 'Thất bại',
          });
        }
      });
  } 

  reloadListForm(event: any) {
    this.listViewPaymentByDay = [];
    this.detailInfoPaymentByDay = null;
    const queryParams = queryString.stringify({ CardVehicleId: this.cardVehicleId, StartDate: null, EndDate: this.endTime});
    this.apiCardService.getVehiclePaymentByDayInfo(queryParams)
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any) => {
        if (results.status === 'success') {
          const datass = cloneDeep(results.data.group_fields);
          this.listViewPaymentByDay = [...datass];
          this.detailInfoPaymentByDay = results.data;
        }
        this.changeDetector.detectChanges();
      });
  }

  handleEndDate(event: any) {
    if(event.target) {
      this.endTime = event.target.value;
    } else {
      this.endTime = moment(event).format('DD/MM/YYYY');
    }
  }

  hideFormPaymentByDay() {
    this.listViewPaymentByDay = [];
    this.detailInfoPaymentByDay = null;
    this.displayPaymentByDay = false;
  }

  onCellClicked(event: any) {
    if (event?.colDef?.cellClass.indexOf('colLink') > -1) {
      this.editRow(event = { rowData: event?.data });
    }
  }

  find() {
    this.load();
  }

  changePageSize() {
    this.load();
  }

  paginate(event: any) {
    this.query.offSet = event.first;
    this.first = event.first;
    this.query.pageSize = event.rows;
    this.load();
  }
 
  getContextMenuItems(params: any) {
    const result = [
      'copy',
      'paste',
      'separator',
      'excelExport'
    ];
    return result;
  }

  // filter
  getFilter(projectCd: string) {
    this.appApiService.GetFilterForm('filter_vehicle_card_guest')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        if (results.status === 'success') {
          const datass = cloneDeep(results.data.group_fields);
          const listViews = cloneDeep(datass);
          this.cloneListViewsFilter = cloneDeep(listViews);
          this.listViewsFilter = [...listViews];
          this.detailInfoFilter = results.data;
          const params = getParamString1(this.listViewsFilter);
          this.query = { ...this.query, ...params };
          this.query.projectCd = projectCd
          this.detailInfoFilter = results.data;
          this.query.pageSize = 20
          this.load();
        }
      });
  }

  handleChangeValueFilterDate(event: any) {
    if(event.value == '0') {
      this.checkEndDate = true;
      updateFieldInForm(this.listViewsFilter, 'endDate', false, 'isVisiable')
      this.listViewsFilter = cloneDeep(this.listViewsFilter);
    } else if(event.value == '1') {
      this.checkEndDate = false;
      updateFieldInForm(this.listViewsFilter, 'endDate', true, 'isVisiable')
      this.listViewsFilter[0].fields.forEach((item: any) => {
        if(item.field_name === 'endDate') {
          item.columnValue = new Date();
        }
      })
      this.listViewsFilter = cloneDeep(this.listViewsFilter);
    }
  } 

  filterLoad(event: any) {
    this.listViewsFilter = cloneDeep(event.forms);
    this.query = { ...this.query, ...event.model };
    if(this.checkEndDate == true) {
      this.query.endDate = null;
    }
    this.load();
  }

  close({ event, datas }: any) {
    if (event !== 'Close') {
      const listViews = cloneDeep(this.cloneListViewsFilter);
      this.listViewsFilter = cloneDeep(listViews);
      const params = getParamString1(listViews);
      this.query = { ...this.query, ...params };
      this.load();
    } else {
      this.listViewsFilter = cloneDeep(datas);
    }
  }

  resetFilter({event}: any) {   
      this.getFilter(this.query.projectCd);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}