import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ShareService } from 'src/app/services/share.service';
@Component({
  selector: 'app-nz-input',
  templateUrl: './nz-input.component.html',
  styleUrls: ['./nz-input.component.scss']
})
export class NzInputComponent  extends FieldType<FieldTypeConfig> implements OnInit{
  get type() {
    return this.props.type || 'text';
  }

  constructor(
    private shareService: ShareService
  ) {
    super();
  }

  ngOnInit(): void {
    this.shareService.data$.subscribe((data)=> {
      if(data) {
        const control = this.form.get('TotalNum');
        if (control) {
          control.setValue(data.totalNum);
        }
      }
    })  
  }
}
