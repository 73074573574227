import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import LOCAL_STORAGE from 'src/app/common/constants/constant';

@Injectable()
export class AuthService {
  private manager: UserManager = new UserManager(environment.authenSettings);
  private user: any = null;
  constructor(private http: HttpClient,
    private messageService: MessageService,
    ) {
      this.manager.getUser().then(async user => {
        this.user = user;
      });
      }

  isLoggedIn(): Promise<boolean> {
    // localStorage.removeItem('menuItems');
    if (this.user != null) {
      return new Promise((resolve, reject) => resolve(!this.user.expired));
    }

    return this.manager.getUser().then(user => {
      this.user = user;
      // this.getEmpDetail()
      return user != null && !user.expired;
    });
  }

  getClaims(): any {
    return this.user.profile;
  }


  getAuthorizationHeaderValue(): string {
    return `Bearer ${this.user.access_token}`;
  }

  getAccessTokenValue(): string {
    return  this.user.access_token;
  }

  getUserName(): string {
    return this.user.profile.preferred_username;
  }

  getUserId(): string {
    return this.user.profile.sub;
  }

  isExpired(): boolean {
    return this.user.expired;
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  signout(): Promise<void> {
    localStorage.removeItem('menuItems');
    localStorage.removeItem('avatarUrl');
    localStorage.removeItem('employeeId');
    localStorage.removeItem('storeId');
    localStorage.removeItem('userIdRequest');
    localStorage.removeItem(LOCAL_STORAGE.SETTINGS);
    return this.manager.signoutRedirect();
  }

  completeAuthentication(): Promise<void> {
    localStorage.setItem('projectCd', '01');
    return this.manager.signinRedirectCallback()
      .then(user => { this.user = user; })
      .then(_ => this.getEmpDetail());
  }

  getUserImage(): string | null {
    return localStorage.getItem('avatarUrl');
  }

 async getEmpDetail() {
    // const headers = new HttpHeaders({ Authorization: this.getAuthorizationHeaderValue() });
    // return this.http.get(environment.apiBase + '/api/v2/employee/GetEmployee?employeeId=', { headers }).toPromise()
    //   .then((emp: any) => {
    //     if (emp && emp.data) {
    //       localStorage.setItem('avatarUrl', emp.data.avatarUrl);
    //       localStorage.setItem('employeeId', emp.data.employeeId);
    //     }
    //   });
  }

  getWorkingProject() {
    return localStorage.getItem('projectCd');
  }

}
