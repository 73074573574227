<div class="flex align-items-center gap-2 pl-2">
    <i>Đính kèm ảnh</i>
    <i title="Chọn ảnh" class="fa fa-image cursor-pointer text-primary-500 text-lg" (click)="chooseImageContent()"></i>
</div>
<div class="grid pt-2">
    <div class="col-6">Nội dung</div>
    <div class="col-6">Hiển thị</div>
</div>
<div class="field">
    <md-editor id="content"
    name="content" 
    [height]="'400px'" 
    #aceEditor
    [formControl]="formControl"
    (onChange)="to.change && to.change(field, $event)"
    (ngModelChange)="onMarkdownChange($event)"
    (onPreviewDomChanged)="onPreviewDomChanged($event)"
    (onEditorLoaded)="onEditorLoaded($event)"
    [mode]="mode"
    class="md-editor-binh-luan" 
    maxlength="2500"
    ></md-editor>
</div>
<!-- <div class="flex gap-3 align-items-center pl-2">
    <i>Tệp đính kèm:</i>
    <div class="attack-file flex gap-2 align-items-center surface-300 border-round-2xl p-1 cursor-pointer" (click)="handleAttackFile()">
        <i class="pi pi-paperclip text-primary-500"></i>
        <div class="name text-primary-500">Chọn tệp đính kèm</div>
    </div>
</div> -->

<p-dialog [(visible)]="showDialogImage" styleClass="popup-config" [appendTo]="'body'" header="Thư viện ảnh" [modal]="true" (onHide)="reset()"
    [style]="{width: '50vw', height: 'auto'}">
    <div class="grid mt-4">
        <div class="col-3 " *ngFor="let itemImage of listImages; let i = index" style="height: 200px;">
            <div class="image-wrapper p-2 shadow-4 w-full h-full relative">
                <img src="{{itemImage?.documentUrl}}" alt="avatar" class="w-full h-full" style="object-fit: contain;">
                <div (click)="chooseImage(itemImage.documentUrl)"    class="btn p-2 inline cursor-pointer text-white border-round-sm" style="position: absolute; bottom: 10px; right: 10px; background-color: #0052CC;">
                    <i class="pi pi-check"></i>
                </div>
            </div>
        </div>
    </div> 
    <div class="grid p-2">
        <div class="col-6">
            <div class="paginator">
                <p-paginator [rows]="query.pageSize" [totalRecords]="countRecord.totalRecord" [first]="first"
                (onPageChange)="paginate($event)" [rowsPerPageOptions]="[12,24,36,48,200,500,{ showAll: 'ALL' }]">
                 </p-paginator>
            </div>
        </div>
        <div class="col-6 flex justify-content-end align-items-center">
            <label class="btn-upload flex gap-2 p-2 border-round-sm inline-block" [for]="'image-service' + field.key" style="background: #0052CC ; color: #fff">
                <i class="pi pi-cloud-upload"></i>
                <div class="name">Upload ảnh</div>
            </label>
            <input (change)="onUploadOutput($event, field.key)"
            accept="image/jpeg,image/png,image/jpg,image/gif" type="file" style="display: none;" [id]="'image-service' + field.key" class="myfile1">
        </div>
    </div>
</p-dialog>


<p-dialog [(visible)]="showMedia" styleClass="popup-config" [appendTo]="'body'" header="Quản lý thư viện" [modal]="true"
    [style]="{width: '50vw', height: 'auto'}">
    <app-media-list *ngIf="showMedia"></app-media-list>
</p-dialog>


