

<!-- <p-menu [model]="menuItems" [style]="{'width': '100%', 'height': '100%'}"></p-menu> -->
<!-- <div class="d-flex gap-16 middle main-menu">
    <p-menubar [model]="menulist1" styleClass="sidebarBody" id="sidebarBody"></p-menubar>
    <div *ngIf="menulist2.length > 0" class="card flex justify-content-center more-right" >
        <button #btn type="button" pButton icon="uni-icon uni-more uni-white-color" (click)="menu.toggle($event)"></button>
        <p-tieredMenu #menu [model]="menulist2" styleClass="uni-black-color" [popup]="true"></p-tieredMenu>
    </div>
</div> -->

<div class="aside-menu">
    <label class="back" style="border-bottom: 1px solid #f8f8f8" *ngIf="detailRouter" (click)="onBackMenu()">
        <span  class="pi pi-arrow-left"></span>
        Back  {{detailRouter?.title}}
    </label>
    <p-slideMenu *ngIf="menulist1.length > 0" [model]="menulist1" [backLabel]="''"></p-slideMenu>

</div>