import {Injectable} from '@angular/core';
import { collection, doc, Firestore, getDoc, where, query, collectionData, orderBy, limit, setDoc, addDoc } from '@angular/fire/firestore';

@Injectable()
export class ChatApiService {

  constructor(
    private firestore: Firestore
  ) {
  }

  getRoom(userIdRequest: string) {
    const mainDocumentReference = doc(this.firestore, 'users', userIdRequest)
    const childCollectionReference = collection(mainDocumentReference, 'threads');
    const docData = query(childCollectionReference, where('type', '==', 'service'), orderBy('last_message.time', 'desc'),orderBy('created', 'desc'), limit(1000))
    return collectionData(docData, {idField: 'id'})
  }

  getUserInRoom(thread_id: string) {
    const mainDocumentReference = doc(this.firestore, 'threads', thread_id)
    const childCollectionReference = collection(mainDocumentReference, 'users')
    const docData = query(childCollectionReference)
    return collectionData(docData, {idField: 'id'})
  }

  getMessage(thread_id: string) {
    const mainDocumentReference = doc(this.firestore, 'threads', thread_id)
    const childCollectionReference = collection(mainDocumentReference, 'messages');
    const docData = query(childCollectionReference, orderBy('time', 'desc'), limit(100))
    return collectionData(docData, {idField: 'id'})
  }

  getMessageSearch(thread_id: string, valueSearch: string) {
    const mainDocumentReference = doc(this.firestore, 'threads', thread_id)
    const childCollectionReference = collection(mainDocumentReference, 'messages');
    const docData = query(childCollectionReference,where('payload', '>=', valueSearch), limit(100))
    return collectionData(docData, {idField: 'id'})
  }

  getEmoji() {
    const mainDocumentReference = doc(this.firestore, 'stickers', 'default'); 
    const childCollectionReference = collection(mainDocumentReference, 'items');
    const docData = query(childCollectionReference, limit(120))
    return collectionData(docData, {idField: 'id'})
  }

  updateUserChat(thread_id: string, childCollection: string, idUser: string, data: any) {
    const mainDocumentReference = doc(this.firestore, 'threads', thread_id)
    const childCollectionReference = collection(mainDocumentReference, childCollection);
    const childCollectionReference2 = doc(childCollectionReference, idUser)
    return setDoc(childCollectionReference2, data)
  }

  createMessageChat(thread_id: string, childCollection: string, data: any) {
    const mainDocumentMessage = doc(this.firestore, 'threads', thread_id)
    const childCollectionMessage = collection(mainDocumentMessage, childCollection);
    return addDoc(childCollectionMessage, data)
  }
}
