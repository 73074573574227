import { Injectable } from '@angular/core';
import { ref, Storage } from '@angular/fire/storage';
import { deleteObject } from '@firebase/storage';

@Injectable()
export class ApiStorageService {
    constructor(
      private storage: Storage
    ) {

    }
  
    async deletefileStorage(path: string) {
        const refStorage = ref(this.storage, path)
        return await deleteObject
    }

    deletefileThumbsStorage(path: string) {
       const arrSize = ['256x256', '512x512', '1024x1024'];
       let sliptPath = path.split('.');
       const promises = [];
       for (const item of arrSize) {
        promises.push(this.deletefileStorage(`${sliptPath[0]}_${item}.${sliptPath[1]}`).catch(err => { }));
      }
      return Promise.all(promises)
    }
}
