
// tslint:disable-next-line:class-name
export class Last_Message {
    id = '';
    payload = '';
    time: any = null;
    title = '';
    type = '';
    user = '';
    constructor(id = '', payload = '', time = null, title = '', type = '', user = '') {
        this.id = id;
        this.payload = payload;
        this.time = time;
        this.title = title;
        this.type = type;
        this.user = user;
    }
}

export class TicKet {
    // tslint:disable-next-line:variable-name
    close_by = '';
    // tslint:disable-next-line:variable-name
    close_date = '';
    // tslint:disable-next-line:variable-name
    external_id = '';
    id = '';
    // tslint:disable-next-line:variable-name
    request_by = '';
    service = '';
    status = '';
    // tslint:disable-next-line:variable-name
    constructor(close_by = '', close_date = '', external_id = '', id = '',  request_by = '', service = '', status = '') {
        this.close_by = close_by;
        this.close_date = close_date;
        this.external_id = external_id;
        this.service = service;
        this.status = status;
        this.id = id;
        this.request_by = request_by;
    }
}


export class Chats {
    id = '';
    avatar: any = null;
    created: any = null;
    // tslint:disable-next-line:variable-name
    created_by = '';
    emailed = '';
    // tslint:disable-next-line:variable-name
    last_message: any;
    name = '';
    ticket: TicKet[] = [];
    type = '';
    unread = 0;
    active: boolean = false
}

export class CustomerDS {
    id = '';
    email = '';
    name = '';
    phone = '';
    constructor(id = '', email = '', name = '', phone = '') {
        this.id = id;
        this.email = email;
        this.name = name;
        this.phone = phone;
    }
}

export class DsDoiChats {
    id = '';
    // avatar: any = null;
    action = '';
    // tslint:disable-next-line:variable-name
    build_code = '';
    // tslint:disable-next-line:variable-name
    close_by = '';
    // tslint:disable-next-line:variable-name
    close_date = '';
    created: any = null;
    customer: CustomerDS | null = null;
    // tslint:disable-next-line:variable-name
    project_code = '';
    // tslint:disable-next-line:variable-name
    request_by = '';
    // tslint:disable-next-line:variable-name
    room_code = '';
    // tslint:disable-next-line:variable-name
    service = '';
    status = '';
    // tslint:disable-next-line:variable-name
    summary = '';
    // tslint:disable-next-line:variable-name
    thread_id = '';
    time: any = null;
    title = '';
    // tslint:disable-next-line:variable-name
    updated_date: any = null;
}

export class Delivery {
    id = '';
    read: boolean = false;
    time: any = null;
}


export class Messages {
    id = '';
    // tslint:disable-next-line:variable-name
    delivery: Delivery[] = [];
    details = '';
    image = '';
    owner = '';
    payload = '';
    summary = '';
    time: any = null;
    timestring: any = null;
    type = '';
    status = '';
    // tslint:disable-next-line:variable-name
    ticket_id = '';
    title = '';
    avatar = '';
    name = '';
    lat = '';
    lng = '';
    // tslint:disable-next-line:variable-name
    last_accessed: any = null;
}

export class Threads {
    id = '';
    type = '';
    name = '';
    // tslint:disable-next-line:variable-name
    project_code = '';
    // tslint:disable-next-line:variable-name
    project_name = '';
    status = '';
    typing: string[] = [];
    // tslint:disable-next-line:variable-name
    created: any = null;
    ticket: TicKet | null = null;
    customer: CustomerDS | null = null;
}


export class UserChat {
    id = '';
    avatar = '';
    name = '';
    roles: string[] = [];
    nameroles = '';
    // tslint:disable-next-line:variable-name
    last_accessed: any = null;
}



export class TypeModel {
    type = '';
    value = '';
}
export class ContactModel {
    email: TypeModel[] = [];
    note = '';
    phone: TypeModel[] = [];
}

export class Friends {
    id = '';
    // tslint:disable-next-line:variable-name
    alias = '';
    avatar = '';
    // tslint:disable-next-line:ban-types
    contacts: ContactModel[] = [];
    // tslint:disable-next-line:variable-name
    created: any = null;
    name = '';
    status = '';
}

export class Project {
    id = '';
    name = '';
}


export class Users {
    id = '';
    // tslint:disable-next-line:variable-name
    authentication_id = '';
    // tslint:disable-next-line:ban-types
    auths: String[] = [];
    // tslint:disable-next-line:variable-name
    customer_id = '';
    // tslint:disable-next-line:variable-name
    last_active: any = null;
    // tslint:disable-next-line:variable-name
    meta: MetaUser | null = null;
    online: boolean = false ;
}

export class MetaUser {
    avatar = '';
    email = '';
    name = '';
    phone = '';
}

export class Emoji {
    id = '';
    name = '';
    url = '';
    size = '';
    type = '';
}

