import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ShareService {
    private data = new Subject<any>();
    private setting$ = new BehaviorSubject<any>({
        projectCd: ''
    });
    public data$ = this.data.asObservable();
    emitdata(x: any) {
        this.data.next(x);
    }

    emitCurrentData() {
        const currentData = this.setting$.getValue();
        this.setting$.next(currentData);
    }
}