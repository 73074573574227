

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../auth/error.service';
const apiShomeBaseUrl = environment.apishomeBase;
@Injectable()
export class NotifyApiService {
    private _http = inject(HttpClient);
    private _authService = inject(AuthService);
    private _messageService = inject(MessageService);
    private _errorService = inject(ErrorService)
    constructor() {
    }
    options = {
        // headers: new HttpHeaders({
        //     Authorization: this._authService.getAuthorizationHeaderValue(),
        //     'Content-Type': 'application/json',
        // })
    };

    getReceiptFilter(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/receipt/GetReceiptFilter`, this.options).pipe(this.ErrorAsync());
    }

    getNotifyFilter(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyFilter`, this.options).pipe(this.ErrorAsync());
    }

    getAppNotifyPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getAppNotifyInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    setNotifyInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/notify/SetNotifyInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    delNotifyInfo(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/notify/DelNotifyInfo?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    setNotifyDraft(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/notify/SetNotifyDraft`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getDocumentUrl(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetDocumentUrl?` + params, this.options).pipe(this.ErrorAsync());
    }

    setDocumentUrl(body: {projectCd: string, documentTitle: string, documentUrl: string}): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/notify/SetDocumentUrl`, body, this.options).pipe(this.ErrorAsync());
    }

    getNotifyToPushs(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/notify/GetNotifyToPushs?` + params, this.options).pipe(this.ErrorAsync());
    }

    getNotifyToPushsV2(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyToPushs?` + params, this.options).pipe(this.ErrorAsync());
    }

    // getNotifyPushStatus(): Observable<any> {
    //     return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/notify/GetNotifyPushStatus`, this.options).pipe(this.ErrorAsync());
    // }

    getNotifyPushStatus(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyPushStatus`, this.options).pipe(this.ErrorAsync());
    }

    delNotiPush(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v1/shome/DelNotiPush?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    delNotiPushV2(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/notify/DelNotifyPush?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    delNotifyPushs(body: {ids: string[]}): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v1/notify/DelNotifyPushs`, body, this.options).pipe(this.ErrorAsync());
    }

    delNotifyPushsV2(body: {ids: string[]}): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v2/notify/DelNotifyPushs`, body, this.options).pipe(this.ErrorAsync());
    }

    setNotifyCreatePush(body: {notiId: number | null, projectCd: string, buildingCd: string, apartments: string[] }): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v1/notify/SetNotifyCreatePush`, body, this.options).pipe(this.ErrorAsync());
    }

    setNotifyCreatePushV2(body: {n_id: string | null, projectCd: string, buildingCd: string, apartments: string[] }): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v2/notify/SetNotifyCreatePush`, body, this.options).pipe(this.ErrorAsync());
    }

    setNotifyToPushRun(body: {ids: any[], notiId: number | null, action: string, run_act: number}): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v1/notify/SetNotifyToPushRun`, body, this.options).pipe(this.ErrorAsync());
    }

    setNotiToPushsV2(body: {ids: any[], notiId: number | null, action: string, run_act: number}): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v2/notify/SetNotiToPushs`, body, this.options).pipe(this.ErrorAsync());
    }

    setNotifyToPushRunV2(body: {ids: any[], notiId: number | null, action: string, run_act: number}): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v2/notify/SetNotifyToPushRun`, body, this.options).pipe(this.ErrorAsync());
    }

    getNotifyTempPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyTempPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getNotifyRefPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyRefPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getNotifyRef(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyRef?` + params, this.options).pipe(this.ErrorAsync());
    }

    delNotifyRef(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/notify/DelNotifyRef?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    setNotifyRef(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/notify/SetNotifyRef`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getNotifyTemp(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyTemp?` + params, this.options).pipe(this.ErrorAsync());
    }

    setNotifyTemp(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/notify/SetNotifyTemp`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getNotifyTempFilter(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/notify/GetNotifyTempFilter`, this.options).pipe(this.ErrorAsync());
    }

    setNotifyTempDraft(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/notify/SetNotifyTempDraft`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    delNotifyTemp(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/notify/DelNotifyTemp?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    private ErrorAsync() {
        return catchError(error => {
            this.handleError(error)
            return of(error.error);
        })
    }

    private handleError(error: any) {
        this._errorService.setError(error.status);
        this._messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error });
    }
}
