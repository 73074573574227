import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { collection, collectionData, doc, Firestore, limit, orderBy, query, where } from '@angular/fire/firestore';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { result } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, MessageService } from 'primeng/api';
import {finalize, Subject, take, takeUntil } from 'rxjs';
import LOCAL_STORAGE from 'src/app/common/constants/constant';
import { AppApiService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ShareService } from 'src/app/services/share.service';
const queryString = require('query-string');

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

    userName = '';
    avatarUrl = '';
    value = 10;
    idUser: string | null = '';
    items: MenuItem[] = [];
    isShowChangePassword = false;
    modelPass = {
        oldPassword: '', 
        userPassword: '',
        userPassCf: ''
      }
    chooseOrga = false
    confimPassword = false;
    submitPass = false;
    organizeRole = null;
    listmenuChecks = []
    menuItems: any[] = [];
    projectList: any[] = [];
    selectProject: string = '';
    urlsForDisableOrgan = []
    itemUnreads: any[] = []
    detailOrganizes = []
    loadForm: boolean = false;
    displayChangePassword: boolean = false;
    isShowPass = false;
    isShowRepass = false;
    isShowOld = false;
    cats: any[] = [];
    constructor(
        private firestore: Firestore,
        private router: Router,
        private messageService: MessageService,
        public translate: TranslateService,
        private apiAppService: AppApiService,
        private shareService: ShareService,
        private spinner: NgxSpinnerService,
        private auth: AuthService,
        private authFirebase: Auth
    ) {
        translate.addLangs(['vi','en']);
        if(localStorage.hasOwnProperty('currentLang') && localStorage.getItem('currentLang') != null) {
            const getLang = localStorage.getItem('currentLang');
            translate.use(`${getLang}`)
        }else {
            translate.setDefaultLang('vi');
            translate.use('vi')
        }
        // this.translate.getTranslation(this.translate.currentLang)
    }

    saveLang(lang: string) {
        localStorage.setItem('currentLang', lang);
        window.location.reload()
    }

    logout() {
        this.auth.signout();
        this.router.navigateByUrl('/login');
    }
    changePassword() {
        this.displayChangePassword = true;
    }

    ngOnInit() {
      this.getWebManagements();
        this.items = [
            {
                label: this.translate.instant('_change_password_'),
                icon: 'pi pi-user-edit',
                command: () => {
                    this.changePassword();
                }
            },
            {
                label: this.translate.instant('_Logout_'),
                icon: 'pi pi-refresh',
                command: () => {
                    this.logout();
                }
            }
        ];
        this.loadForm = false;
        this.userName = this.auth.getUserName();
        this.authFirebase.onAuthStateChanged((auth)=> {
            this.idUser = auth ? auth.uid : '';
            localStorage.setItem('userID', auth ? auth.uid : '')
            if(this.idUser) {
                this.getDataMessage(this.idUser);
            }
        })

        this.getProjectList1();
    }

    ngAfterContentChecked() {
        this.items[0].label = this.translate.instant('_change_password_');
        this.items[1].label = this.translate.instant('_Logout_');
    }

    playAudio() {
        let audio = new Audio();
        audio.src = "https://firebasestorage.googleapis.com/v0/b/sunshine-super-app.appspot.com/o/s-housing%2Fnotifi.mp3?alt=media&token=f53ad8a3-c1d6-4baa-a7a4-2482b9000dd8";
        audio.load();
        audio.play();
    }

    getDataMessage(idUser: string) {
        const url = window.location.pathname;
        const mainDocumentReference = doc(this.firestore, 'users', idUser)
        const childCollectionReference = collection(mainDocumentReference, 'threads');
        const docData = query(childCollectionReference, where('type', '==', 'service'), where('unread', '==', 1), orderBy('last_message.time', 'desc'), orderBy('created', 'desc'), limit(1000))
        collectionData(docData, {idField: 'id'})
        .pipe(take(1))
        .subscribe((results) => {
            if(results) {
                this.itemUnreads = results.filter((item: any) => item.unread === 1);
                this.loadForm = true;
                if(this.loadForm && this.itemUnreads.length > 0 && url !== '/chat') {
                    this.playAudio();
                    this.messageService.add({severity: 'info', summary: 'Thông báo', detail : `${this.itemUnreads[0].name}: ${this.itemUnreads[0].last_message.payload}`})
                }
            }
        })
    }

    getProjectList1() {
        this.apiAppService.getProjectList1()
        .subscribe(results => {
            this.projectList = results.data;
            if (localStorage.hasOwnProperty(LOCAL_STORAGE.SETTINGS) && localStorage.getItem(LOCAL_STORAGE.SETTINGS) != null) {
                const projectCd = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SETTINGS) || '{}')
                this.selectProject = projectCd
            } else {
                this.selectProject = this.projectList[0].value;
            }
            this.shareService.emitdata(this.selectProject)
            localStorage.setItem(LOCAL_STORAGE.SETTINGS, JSON.stringify(this.selectProject));
        })
    }

    changeProject(event: any) {
        localStorage.setItem(LOCAL_STORAGE.SETTINGS, JSON.stringify(event.value))
        this.shareService.emitdata(event.value)
    }

    resetPassword() {
        this.modelPass = {
            oldPassword: '',
            userPassword: '',
            userPassCf: ''
        }
        this.isShowPass = false;
        this.isShowRepass = false;
        this.isShowOld = false;
    }

    saveChangePass() {
        this.submitPass = true;
        if ((this.modelPass.oldPassword && !this.modelPass.userPassCf) || this.confimPassword) {
          return;
        } else {
            this.spinner.show();
            const params = {
                oldPassword: this.modelPass.oldPassword,
                newPassword: this.modelPass.userPassCf  
              };
                this.apiAppService.resetUserPassword(params)
                .pipe(takeUntil(this.unsubscribe$),
                finalize(()=>{
                  this.spinner.hide();
                }))
                .subscribe(results => {
                if (results?.status === 'success') {
                  this.messageService.add({severity: 'success', summary: 'Thông báo', detail: results?.message || 'Thay đổi mật khẩu thành công'});
                  this.submitPass = false;
                  this.displayChangePassword = false;
                }
                if (results.status === 'error') {
                  this.messageService.add({severity: 'error', summary: 'Thông báo', detail: results?.message});
                }
              });
        }
    }

    checkPasswordcf() {
        if (this.modelPass.userPassword === this.modelPass.userPassCf) {
          this.confimPassword = false;
        } else {
          this.confimPassword = true;
        }
    }

    ngAfterViewChecked(): void {

    }

    goToHome() {
      this.router.navigate(['/home']);
      // const pathname = window.location.pathname;
      //   let pathUrl = pathname.split("/");
      //   let pathUrl1 = '/';
      //   pathUrl1 = pathUrl1.concat(pathUrl["1"])
      //   if(pathUrl[2]){
      //       pathUrl1 = pathUrl1.concat("/").concat(pathUrl["2"])
      //   }
      //   this.organizeInfoService.organizeInfo$.subscribe((results: any) => {
      //       if(results && results.length>0){
      //        const queryParams = queryString.stringify({ organizeIds: results });
      //         this.apiService.getUserMenus(queryParams).subscribe(results => {
      //              if (results.status === 'success') {
      //                  this.menuItems = results.data;
      //                  this.convetArry(this.menuItems);
      //                   if(this.listmenuChecks.map(d => d.path).indexOf(pathUrl1) < 0) {
      //                       this.router.navigate(['/404']);
      //                   }else{
      //                     // this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Không có quyền truy cập' });
      //                     this.router.navigate(['/home']);
      //                   }
      //                  this.menuItems = [...this.menuItems];
      //              }
      //          });
      //       }else{
      //           this.router.navigate(['/404']);
      //       }
      //   });

    }

  private readonly unsubscribe$: Subject<void> = new Subject();
  getWebManagements() {
    this.apiAppService.getWebManagements().pipe(takeUntil(this.unsubscribe$))
      .subscribe( (results: any) => {
        if(results && results.length > 0)  {
          this.cats = results
        }
      })
  }

}
