import { DatePipe } from "@angular/common";
import { inject } from "@angular/core";



export function convertFormData(datas: any[]) {
    const _datepipe = inject(DatePipe);
    datas.forEach(results => {
        results.fields.forEach((data: any) => {
            if (data.columnType === 'datetime' && data.isVisiable) {
                if (data.columnValue) {
                    data.columnValue = typeof data.columnValue === 'string' ? data.columnValue : _datepipe.transform(new Date(data.columnValue), 'DD/MM/YYYY');
                } else {
                    data.columnValue = data.columnValue;
                }
            } else if (data.columnType === 'datefulltime' && data.isVisiable) {
                if (data.columnValue) {
                    data.columnValue = typeof data.columnValue === 'string' ? data.columnValue : _datepipe.transform(new Date(data.columnValue), 'DD/MM/YYYY HH:mm:ss');
                } else {
                    data.columnValue = data.columnValue;
                }
            } else if (data.columnType === 'timeonly') {
                data.columnValue = typeof data.columnValue === 'string' ? `${data.columnValue}:00` : _datepipe.transform(new Date(data.columnValue), 'HH:mm');
                // data.columnValue = typeof data.columnValue === 'string' ? `${data.columnValue}:00` : null;
            } else if (data.columnType === 'selectTree') {
                data.columnValue = data.columnValue ? data.columnValue.orgId : null;
                delete data.options;
            } else if (data.columnType === 'autoCompletes') {
                data.columnValue = data.columnValue && data.columnValue.length > 0 ? data.columnValue.map((d: any) => d.code).toString() : null;
                delete data.options;
            } else if (data.columnType === 'selectTrees') {
                console.log(" data.columnValue", data.columnValue);

                data.columnValue = data.columnValue && data.columnValue.length > 0 ? data.columnValue.map((d: any) => d.orgDepId).toString() : null;
                delete data.options;
            } else if (data.columnType === 'currency') {
                data.columnValue = Number(data.columnValue.replace(/[^0-9.-]+/g, ""));
            } else if (data.columnType === 'members') {
                delete data.options;
            } else if (data.columnType === 'linkUrlDrag' || data.columnType === 'listMch') {
                data.columnValue = (data.columnValue && data.columnValue.length) > 0 ? data.columnValue.toString() : '';
            } else if ((data.columnType === 'select' || data.columnType === 'multiSelect' || data.columnType === 'dropdown' || data.columnType === 'checkboxList') && data.options) {
                if (data.columnType === 'multiSelect') {
                    if (data.columnValue && data.columnValue.length > 0) {
                        // data.columnValue = data.columnValue.map(d => d.code);
                        data.columnValue = data.columnValue.toString()
                    } else {
                        data.columnValue = null;
                    }
                    delete data.options;

                } else if (data.columnType === 'checkboxList') {
                    if (data.columnValue && data.columnValue.length > 0) {
                        data.columnValue = data.columnValue.toString();
                    }
                    delete data.options;
                } else {
                    data.columnValue = data.columnValue;
                    delete data.options;

                }
            } else if (data.columnType === 'chips') {
                data.columnValue = data.columnValue ? data.columnValue.toString() : '';
            } else if (data.columnType === 'onOff') {
                data.columnValue = data.columnValue ? "1" : "0"
            } else {
                data.columnValue = data.columnValue;
                if (data.columnType === 'number' && data.data_type === 'int') {
                    data.columnValue = data.columnValue ? Number(data.columnValue.replace(/[^0-9.-]+/g, "")) : 0;
                }
            }

        })
    });
}



export function getParamString1(listForms: any[]) {
    let object: any = {}
    listForms.forEach(ffields => {
        ffields.fields.forEach((field: any) => {
            object[field.field_name] = field.columnValue
        })
    });
    return object
 
}

 // end parseHtmlToMarkdown
 export function convesrtDate(value: string) {
    const cutString = value.split(' ');
    const stringDate = cutString[0].split('/');
    if(cutString.length > 1) {
      return `${stringDate[2]}-${stringDate[1]}-${stringDate[0]} ${cutString[1]}`
    }else {
      return `${stringDate[2]}-${stringDate[1]}-${stringDate[0]}`
    }
  }