<div class="section">
  <ag-grid-angular 
  [style.height]="domLayout === '' ? height + 'px' : ''"
  [excelStyles]="excelStyles"
  [getContextMenuItems]="getContextMenuItems"
   #agGrid style="width: 100% "
   id="{{idGrid}}"
   class="ag-theme-balham {{listsData.length > 0 ? '' : 'hidden_row'}}"
  [pinnedBottomRowData]="isShowTotalBottom ? pinnedBottomData : []"
  [debounceVerticalScrollbar]="true"
  [rowData]="listsData"
  [domLayout]="domLayout"
  [frameworkComponents]="frameworkComponents"
  [masterDetail]="masterDetail"
  [floatingFiltersHeight]="floatingFiltersHeight"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [columnDefs]="columnDefs"
  [detailCellRendererParams]="detailCellRendererParams"
  [headerHeight]="headerHeight"
  [groupDefaultExpanded]="groupDefaultExpanded"
  [stopEditingWhenCellsLoseFocus]="true" 
  [groupDisplayType]="'groupRows'"
  [isRowSelectable]="isRowSelectable"
  [overlayNoRowsTemplate]="noRowsTemplate"
  [tooltipShowDelay]="tooltipShowDelay"
  (gridReady)="onGridReady($event)"
  [defaultColDef]="defaultColDef"
  [enableCellTextSelection]="true"
  [suppressCopyRowsToClipboard]="true"
  [enableRangeSelection]="true"
  [enableRangeSelection]="true"
  (rowGroupOpened)="rowGroupOpened($event)"
  [rowSelection]="'multiple'"
  [suppressRowClickSelection]="true"
  [rowMultiSelectWithClick]="true"
  (cellClicked)="CellClicked($event)"
  (rowDoubleClicked)="RowDoubleClicked($event)"
  (cellDoubleClicked)="CellDoubleClicked($event)"
  [animateRows]= "true"
  [skipHeaderOnAutoSize]= "true"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [overlayLoadingTemplate]="overlayLoadingTemplate"
  (rowSelected)="onRowSelected($event)"
  (cellValueChanged)="onCellValueChanged($event)"
  [getRowHeight]="getRowHeight"
  [modules]="modules"
  (columnResized)="onColumnResized($event)"
  (bodyScroll)="handleScroll($event)"
  (onGridSizeChanged)="onGridSizeChanged"
  [getMainMenuItems]="getMainMenuItems"
  (firstDataRendered)="onFirstDataRendered($event)"
  >
  </ag-grid-angular>
</div>
<!-- [floatingFilter]="floatingFilter" -->

<!-- [getRowId]="getRowId" -->

<!-- (onGridSizeChanged)="onGridSizeChanged" -->

<!-- [groupIncludeFooter]="groupIncludeFooter" -->
<!-- [groupIncludeTotalFooter]="groupIncludeTotalFooter" -->