
<div class="app-body" >
  <div class="header" >
    <app-navbar></app-navbar>
  </div>
  <main class="d-flex">
    <aside>
      <app-sidebar></app-sidebar>
    </aside>
    <div class="content">
      <router-outlet>
      </router-outlet>
    </div>
  </main>
</div>

