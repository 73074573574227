<form [formGroup]="form" *ngIf="fields.length > 0" [class]="isFiler ? 'Filter': 'Form'">
    <!-- <span *ngIf="!hideButton" (click)="CauHinh()" class="pi pi-cog cursor-pointer setting" style="position: absolute;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
    top: 9px;
    z-index: 9999;
    right: 16px;"></span> -->


    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
    <div *ngIf="!hideButton" class="flex gap-2 buttons-detail">
        <!-- <p-button *ngIf="isExtend" label="{{'_Tính gia hạn_' | translate}}" styleClass="" icon="pi pi-money-bill"
        (click)="handleExtend()"></p-button> -->
        <ng-container *ngFor="let button of buttons">
            <p-button  (click)="onChangeButtonEdit(button.value)" label="{{translates.instant(button.label ? button.label: '')}}" [icon]="button.icon ? button.icon : ''" [disabled]="button?.disabled ? true : false" styleClass="{{button?.class}}"
              ></p-button>
        </ng-container>
        <p-button (click)="CauHinh()"
        styleClass="p-button-sm p-button-secondary">
        <span class="uni-icon uni-setting-transparent uni-white-color"></span>
        </p-button>
        <p-button  *ngIf="isFiler" label="{{'_Đóng_' | translate}}" styleClass="btn-trans ml-1 p-button-danger" icon="pi pi-times"
            (click)="cancel('Close')"></p-button>
    </div>
    <div *ngIf="hideButton" class="flex gap-2 pt-2 justify-content-end buttons-detail">
        <ng-container *ngFor="let button of buttons">
            <ng-container *ngIf="button.value !== 'CauHinh'">
                <p-button  (click)="onChangeButtonEdit(button.value)" [disabled]="button?.disabled ? true : false" styleClass="{{button?.class}}">
                <span *ngIf="button.icon" [class]="button.icon"></span>{{translates.instant(button.label ? button.label: '')}}</p-button>
            </ng-container>
            <ng-container *ngIf="button.value === 'CauHinh'">
                <p-button (click)="CauHinh()"
                styleClass="p-button-sm p-button-secondary">
                <span class="uni-icon uni-setting-transparent uni-white-color"></span>
            </p-button>
            </ng-container>
           
        </ng-container>
    </div>
</form>


<p-dialog header="{{'_Cài đặt cấu hình_' | translate }}" [appendTo]="'body'" [focusTrap]="false" styleClass="popup-setting"
    [(visible)]="displaySetting" (onHide)="cancel('CauHinh')" [modal]="true" [style]="{width: '50vw'}"
    [maximizable]="true" [draggable]="false" [resizable]="false">
    <app-config-grid-table-form *ngIf="displaySetting && gridKey" [typeConfig]="'FormInfo'"
        (callbackF)="callbackConfigGridTanle($event)" [gridKey]="gridKey"></app-config-grid-table-form>
</p-dialog>

<p-dialog [(visible)]="displaySetting1" styleClass="popup-config" [appendTo]="'body'" [header]="configFormGroup" [modal]="true"
    [style]="{width: '50vw', height: 'auto'}">
    <app-form-detail *ngIf="listViews.length> 0" [detailInfo]="detailInfoConfig" [isFiler]="false" [buttons]="buttons1"
        [listForms]="listViews" (callback)="setGroupInfo($event)" (callbackcancel)="quaylai($event)"></app-form-detail>
</p-dialog>
