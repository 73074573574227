import { stringToArray } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { of } from 'rxjs';
export interface OptionDropdown {
  label: string;
  value: string | number
}
@Component({
  selector: 'app-nz-dropdown',
  templateUrl: './nz-dropdown.component.html',
  styleUrls: ['./nz-dropdown.component.scss']
})
export class NzDropdownComponent  extends FieldType<FieldTypeConfig> implements OnInit{
  listOptions: any = []
  isFilter = false;
 
  ngOnInit(): void {
    const lists: any = this.field.props.options;
    // this.listOptions = lists;
    if(this.field.key === 'custId') {
      if(lists && lists.length > 0) {
        this.listOptions = lists.map((item: {name: string, value: string, isHost: boolean | null}) => {
          if(item.isHost == true) {
            return {
              name: item.name + ' - ' + 'Chủ hộ',
              value: item.value,
              isHost: item.isHost
            }
          } else {
            return {
              name: item.name,
              value: item.value,
              isHost: item.isHost
            }
          }
      })
      }
    } else {
      this.listOptions = lists;
    }
  }
}
