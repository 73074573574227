import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
@Component({
  selector: 'app-nz-input-currency',
  templateUrl: './nz-input-currency.component.html',
  styleUrls: ['./nz-input-currency.component.scss']
})
export class NzInputCurrencyComponent extends FieldType<FieldTypeConfig> implements OnInit{
  get type() {
    return this.props.type || 'text';
  }

  constructor(
  
  ) {
    super();
  }

  ngOnInit(): void {
  
  }
}
