import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { collection, doc, Firestore, getDoc, where, query, collectionData, orderBy, limit, addDoc, Timestamp, setDoc } from '@angular/fire/firestore';
import { ref, uploadBytesResumable, Storage, getDownloadURL } from '@angular/fire/storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { finalize, Subject, takeUntil } from 'rxjs';
import { Chats, Emoji, Last_Message, Messages } from 'src/app/models/chats.model';
import { ApiStorageService } from 'src/app/services/api-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChatApiService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy, OnChanges{
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input() dataChat: any
  userIdRequest: string = '';
  rooms: Chats[] = [];
  emojis: Emoji[] = [];
  usersChats: any[] = [];
  conversations: any[] = [];
  conversations_search: any[] = [];
  totaluserchat: number = 0;
  authState: any = null;
  userName: string = '';
  avatarUrl: string | null = null;
  roomName: string = '';
  iduser: string = '';
  message: string = '';
  idMessage: string = '';
  isShowLoadingChat: boolean = false;
  isShowSendFile: boolean = false;
  flagtyping: boolean = false;
  file: any = {};
  arrFile: Emoji[] = [];
  isShowEmoji: boolean = false;
  showListUserInRoom: boolean = false;
  bListSearchConverstion: boolean = false;
  constructor(
      private chatService: ChatApiService,
      private firestore: Firestore,
      private authService: AuthService,
      private storage: Storage,
      private apiStorageService: ApiStorageService,
      private spinner: NgxSpinnerService
  ) {
    
  }

  ngOnChanges() {
   
  }

  ngOnInit(): void {
    this.userName = this.authService.getUserName();
    if(this.authService.getUserImage() !== null) {
      this.avatarUrl = this.authService.getUserImage();
    }
    const userId = localStorage.getItem('userID');
    if (userId !== null) {
      this.iduser = userId as string;
      this.getRoom();
      this.getEmoji();
    }
  }

  getChatRoom() {
    this.bListSearchConverstion = false;
  }

  getRoom() {
    this.bListSearchConverstion = false;
    this.spinner.show();
    this.chatService.getRoom(this.iduser)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async results => {
      try {
          const rooms = results.map((item: any) => {
            return {
              id: item?.id,
              avatar: item?.avatar,
              created: moment(item?.last_message.time.toDate()).local().startOf('minute').fromNow(),
              created_by: item?.created_by,
              emailed: item?.emailed,
              last_message: item?.last_message,
              name: item?.name,
              ticket: item?.ticket,
              type: item?.type,
              unread: item?.unread, 
              active: false
            }
        })
      this.rooms = rooms;
      this.spinner.hide();
      } catch (error) {
        console.error(error);
        this.spinner.hide();
      }
    })
  }

  getUserInRoom(idMessage: string) {
   this.chatService.getUserInRoom(idMessage)
   .pipe(takeUntil(this.unsubscribe$))
   .subscribe((res)=> {
      this.usersChats = res;
      this.totaluserchat = res.length;
    })
  }

  getMessage(idMessage: string) {
    this.spinner.show();
    this.chatService.getMessage(idMessage)
    .pipe(takeUntil(this.unsubscribe$)) 
    .subscribe((res: any)=> {
        if(res && this.usersChats.length > 0) {
          this.conversations = res.map((item: any)=> this.initValueMessagesStart(item));
          this.conversations = this.sortByParcelNumber(this.conversations);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
    })
  }

  getDataMessagesSearch(value: string) {
    if(this.idMessage) {
      this.bListSearchConverstion = true
      this.conversations_search = [];
      this.chatService.getMessageSearch(this.idMessage, value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        this.conversations_search = results.map(item => this.initValueMessagesSearch(item));
      })
    }
  }

  initValueMessagesSearch(doc: any) {
    const messagesItem = new Messages();
    messagesItem.id = doc.id;
    messagesItem.details = doc.details || null;
    messagesItem.image = doc.image || '';
    messagesItem.type = doc.type || '';
    messagesItem.owner = doc.owner || '';
    if (messagesItem.owner !== '') {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.usersChats.length; i++) {
        if (messagesItem.owner === this.usersChats[i].id) {
          messagesItem.name = this.usersChats[i].name;
          messagesItem.avatar = this.usersChats[i].avatar;
          messagesItem.last_accessed = this.usersChats[i].last_accessed;
        }
      }
    }
    messagesItem.payload = doc.payload || '';
    messagesItem.summary =  doc.summary || '';
    messagesItem.status = doc.status || '';
    messagesItem.ticket_id = doc.ticket_id || '';
    messagesItem.title = doc.title || '';
    messagesItem.lat = doc.lat || '0';
    messagesItem.lng = doc.lng || '0';
    messagesItem.time = doc.time || null;
    if (messagesItem.time !== null) {
      messagesItem.time = messagesItem.time.toDate();
      messagesItem.timestring = moment(messagesItem.time).format('DD/MM/YYYY h:mm:ss a');
    }
    if (this.conversations.map(function (e) { return e.id; }).indexOf(messagesItem.id) < 0) {
      this.conversations.push(messagesItem);
    }
    return messagesItem;
  }

  getEmoji() {
   this.chatService.getEmoji()
   .pipe(takeUntil(this.unsubscribe$))
   .subscribe((res)=> {
      this.emojis = res.map((item: any)=> {
        return {
          id: item.id,
          name: item.name,
          url: item.url,
          size: '',
          type: 'emoji'
        }
      }) 
    })
  }

  gotoConversation(item: any) {

  }

  roomClick(id: any, namechat: any, roomChat: any) {
    this.roomName = namechat
    this.idMessage = id
    this.getUserInRoom(id)
    this.getMessage(id);
    this.isShowLoadingChat = true;
    this.conversations = [];
    this.rooms.forEach((item: any) => item.active = false)
    roomChat.active = true;
    setTimeout(() => {
      this.isShowLoadingChat = false;
      this.getMessage(id);
    }, 1000);
  }

  sendMessage() {
    if(this.message !== '') {
      const data = {
        delivery: {
        },
        avatar: this.avatarUrl,
        owner: this.iduser,
        payload: this.message,
        time: Timestamp.now(),
        type: 'text'
      }

      data.delivery[`${this.iduser}`] = {
        read: true,
        time: Timestamp.now()
      };

      const data2 = {
        name: this.userName ? this.userName : null,
        avatar: this.avatarUrl ? this.avatarUrl : null
      };

      this.chatService.updateUserChat(this.idMessage, 'users', this.iduser, data2)
      .then((res)=> {})
      .catch((error) => {
        console.error('Lỗi', error)
      })

      this.chatService.createMessageChat(this.idMessage, 'messages', data)
      .then((res)=> {})
      .catch((error) => {
        console.error('Lỗi message', error);
      })
      this.message = '';
    }

    if(this.arrFile.length > 0) {
      for (let i = 0; i < this.arrFile.length; i++) {
        let tmptype = 'file';
        if (this.arrFile[i].id.indexOf('image') > -1) {
          tmptype = 'image';
        }
        const datafile = {
          delivery: {
          },
          details: this.arrFile[i].id,
          image: this.arrFile[i].url,
          owner: this.iduser,
          payload: this.arrFile[i].name,
          summary: this.arrFile[i].size + '',
          time: Timestamp.now(),
          type: tmptype
        };
        datafile.delivery[`${this.iduser}`] = {
          read: true,
          time: Timestamp.now(),
        };
        this.chatService.createMessageChat( this.idMessage , 'messages', datafile).then((res) => {
          // this.handleAddSubCollection(dataAdd);
        })
          .catch((error) => {
            // this.notificationService.showNotification(CONSTANTS.MESSAGE_ALERT.ADD_FAIL, CONSTANTS.MESSAGE_TYPE.FAILED);
            console.log(error);
          });
        this.message = '';
      }
      this.arrFile = [];
      this.isShowSendFile = false;
    }
  }

  onUploadOutput(event: any) {
    if (event.target.files[0] && event.target.files[0].size > 0) {
      this.isShowSendFile = true;
      this.file = event.target.files[0];
      const getDAte = new Date();
      const getTime = getDAte.getTime();
      const filenameupload = event.target.files[0].name;
      const tenfileupload = `${getTime}-${filenameupload}`;
      const storageRef = ref(this.storage, `threads/${ this.iduser }/${tenfileupload}`)
      const uploadTask = uploadBytesResumable(storageRef, this.file);
      uploadTask.on('state_changed', (snapshot: any) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      }, (error) => {
        
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL)=> {   
          const emojiItem = new Emoji();
          let tmptype = 'file';
          if (event.target.files[0].type.indexOf('image') > -1) {
            tmptype = 'image';
          }
          emojiItem.id = event.target.files[0].type;
          emojiItem.size = event.target.files[0].size;
          emojiItem.name = tenfileupload;
          emojiItem.url = downloadURL;
          emojiItem.type = tmptype;
          this.arrFile.push(emojiItem);
        })
      });
    }
  }

  deleteAvatar(url: string) {
    for (let i = 0; i < this.arrFile.length; i++) {
      if (this.arrFile[i].url === url) {
        let pathfile = '';
        let pathfile_thumb = '';
        pathfile = '/threads/' + this.iduser + '/' + this.arrFile[i].name;
        pathfile_thumb = '/threads/' + this.iduser + '/thumbnails/' + this.arrFile[i].name;
        if (this.arrFile[i].type === 'image') {
          this.apiStorageService.deletefileStorage(pathfile).then((res) => { }).catch((error) => console.log(error));
          this.apiStorageService.deletefileThumbsStorage(pathfile_thumb).then(() => { }).catch((error) => console.log(error));

        } else {
          this.apiStorageService.deletefileStorage(pathfile).then(() => { }).catch((error) => console.log(error));
        }
        break;
      }
    }
    this.arrFile = this.arrFile.filter(t => t.url !== url);
  }

  eventHandler(event: any) {

  }

  onChangeInput(event: any) {
    if(this.message !== '') {
      this.flagtyping = true;
    } else {
      this.flagtyping = false;
    }
  }

  sendMessageEmoji(url: string) {
    const data = {
      delivery: {
      },
      details: null,
      image: url,
      owner: this.iduser,
      payload: null,
      summary: null,
      time: Timestamp.now(),
      type: 'sticker'
    };

    data.delivery[`${this.iduser}`] = {
      read: true,
      time: Timestamp.now() 
    };
    const mainDocumentReference = doc(this.firestore, 'threads', this.idMessage)
    const childCollectionReference = collection(mainDocumentReference, 'messages');
    addDoc(childCollectionReference, data).then((res) => {
    })
  } 

  showListUser() {
    this.showListUserInRoom = true;
  }

  onDeleteUserInRoom(idUser: string) {
    
  }

  initValueMessagesStart(doc: any) {
    const messagesItem = new Messages();
    messagesItem.id = doc.id;
    messagesItem.details = doc.details;
    messagesItem.image = doc.image;
    messagesItem.type = doc.type;
    messagesItem.owner = doc.owner;
    if (messagesItem.owner !== '') {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.usersChats.length; i++) {
        if (messagesItem.owner === this.usersChats[i].id) {
          messagesItem.name = this.usersChats[i].name;
          messagesItem.avatar = this.usersChats[i].avatar;
          messagesItem.last_accessed = this.usersChats[i].last_accessed;
        }
      }
    }
    messagesItem.payload = doc.payload;
    messagesItem.summary =  doc.summary;
    messagesItem.status = doc.status;
    messagesItem.ticket_id = doc.ticket_id;
    messagesItem.title = doc.title;
    messagesItem.lat = doc.lat;
    messagesItem.lng = doc.lng;
    messagesItem.time = doc.time;
    if (messagesItem.time !== null) {
      messagesItem.time = messagesItem.time.toDate();
      messagesItem.timestring = moment(messagesItem.time).format('DD/MM/YYYY h:mm:ss a');
    }
    if (this.conversations.map(function (e) { return e.id; }).indexOf(messagesItem.id) < 0) {
      this.conversations.push(messagesItem);
    }
    return messagesItem;
  }

  initValueRoomStart(doc: any) {
    const room = new Chats();
    room.id = doc.id;
    room.avatar = doc.avatar;
    room.emailed = doc.emailed;
    room.type = doc.type;
    room.name = doc.name;
    room.unread = doc.unread;
    room.created = doc.created;
    if (room.created !== null) {
      // tslint:disable-next-line:prefer-for-of
      room.created = doc.created.toDate();
      room.created = moment(room.created).format('DD/MM/YYYY h:mm:ss a')
    }
    const last_message = doc.last_message
    if(last_message !== null) {
      const lastmessage = new Last_Message();
      lastmessage.id =  doc.last_message.id;
      lastmessage.title =  doc.last_message.title;
      lastmessage.payload =  doc.last_message.payload;
      lastmessage.type =  doc.last_message.type;
      lastmessage.user =  doc.last_message.user;
      lastmessage.time =  doc.last_message.time;
      if(lastmessage.time !== null) {
        lastmessage.time =  doc.last_message.time.toDate();
        lastmessage.time =  moment(lastmessage.time).local().startOf('minute').fromNow();
      }
      room.created = lastmessage.time
      room.last_message.push(lastmessage)
    }
    return room
  }

  sortByParcelNumber(arr: any) {
    arr.sort((a: any, b: any) => {
      if (a.time < b.time) {
        return -1;
      } else if (a.time > b.time) {
        return 1;
      } else {
        return 0;
      }
    });
    return arr;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
