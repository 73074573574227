import { Component, EventEmitter, inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BButton } from './buttons.mode';
import { TYPELINKURL, TYPES, TYPESAUTOCOMPLETE, TYPESCHECKBOX, TYPESCURRENCY, TYPESDATETIME, TYPESDECIMAL, TYPESDROPDOWN, TYPESIMAGE, TYPESINPUT, TYPESINPUTEDIT, TYPESINPUTSEARCH, TYPESMARKDOWN, TYPESMULTISELECT, TYPESTEXTAREA, TYPESTIME, TYPETREESELECT, TYPEUPLOADEDFILE } from './columnTypes';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FFields, LListViews } from 'src/app/models/demo';
import { PanelWrapperComponent } from '../formLy/panel-wapper';
import { forkJoin, of, Subject, takeUntil } from 'rxjs';
import { cloneDeep } from 'lodash';
import { AppApiService } from 'src/app/services/app.service';
import queryString from 'query-string';
import { NgxSpinnerService } from 'ngx-spinner';
import * as numeral from 'numeral';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { convesrtDate } from './funtions';
@Component({
  selector: 'app-form-detail',
  templateUrl: './form-detail.component.html',
  styleUrls: ['./form-detail.component.scss']
})
export class FormDetailComponent implements OnInit {
  @Input() hideButton: boolean = false;
  private _messageService = inject(MessageService);
  public translates = inject(TranslateService);
  private _spinner = inject(NgxSpinnerService)
  private _appApi = inject(AppApiService);
  @Input() buttons: BButton[] = [];
  @Input() detailInfo: any = null;
  @Input() listForms: any[] = [];
  @Input() isFiler: boolean = false;
  @Input() isExtend: boolean = false;
  @Input() apartmentId: string | null = '';
  @Output() callback = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() reset = new EventEmitter<any>();
  @Output() endDate = new EventEmitter<any>();
  @Output() changeCardType = new EventEmitter<any>();
  @Output() changeVehicleCheckbox = new EventEmitter<any>();
  @Output() changeValueToNum = new EventEmitter<any>();
  @Output() changeValueFromNum = new EventEmitter<any>();
  @Output() changeValueFilterDate = new EventEmitter<any>();
  @Output() changeValueApartmentCd = new EventEmitter<any>();
  @Output() changeValueProjectCd = new EventEmitter<any>();
  @Output() changeValueBuildingCd = new EventEmitter<any>();
  @Output() changeValueIsAllProject = new EventEmitter<any>();
  @Output() changeValueIsDateFilter = new EventEmitter<any>();
  @Output() changeValueIsDateFilter2 = new EventEmitter<any>();
  @Output() changeValueIsBill = new EventEmitter<any>();
  @Output() changeValueIsPush = new EventEmitter<any>();
  @Output() callbackReload = new EventEmitter<any>();
  @Output() callbackExtend = new EventEmitter<any>();
  public modelFields: any = {};
  public includeImage: boolean = false;
  public isSubmit: boolean = false;
  form = new FormGroup({});
  model: any = {};
  buttons1: any = [
    // { label: '_Bỏ qua_', value: 'Cancel', class: 'p-button p-button-secondary', icon: 'pi pi-times-circle' },
    { label: '_Lưu lại_', value: 'Update', class: '' }
  ];
  public options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: true,
      submitted: false,
      tests: [
        { name: 'dsd', value: 1 }
      ]
    },
  };
  fields: FormlyFieldConfig[] = [];
  configFormGroup = 'Cấu hình Form Group';
  constructor() {
  }


  ngOnInit() {
    if (this.buttons.length === 0) {
      this.translates.get(['_Hủy_', '_Lưu_']).subscribe(translations => {
        this.buttons = [
          // { label: translations['_Hủy_'], value: 'Cancel', class: 'p-button huy', icon: '', disabled: false },
          { label: translations['_Lưu_'], value: 'Update', class: '', icon: 'pi pi-check', disabled: false }
        ]
      })
    }
    this.submitMaterial();
    this.callApiDropdow();
    this.configFormGroup = this.translates.instant("_config_form_group_");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listForms'] && this.listForms) {
      this.submitMaterial();
    }
  }

  callApiDropdow() {
    const datas = cloneDeep(this.fields);
    const promissall: any[] = [];
    this.listForms.forEach(ffields => {
      ffields.fields.forEach((ffield: any) => {
        if (TYPES.indexOf(ffield.columnType) > -1 && ffield.columnObject) {
          if (TYPESAUTOCOMPLETE.indexOf(ffield.columnType) > -1) {
            ffield.columnObject = ffield.columnObject + ffield.columnValue;
            if (ffield.columnValue) {
              promissall.push(this._appApi.getCustObjectList(ffield.columnObject, `${ffield.columnType}?${ffield.field_name}`));
            } else {
              const columnObject = ffield.columnObject.split('?');
              const params1: any = columnObject.length > 1 ? columnObject[1].split('&') : [];
              const indexparams1 = params1.findIndex((d: any) => d.includes('filter='));
              if (indexparams1 < 0) {
                params1.push('filter=@@');
                ffield.columnObject = columnObject[0] + `?${params1.join('&')}`;
              }
              if (ffield.columnType === 'autocomplete') {
                // promissall.push(this._appApi.getAutocompleteLinkApiV2(ffield.columnObject
                //   , `${ffield.field_name}${ffield.group_cd}`));
              
              } else {
              
                // promissall.push(this._appApi.getAutocompleteLinkApiV2s(ffield.columnObject
                //   , `${ffield.field_name}${ffield.group_cd}`));
              }
            }
          } else if(ffield.columnObject && ffield.field_name === 'CustId' || ffield.columnObject && ffield.field_name === 'custId' ) {
            promissall.push(this._appApi.getCustObjectList(ffield.columnObject + this.apartmentId, `${ffield.field_name}${ffield.group_cd}`));
          }
          else {
            promissall.push(this._appApi.getCustObjectList(ffield.columnObject, `${ffield.field_name}${ffield.group_cd}`));
          }
        }
      })
    });
    console.log("this.fields", this.fields)
    if (promissall.length > 0) {
      forkJoin(promissall.filter(d => d !== undefined))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((results: any) => {
          this.fields = [];
          const responses = results.filter((d: any) => d !== undefined);
          responses.forEach((item: any) => {
            const types = item.key.split('?');
            if (types[0] === 'autocomplete') {
              this.model[types[1]] = item.result.length > 0 ? item.result[0] : null;
            } else {
              if (item) this.options.formState[item.key] = item.result;
            }
          });
          this.fields = cloneDeep(datas);
        })
    }
  }

  private readonly unsubscribe$: Subject<void> = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submitMaterial() {
    const lisst: any[] = []
    this.listForms.forEach(ffields => {
      lisst.push(
        {
          fieldGroupClassName: 'grid',
          className: ffields.group_column,
          fieldGroup: [{
            fieldGroupClassName: 'grid',
            className: 'col-12',
            wrappers: [PanelWrapperComponent],
            props: {
              label: ffields.group_name,
            },
            fieldGroup: [...this.forChid(ffields)]
          }]
        }
      )

    });
    this.fields = [
      {
        fieldGroupClassName: 'grid',
        fieldGroup: lisst
      }];
  }

  forChid(ffields: any): any {
    const newFields: any[] = ffields.fields.map((ffield: FFields) => {
      return {
        key: ffield.field_name,
        defaultValue: this.setValueForm(ffield),
        type: ffield.columnType && TYPESINPUT.indexOf(ffield.columnType) > -1 ? 'nzInput'
          : ffield.columnType && TYPESDECIMAL.indexOf(ffield.columnType) > -1 ? 'nzInputNumber'
           : ffield.columnType && TYPESCURRENCY.indexOf(ffield.columnType) > -1 ? 'nzInputCurrency'
            : ffield.columnType && TYPESDROPDOWN.indexOf(ffield.columnType) > -1 ? 'nzDropdown'
              : ffield.columnType && TYPESTEXTAREA.indexOf(ffield.columnType) > -1 ? 'nzTextarea'
                : ffield.columnType && TYPESCHECKBOX.indexOf(ffield.columnType) > -1 ? 'nzCheckbox'
                  : ffield.columnType && TYPESDATETIME.indexOf(ffield.columnType) > -1 ? 'nzDateTime'
                    : ffield.columnType && TYPESAUTOCOMPLETE.indexOf(ffield.columnType) > -1 ? 'nzAutocomplete'
                      : ffield.columnType && TYPETREESELECT.indexOf(ffield.columnType) > -1 ? 'nzTreeSelect'
                        : ffield.columnType && TYPESMULTISELECT.indexOf(ffield.columnType) > -1 ? 'nzMultiSelect'
                          : ffield.columnType && TYPESIMAGE.indexOf(ffield.columnType) > -1 ? 'nzImage'
                            : ffield.columnType && TYPELINKURL.indexOf(ffield.columnType) > -1 ? 'nzLinkUrl'
                              : ffield.columnType && TYPESTIME.indexOf(ffield.columnType) > -1 ? 'nzTime'
                                : ffield.columnType && TYPESMARKDOWN.indexOf(ffield.columnType) > -1 ? 'nzMarkDown'
                                 : ffield.columnType && TYPESINPUTEDIT.indexOf(ffield.columnType) > -1 ? 'nzInputEdit'
                                  : ffield.columnType && TYPESINPUTSEARCH.indexOf(ffield.columnType) > -1 ? 'nzInputSearch'
                                   : ffield.columnType && TYPEUPLOADEDFILE.indexOf(ffield.columnType) > -1 ? 'nzUploaded'
                                    : '',
        className: `${ffield.columnClass} ${ffield.isEmpty ? 'isVisibility' : ''}`,
        hide: !ffield.isVisiable && !ffield.isEmpty,
        expressionProperties: {
          'props.options': `formState.${ffield.field_name}${ffield.group_cd}`,
          'props.results': `formState.${ffield.field_name}${ffield.group_cd}`
        },
        props: {
          label: ffield.columnLabel,
          placeholder: ffield.columnLabel,
          required: ffield.isVisiable && !ffield.isEmpty ? ffield.isRequire : false,
          disabled: ffield.isDisable,
          type: ffield.data_type,
          columnType: ffield.columnType,
          group_cd: ffield.group_cd,
          columnObject: ffield.columnObject,
          searchObject: (to: any, event: any, field: any) => {
            if (to.columnObject && event.query) {
              const apis = to.columnObject.split("?");
              const params = apis[0].toString() + `?filter=${event.query}`;
              this._appApi.getCustObjectList(params, to.key).subscribe((results: any) => {
                if (results.result.length > 0) {
                  to.results = results.result;
                } else {
                  to.results = [];
                }
              })
            }
          },
          valueProp: "value",
          labelProp: 'name',
          // options: this.options.formState[`${ffield.field_name}${ffield.group_cd}`],
          change: this.onChangeField.bind(this),
        },
      }
    });
    return newFields
  }

  setValueForm(ffield: FFields) {
    if (TYPESDATETIME.indexOf(ffield.columnType) > -1) {
      return ffield.columnValue ? new Date(this.convesrtDate(ffield.columnValue)) : ''
    } else if (TYPESDECIMAL.indexOf(ffield.columnType) > -1) {
      return ffield.columnValue ? numeral(ffield.columnValue).value() : 0
    } else if (TYPESCURRENCY.indexOf(ffield.columnType) > -1) {
      return ffield.columnValue ? numeral(ffield.columnValue).value() : 0
    } else if (TYPESMULTISELECT.indexOf(ffield.columnType) > -1) {
      return ffield.columnValue ? ffield.columnValue.split(',') : []
    } else if (TYPESTIME.indexOf(ffield.columnType) > -1) {
      return ffield.columnValue ? moment(new Date(convesrtDate(ffield.columnValue))).format('HH:mm') : '00:00'
    } else if (TYPESAUTOCOMPLETE.indexOf(ffield.columnType) > -1) {
      return ffield.columnValue;
    } else {
      return ffield.columnValue == 'true' ? true : ffield.columnValue == 'false' ? false : ffield.columnValue
    }
  }

  convesrtDate(value: any) {
    if (value instanceof Date && !isNaN(value.valueOf())) {
      return moment(new Date(value)).format('YYYY-MM-DD')
    } else {
      const cutString = value.split(' ');
      const stringDate = cutString[0].split('/');
      if (cutString.length > 1) {
        return `${stringDate[2]}-${stringDate[1]}-${stringDate[0]} ${cutString[1]}`
      } else {
        return `${stringDate[2]}-${stringDate[1]}-${stringDate[0]}`
      }
    }
  }

  onChangeField(field: FormlyFieldConfig, event: any) {
    const fieldItem: FFields = this.getValueByKey(field.key);
    if(fieldItem && fieldItem.columnDisplay) {
      const fieldsString = fieldItem.columnDisplay.split(",");
      if (TYPESAUTOCOMPLETE.indexOf(fieldItem.columnType) > -1) {
        this.getOptionsFieldAutocomplete(fieldItem, fieldsString, field, event);
      } else {
        this.getOptionsField(fieldItem, fieldsString, field);
      }
    } if(fieldItem && fieldItem.field_name === 'EndDate') {
        this.endDate.emit(event)
    } if(fieldItem && fieldItem.field_name === 'CardTypeId') {
      this.changeCardType.emit(event);
    } if(fieldItem && fieldItem.field_name === 'IsVehicle') {
      this.changeVehicleCheckbox.emit(event);
    } if(fieldItem && fieldItem.field_name === 'ToNum') {
      this.changeValueToNum.emit(event);
    } if(fieldItem && fieldItem.field_name === 'FromNum') {
      this.changeValueFromNum.emit(event);
    } if(fieldItem && fieldItem.field_name === 'isFilterDate') {
      this.changeValueFilterDate.emit(event);
    } if(fieldItem && fieldItem.field_name === 'ApartmentCd') {
      this.changeValueApartmentCd.emit(event);
    } if(fieldItem && fieldItem.field_name === 'ProjectCd') {
      this.changeValueProjectCd.emit(event);
    } if(fieldItem && fieldItem.field_name === 'BuildingCd') {
      this.changeValueBuildingCd.emit(event);
    } if(fieldItem && fieldItem.field_name === 'IsDateFilter') {
      this.changeValueIsDateFilter.emit(event);
    } if(fieldItem && fieldItem.field_name === 'IsBill') {
      this.changeValueIsBill.emit(event);
    } if(fieldItem && fieldItem.field_name === 'IsPush') {
      this.changeValueIsPush.emit(event);
    } if(fieldItem && fieldItem.field_name === 'isDateFilter') {
      this.changeValueIsDateFilter2.emit(event);
    } if(fieldItem && fieldItem.field_name === 'isAllProject') {
      this.changeValueIsAllProject.emit(event);
    }
    if(fieldItem && fieldItem.isSpecial) {
      this.options.formState.submitted = false;
      this.callbackReload.emit({
        forms: this.listForms,
        actions: 'callbackForm',
        item: fieldItem.field_name,
        value: event
      });
    }
  }

  getOptionsFieldAutocomplete(itemField: FFields, fieldsString: any[], field: any, event: any) {
    if (itemField) {
      const promissall: any[] = [];
      this.listForms.forEach(ffields => {
        ffields.fields.forEach((ffield: any) => {
          if (ffield.field_name === field.key) {
            ffield.columnValue = typeof field.formControl?.value === 'object' ? field.formControl?.value?.value : field.formControl?.value;
            const params = ffield.columnObject.split('?');
            const params1 = params[1].split('&');
            const indexparams1 = params1.filter((d: any) => !d.includes(`Oid=`));
            indexparams1.push(`Oid=${ffield.columnValue}`);
            ffield.columnObject = params[0] + `?${indexparams1.join('&')}`;
          } else if (fieldsString.indexOf(`${ffield.field_name}`) > -1 && ffield.columnObject) {
            const columnValue1 = typeof field.formControl?.value === 'object' ? field.formControl?.value?.value : field.formControl?.value;
            const params = ffield.columnObject.split('?');
            const params1 = params[1].split('&');
            const indexparams1 = params1.filter((d: any) => !d.includes(`OidDisplay=`));
            indexparams1.push(`OidDisplay=${columnValue1}`);
            ffield.columnObject = params[0] + `?${indexparams1.join('&')}`;
            // promissall.push(this._appApi.getCustObjectList(ffield.columnObject, `${ffield.field_name}${ffield.group_cd}`));
          }
        });
      });
      setTimeout(() => {
        this.callApiDropdow();
      }, 200);
      // if (promissall.length > 0) {
      //   forkJoin(promissall.filter(d => d !== undefined))
      //     .pipe(takeUntil(this.unsubscribe$))
      //     .subscribe((results: any) => {
      //       const responses = results.filter((d: any) => d !== undefined);
      //       responses.forEach((item: any) => {
      //         this.options.formState[item.key] = item.result;
      //       });
      //       this.callApiDropdow();
      //     });
      // }
    }
  }

  getOptionsField(itemField: FFields, fieldsString: any[], field: FormlyFieldConfig) {
    if (itemField) {
      const promissall: any[] = [];
      this.listForms.forEach(ffields => {
        ffields.fields.forEach((ffield: any) => {
          if (fieldsString.indexOf(`${ffield.field_name}`) > -1 && ffield.columnObject) {
            const params = ffield.columnObject.split("?");
            let params1 = params[1].split("&");
            const indexparams1 = params1.filter((d: any) => !d.includes(`${itemField.field_name}=`));
            indexparams1.push(`${itemField.field_name}=${field.formControl?.value}`);
            ffield.columnObject = params[0] + `?${indexparams1.join("&")}`
            promissall.push(this._appApi.getCustObjectList(ffield.columnObject, `${ffield.field_name}${ffield.group_cd}`))
          }
        })
      });

      if (promissall.length > 0) {
        const datas = cloneDeep(this.fields);
        this.fields = [];
        forkJoin(promissall.filter(d => d !== undefined))
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((results: any) => {
            const responses = results.filter((d: any) => d !== undefined);
            responses.forEach((item: any) => {
              this.options.formState[item.key] = item.result;
            });
            this.fields = cloneDeep(datas);
          })
      }
    }


  }

  getValueByKey(key: any): any {
    if (this.listForms && this.listForms.length > 0) {
      let value = {}
      for (let i = 0; i < this.listForms.length; i++) {
        for (let j = 0; j < this.listForms[i].fields.length; j++) {
          if (this.listForms[i].fields[j].field_name === key) {
            value = this.listForms[i].fields[j];
            break;
          }
        }
      }
      return value
    }
    return null
  }

  onChangeButtonEdit(action: string | undefined) {
    this.options.formState.submitted = true;
    if (this.form.valid) {
      const object: any = this.form.getRawValue();
      const objectKeys = Object.keys(object);
      this.listForms.forEach(ffields => {
        ffields.fields.forEach((ffield: any) => {
          if (objectKeys.indexOf(ffield.field_name) > -1) {
            if (ffield.columnType === 'datetime') {
              ffield.columnValue = moment(object[ffield.field_name]).format('DD/MM/YYYY');
            } else  if (ffield.columnType === 'time') {
              let isDate = moment(object[ffield.field_name]).isValid();
              if(!isDate) {
                ffield.columnValue = object[ffield.field_name]
              }else{
                ffield.columnValue =  moment(object[ffield.field_name]).format('hh:mm'); 
              }
            } else if(TYPESAUTOCOMPLETE.indexOf(ffield.columnType) > -1) {
              ffield.columnValue = object[ffield.field_name]?.value;
            } else if (TYPESMULTISELECT.indexOf(ffield.columnType) > -1) {
              ffield.columnValue = object[ffield.field_name].length > 0 ? object[ffield.field_name].toString() : null;
            } else {
              ffield.columnValue = object[ffield.field_name] || null;
            }
          }
        })
      });
      this.options.formState.submitted = false;
      const params: any = {}
      for (let key in this.model) {
        console.log(this.model[key])
        if (this.model[key] instanceof Date && !isNaN(this.model[key].valueOf())) {
          params[key] = moment(new Date(this.model[key])).format('DD/MM/YYYY')
        } else if (Array.isArray(this.model[key])) {
          params[key] = this.model[key].toString();
        }else if(this.model[key] && this.model[key].hasOwnProperty('value')) {
          params[key] = this.model[key].value;
        } else {
          params[key] = this.model[key]
        }
      }
      this.callback.emit({
        forms: this.listForms,
        model: params,
        actions: action
      })
    }
    if (action) {
      if (action === 'Cancel') {
        this.close.emit(action);
      } else if (action === 'Reset') {
        this.reset.emit(action)
      } else if (action === 'CauHinh') {
        this.CauHinh()
      }
    }
  }

  cancel(type: string) {
    this.close.emit({ event: type, datas: this.listForms });
    this.displaySetting = false;
  }

  // cấu hình
  gridKey: string = '';
  group_cd: string = '';
  listViews: any[] = [];
  detailInfoConfig: any = null;
  displaySetting1: boolean = false;
  displaySetting: boolean = false;
  CauHinh() {
    this.gridKey = this.detailInfo.tableKey
    this.displaySetting = true;
  }

  callbackConfigGridTanle(event: any) {
    this.group_cd = event;
    this.getGroupInfo();
  }

  getGroupInfo() {
    this.listViews = [];
    const queryParams = queryString.stringify({ group_key: this.detailInfo.groupKey, group_cd: this.group_cd });
    this._appApi.getGroupInfo(queryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        if (results.status === 'success') {
          const listViews = cloneDeep(results.data.group_fields);
          this.listViews = [...listViews];
          this.detailInfoConfig = results.data;
          this.displaySetting1 = true;
        }
      });
  }

  setGroupInfo(data: any) {
    this._spinner.show();
    const params = {
      ...this.detailInfoConfig, group_fields: data.forms
    }
    this._appApi.setGroupInfo(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any) => {
        if (results.status === 'success') {
          this._messageService.add({ severity: 'success', summary: 'Thông báo', detail: results.message });
          this._spinner.hide();
          this.displaySetting1 = false;
          // this.load();
        } else {
          this._messageService.add({
            severity: 'error', summary: 'Thông báo',
            detail: results.message
          });
          this._spinner.hide();
        }
      })
  }

  quaylai(data: any) {
    this.displaySetting1 = false;
  }

  // handleExtend() {
  //   this.callbackExtend.emit();
  // }

}
