import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppApiService } from 'src/app/services/app.service';
import { AgGridFn, AgGridFnEdit } from '../funtions/funtion';
import queryString from 'query-string';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { forkJoin } from 'rxjs';
const MAX_SIZE = 100000000;

@Component({
  selector: 'app-config-grid-table-form',
  templateUrl: './config-grid-table-form.component.html',
  styleUrls: ['./config-grid-table-form.component.scss']
})
export class ConfigGridTableFormComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private apiService: AppApiService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private changeDetector: ChangeDetectorRef,
    private messageService: MessageService,
    private translate: TranslateService,
    private router: Router) {

    const routersss: any = this.route.data;
    this.dataRouter = routersss['_value'];
  }
  dataRouter: any;
  @Input() gridKey: string = '';
  @Output() callbackF = new EventEmitter<any>();
  @Input() typeConfig: string = '';
  pagingComponent = {
    total: 0
  };
  public agGridFn = AgGridFn;
  cols: any[] = [];
  colsDetail: any[] = [];
  columnDefs: any = [];
  detailCellRendererParams: any = null;
  gridflexs: any;
  query: any = {
    filter: '',
    gridWidth: 0,
    offSet: 0,
    pageSize: 10000000,
    gridKey: '',
    tableKey: ''
  };
  first = 0;
  countRecord: any = {
    totalRecord: 0,
    currentRecordStart: 0,
    currentRecordEnd: 0
  };
  loadjs = 0;
  listsData = [];
  listsDataCloone = [];
  displaySetting = false;
  load() {
    this.columnDefs = [];
    this.spinner.show();
    const params = {...this.query};
    if (this.typeConfig === 'FormInfo') {
      params.tableKey = params.gridKey;
      delete params.gridKey;
    } else {
      params.gridKey = params.gridKey;
      delete params.tableKey;
    }
    const queryParams = queryString.stringify(params);
    this.apiService.getGridViewPage(this.typeConfig === 'FormInfo' ? 'GetFormViewPage' : 'GetGridViewPage', queryParams).subscribe(
      (results: any) => {
        this.listsData = results.data.dataList.data.map((d: any) => {
          return {
            ...d, error : false
          };
        });
        this.listsDataCloone = cloneDeep(this.listsData);
        if (this.query.offSet === 0) {
          this.cols = results.data.gridflexs;
          this.colsDetail = results.data.gridflexdetails ? results.data.gridflexdetails : [];
        }
        this.initGrid();
        this.countRecord.totalRecord = results.data.dataList.recordsTotal;
        this.countRecord.currentRecordStart = this.query.offSet + 1;
        if ((results.data.dataList.recordsTotal - this.query.offSet) > this.query.pageSize) {
          this.countRecord.currentRecordEnd = this.query.offSet + Number(this.query.pageSize);
        } else {
          this.countRecord.currentRecordEnd = results.data.dataList.recordsTotal;
        }
        this.spinner.hide();
      });
  }
  cauhinh() {
    this.displaySetting = true;
  }

  Save() {
    if (this.typeConfig === 'FormInfo') {
      this.callApiForm();
    } else {
      this.callApi();
    }
  }

  callApiForm() {
    this.setGridViewInfo();
  }

  callApi() {
    this.setGridViewInfo();
  }

  setGridViewInfo() {
    if (this.listsData.length > 0) {
      const newListsData = cloneDeep(this.listsData.filter((item: any) => item.isChange));
      const listApis: any = [];
      for (const item of newListsData) {
        const params: any = item;
        params.isDisable = params.isDisable ? 1 : 0,
        params.isEmpty = params.isEmpty ? 1 : 0,
        params.isIgnore = params.isIgnore ? 1 : 0,
        params.isRequire = params.isRequire ? 1 : 0,
        params.isSpecial = params.isSpecial ? 1 : 0,
        params.isVisiable = params.isVisiable ? 1 : 0,
        params.isVisiable = params.isVisiable ? 1 : 0,
        listApis.push(this.apiService.setGridViewInfo(this.typeConfig === 'FormInfo' ? 'SetFormViewInfo' : 'SetGridViewInfo', params));
      }
      if (listApis.length > 0) {
        this.spinner.show();
        forkJoin(listApis).subscribe(results => {
          this.messageService.add({ severity: 'success'
            , summary: this.translate.instant('_Thông báo_')
            , detail:  this.translate.instant('_Cập nhật thành công_') });
          this.spinner.hide();
          this.load();
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: 'Không có bản ghi nào thay đổi !'});
      }

    }

  }


  showButtons(event: any) {
    return {
      buttons: [
        {
          onClick: this.chitietgroup.bind(this),
          label: 'Group',
          icon: 'pi pi-eye',
          class: 'btn-primary mr5',
        },
        {
          onClick: this.Delete.bind(this),
          label: this.translate.instant('_Xóa_'),
          icon: 'pi pi-trash',
          class: 'btn-primary mr5',
        },

      ]
    };
  }

  chitietgroup(event: any) {
    this.callbackF.emit(event.rowData.group_cd);
  }

  initGrid() {
    this.columnDefs = [
      ...AgGridFnEdit(this.cols.filter((d: any) => !d.isHide)),
      {
        headerName: this.translate.instant('_action_'),
        filter: '',
        maxWidth: 110,
        pinned: 'right',
        cellRenderer: 'buttonAgGridComponent',
        cellClass: ['border-right', 'no-auto'],
        cellRendererParams: (params: any) => this.showButtons(params),
        checkboxSelection: false,
        field: 'checkbox'
      }];
  }

  Delete(event: any) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn thực hiện xóa bản ghi này?',
      accept: () => {
        let queryParams = null;
        if (this.typeConfig === 'FormInfo') {
          queryParams = queryString.stringify({ fieldId: event.rowData.id });
        } else {
          queryParams = queryString.stringify({ gridId: event.rowData.id });
        }
        // this.apiService.delFormViewInfo(this.typeConfig === 'FormInfo' ? 'DelFormViewInfo' : 'DelGridViewInfo', queryParams)
        // .subscribe(results => {
        //   if (results.status === 'success') {
        //     this.messageService.add({ severity: 'success'
        //     , summary: 'Thông báo'
        //     , detail: results.data ? results.data : 'Xóa công ty thành công' });
        //     this.load();
        //   } else {
        //     this.messageService.add({ severity: 'error', summary: 'Thông báo', detail: results ? results.message : null });
        //   }
        // });
      }
    });
  }

  doubleClicked(event: any) {
    this.load();
  }

  find() {
    this.load();
  }

  ngOnInit() {
    this.query.gridKey = this.gridKey;
    this.load();
  }
}




