import { Component, HostListener, inject, OnInit } from '@angular/core';
import { Event, RouterEvent, Router, NavigationEnd } from '@angular/router';
import queryString from 'query-string';
import { filter } from 'rxjs';
import { MenuItem } from './sidebar.metadata';
import { AppApiService } from 'src/app/services/app.service';
import { MenuItem as MenuItem1 } from 'primeng/api';
import { cloneDeep, includes, pullAt, replace } from 'lodash'
import { menu } from './sidebar-routes.config';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
// import { menus } from './sidebar-routes.config';
declare var $: any;
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    private _apiService = inject(AppApiService);

    menulist1: any[] = [];
    menulist2: any[] = [];
    // menuItems: MenuItem[] = [];
    menuItems: any[] = [];
    screenWidth: number = 0;
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
        this.screenWidth = window.innerWidth;
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((e: any) => {
            const pathname = window.location.pathname;
            if (this.menuItems.length > 0) {
                this.menuItems = cloneDeep(this.menuItems);
                // this.fnCutArray(e.url, cloneDeep(this.menuItems));
                localStorage.setItem('menuItems', JSON.stringify(this.menuItems));
            } else {
                this.getListMenuByUserId(pathname)
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        const pathname = window.location.pathname;
        this.screenWidth = window.innerWidth;
        this.menuItems = cloneDeep(this.menuItems);
        this.fnCutArray(pathname, cloneDeep(this.menuItems));
        localStorage.setItem('menuItems', JSON.stringify(this.menuItems));
    }
    menusss: MenuItem1[] = [];

    fnCutArray(url: string, menuItems: any) {
        if (navigator.userAgent.indexOf('Mac OS X') != -1) {
            var array = menuItems;
            var pulled = pullAt(array, [0, 1, 2, 3, 4]);
            this.menulist1 = pulled;
            this.menulist2 = array;
            this.parseObjectProperties(this.menulist1, url);
            this.parseObjectProperties(this.menulist2, url);
            this.menulist1 = [...this.menulist1];
            this.menulist2 = [...this.menulist2];
        } else {

            if (this.screenWidth < 576) {
                // this.mediaBreakpoint$.next('xs');
            } else if (this.screenWidth >= 576 && this.screenWidth < 768) {
                // this.mediaBreakpoint$.next('sm');


            } else if (this.screenWidth > 1400 && this.screenWidth <= 1600) {
                var array = menuItems;
                var pulled = pullAt(array, [0, 1, 2, 3, 4]);
                this.menulist1 = pulled;
                this.menulist2 = array;
                this.parseObjectProperties(this.menulist1, url);
                this.parseObjectProperties(this.menulist2, url);
                this.menulist1 = [...this.menulist1];
                this.menulist2 = [...this.menulist2];
            } else if (this.screenWidth > 1280 && this.screenWidth <= 1400) {
                var array = menuItems;
                var pulled = pullAt(array, [0, 1, 2, 3, 4]);
                this.menulist1 = pulled;
                this.menulist2 = array;
                this.parseObjectProperties(this.menulist1, url);
                this.parseObjectProperties(this.menulist2, url);
                this.menulist1 = [...this.menulist1];
                this.menulist2 = [...this.menulist2];
            } else if (this.screenWidth > 1024 && this.screenWidth <= 1280) {
                var array = menuItems;
                var pulled = pullAt(array, [0, 1, 2, 3, 4]);
                this.menulist1 = pulled;
                this.menulist2 = array;
                this.parseObjectProperties(this.menulist1, url);
                this.parseObjectProperties(this.menulist2, url);
                this.menulist1 = [...this.menulist1];
                this.menulist2 = [...this.menulist2];
            } else if (this.screenWidth > 960 && this.screenWidth <= 1024) {
                var array = menuItems;
                var pulled = pullAt(array, [0, 1, 2, 3, 4]);
                this.menulist1 = pulled;
                this.menulist2 = array;
                this.parseObjectProperties(this.menulist1, url);
                this.parseObjectProperties(this.menulist2, url);
                this.menulist1 = [...this.menulist1];
                this.menulist2 = [...this.menulist2];
            } else {
                var array = menuItems;
                this.menulist1 = array;
                this.menulist2 = [];
                const urllink = url.split('/');
                if (urllink.length < 3) {
                    this.parseObjectProperties(this.menulist1, url);
                    this.menulist1 = [...this.menulist1];
                } else {
                    this.menulist1 = [];
                    setTimeout(() => {
                        let menus = this.menuItems.filter(i => i.path === urllink[1]);
                        if(menus[0].path === 'card-manager') {
                            this.parseObjectProperties(this.menuItems, url);
                            this.menulist1 = [...this.menuItems];
                        } else {
                            this.parseObjectProperties(menus[0]?.submenus, url);
                            this.menulist1 = [...menus[0]?.submenus];
                            this.detailRouter = menus[0];
                        } 
                    }, 0);
                }

            }
        }



    }
    ngOnInit() {
        this.menusss = [
            {
                label: 'File',
                icon: 'pi pi-fw pi-file',
                items: [
                    {
                        label: 'New',
                        icon: 'pi pi-fw pi-plus',
                        items: [
                            {
                                label: 'Bookmark',
                                icon: 'pi pi-fw pi-bookmark'
                            },
                            {
                                label: 'Video',
                                icon: 'pi pi-fw pi-video'
                            }
                        ]
                    },
                    {
                        label: 'Delete',
                        icon: 'pi pi-fw pi-trash'
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Export',
                        icon: 'pi pi-fw pi-external-link'
                    }
                ]
            },
            {
                label: 'Edit',
                icon: 'pi pi-fw pi-pencil',
                items: [
                    {
                        label: 'Left',
                        icon: 'pi pi-fw pi-align-left'
                    },
                    {
                        label: 'Right',
                        icon: 'pi pi-fw pi-align-right'
                    },
                    {
                        label: 'Center',
                        icon: 'pi pi-fw pi-align-center'
                    },
                    {
                        label: 'Justify',
                        icon: 'pi pi-fw pi-align-justify'
                    }
                ]
            },
            {
                label: 'Users',
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'New',
                        icon: 'pi pi-fw pi-user-plus'
                    },
                    {
                        label: 'Delete',
                        icon: 'pi pi-fw pi-user-minus'
                    },
                    {
                        label: 'Search',
                        icon: 'pi pi-fw pi-users',
                        items: [
                            {
                                label: 'Filter',
                                icon: 'pi pi-fw pi-filter',
                                items: [
                                    {
                                        label: 'Print',
                                        icon: 'pi pi-fw pi-print'
                                    }
                                ]
                            },
                            {
                                icon: 'pi pi-fw pi-bars',
                                label: 'List'
                            }
                        ]
                    }
                ]
            },
            {
                label: 'Events',
                icon: 'pi pi-fw pi-calendar',
                items: [
                    {
                        label: 'Edit',
                        icon: 'pi pi-fw pi-pencil',
                        items: [
                            {
                                label: 'Save',
                                icon: 'pi pi-fw pi-calendar-plus'
                            },
                            {
                                label: 'Delete',
                                icon: 'pi pi-fw pi-calendar-minus'
                            }
                        ]
                    },
                    {
                        label: 'Archieve',
                        icon: 'pi pi-fw pi-calendar-times',
                        items: [
                            {
                                label: 'Remove',
                                icon: 'pi pi-fw pi-calendar-minus'
                            }
                        ]
                    }
                ]
            },
            {
                separator: true
            },
            {
                label: 'Quit',
                icon: 'pi pi-fw pi-power-off'
            }
        ];
        // const pathname  = window.location.pathname ;
        // this.menuItems = menus.filter(menuItem => menuItem);
        //     this.parseObjectProperties(this.menuItems, pathname);
        //     this.menuItems = [...this.menuItems];
    }

    getListMenuByUserId(pathname: string) {
        const queryMeny = queryString.stringify({ userId: this.authService.getClaims().sub, webId: environment.webId })
        this._apiService.clientMenuGetListByUserId(queryMeny).subscribe(results => { 
            if (results.status === 'success') {
                this.menuItems = results.data.filter((menuItem: MenuItem) => menuItem);
                // this.menuItems = menu //menu fix tạm
                localStorage.setItem('menuItems', JSON.stringify(results.data));
                this.fnCutArray(pathname, cloneDeep(this.menuItems));
            }
        });
    }

    parseObjectProperties2(obj: any[], pathname: string) {
        for (let k of obj) {


        }
    }
    detailRouter: any = null;
    backLabel: string = '';
    changeRouter(event: any) {
        if (event.item && event.item.items.length > 0) {
            this.detailRouter = event.item;
            if (this.detailRouter.classs === 'navigation-header') {
                this.backLabel = this.detailRouter.title;
            }
        }

    }

    onBackMenu() {
        // this.menulist1 = [...this.menulist1];
        this.menulist1 = [];
        setTimeout(() => {
            const pathname = window.location.pathname;
            this.detailRouter = null;
            this.parseObjectProperties(this.menuItems, pathname);
            this.menulist1 = cloneDeep(this.menuItems);
        }, 0);
    }

    parseObjectProperties(obj: any[], pathname: string) {
        for (let k of obj) {
            k.label = k.title;
            if (k.path && k.classs !== 'navigation-header') {
                k.routerLink = k.path
                // k.icon = ''
            } else {
                k.command = (event: any) => {
                    this.changeRouter(event);
                    document.querySelectorAll('.parent_active').forEach((element) => {
                        element.classList.remove('parent_active');
                    });
                }
            }

            // temp remove icon white
            // if (k.path && k.classs === 'navigation-header') {
            //     k.icon = (k.icon).replace("uni-white-color", "");
            // }
            if (k.path && k.classs === 'nav-link') {
                k.routerLink = k.path
                
            }
            if (k.submenus && k.submenus.length > 0) {
                k.items = k.submenus.filter((d: any) => d.classs && (d.classs.indexOf("hidden") < 0));
            }
    
            if (k.routerLink) {
                // active menu con
                // k.icon = "uni-icon uni-clock uni-hover-orange"
                if (k.classs === 'navigation-header') {
                    if (k.routerLink && pathname.includes(k.routerLink)) {
                        k.styleClass = 'parent_active' + ' ' + k.classs
                    } else {
                        k.styleClass = 'parent_no_active' + ' ' + k.classs
                    }
                } else if (k.classs === 'nav-link') {
                    k.command = (event: any) => {
                        document.querySelectorAll('.parent_active').forEach((element) => {
                            element.classList.remove('parent_active');
                        });
                    }     
                }
                 else {
                    k.command = (event: any) => {
                        document.querySelectorAll('.active').forEach((element) => {
                            element.classList.remove('active');
                          });
                        
                        document.querySelectorAll('.no-active').forEach((element) => {
                        element.classList.remove('no-active');
                        });
                    }
                    if (k.routerLink && pathname.includes(k.routerLink)) {
                        k.styleClass = k.path === '/quan-ly-hop-dong/xu-ly-dat-coc' ? 'active hidden' : 'active' + ' ' + k.classs
                    } else {
                        k.styleClass = k.path === '/quan-ly-hop-dong/xu-ly-dat-coc' ? 'no-active hidden' : 'no-active' + ' ' + k.classs
                    }
                }

            } else {
                //active cha

                if (k.path && pathname && pathname.split('/').indexOf(k.path) > -1 && k.classs === 'navigation-header') {
                    k.styleClass = "parent_active" + ' ' + k.classs
                } else {
                    k.styleClass = "parent_no_active" + ' ' + k.classs
                }
            }

            if (k.hasOwnProperty('items') && Array.isArray(k.items) && k.items.length > 0) {
                this.parseObjectProperties(k.items, pathname);
            }
        }
    }


}
