import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MenuModule} from 'primeng/menu';
import {AppRoutingModule} from './app-routing.module';
import {FooterComponent} from './shared/footer/footer.component';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {SidebarComponent} from './shared/sidebar/sidebar.component';
import {MenubarModule} from 'primeng/menubar';
import {AvatarModule} from 'primeng/avatar';
import {ToolbarModule} from 'primeng/toolbar';
import {BadgeModule} from 'primeng/badge';
import {DefaultLayoutComponent} from './containers/default-layout';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {StyleClassModule} from 'primeng/styleclass';
import {PanelMenuModule} from 'primeng/panelmenu';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthService} from './services/auth/auth.service';
import {AppApiService} from './services/app.service';
import {AuthGuardService} from './services/auth/auth-guard.service';
import {AuthInterceptor} from './services/auth/auth-interceptor';
import {DatePipe} from '@angular/common';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import {SlideMenuModule} from 'primeng/slidemenu';
import {AuthCallbackComponent} from './shared/components/auth-callback/auth-callback.component';
import { ExportFileService } from './services/export-file.service';
import { ApiDownloadService } from './services/api-download.services';
import { ShareService } from './services/share.service';
import { VehicleGuestComponent } from './components/vehicle/vehicle-guest/vehicle-guest.component';
import { CardVehicleService } from './services/card-service/card-vehicle.service';
import { HrmBreadCrumbModule } from './common/hrm-breadcrumb/hrm-breadcrumb.module';
import { FormDetailModule } from './common/form-detail/form-detail.module';
import { ConfigGridTableFormModule } from './common/config-grid-table-form/config-grid-table-form.module';
import { ListGridAngularModule } from './common/ag-grid/list-grid-angular/list-grid-angular.module';
import { LoadingGridModule } from './common/loading-grid/loading-grid.module';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { CardApiService } from './services/card-service/card.service';
import { ApartmentApiService } from './services/apartment-service/apartment.service';
import { VehicleGuestInfoComponent } from './components/vehicle/vehicle-guest/vehicle-guest-info/vehicle-guest-info.component';
import { environment } from 'src/environments/environment';
import { ChatApiService } from './services/chat.service';
import { ApiStorageService } from './services/api-storage.service';
import { CheckHideActionsDirectiveModule } from './directive/check-action.module';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { ChatComponent } from './components/chat/chat.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AuthCallbackComponent,
    VehicleGuestComponent,
    VehicleGuestInfoComponent,
    ChatComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    TieredMenuModule,
    FormsModule,
    HrmBreadCrumbModule,
    FormDetailModule,
    ConfigGridTableFormModule,
    CheckHideActionsDirectiveModule,
    DialogModule,
    PaginatorModule,
    SidebarModule,
    ListGridAngularModule,
    LoadingGridModule,
    HttpClientModule,
    SlideMenuModule,
    OverlayPanelModule,
    LMarkdownEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MenubarModule,
    AvatarModule,
    ToolbarModule,
    ChartModule,
    MenuModule,
    TableModule,
    BadgeModule,
    StyleClassModule,
    PanelMenuModule,
    ButtonModule,
    NgxSpinnerModule,
    ConfirmDialogModule,
    ToastModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
  ],
  providers: [
    MessageService,
    DialogService,
    AuthService,
    AppApiService,
    CardApiService,
    ShareService,
    ApiDownloadService,
    ApartmentApiService,
    FirebaseAuthService,
    ChatApiService,
    ApiStorageService,
    ExportFileService,
    CardVehicleService,
    DatePipe,
    ConfirmationService,
    AuthGuardService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
